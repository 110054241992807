import { MutableRefObject } from "react";
import Box from "@mui/material/Box";
import { alpha, Fade, InputBase, useTheme } from "@mui/material";

export default function Circle({
  index,
  isFilled,
  inputRefs,
  onFocus,
}: {
  index: number;
  isFilled: boolean;
  inputRefs: MutableRefObject<(HTMLInputElement | null)[]>;
  onFocus: () => void;
}) {
  const theme = useTheme();
  const borderWidth = 3;

  const backgroundColor = alpha(theme.palette.text.primary, 1);

  return (
    <Box
      sx={{
        width: 35,
        height: 35,
        borderRadius: "50%",
        border: "solid",
        borderColor: backgroundColor,
        borderWidth: borderWidth,
        position: "relative",
      }}
    >
      <InputBase
        onFocus={() => {
          onFocus();
        }}
        inputRef={(ref) => (inputRefs.current[index] = ref)}
        sx={{
          width: 1,
          position: "absolute",
        }}
        inputProps={{ style: { textAlign: "center", caretColor: "transparent" }, inputMode: "numeric" }}
        value={""}
      />
      <Fade
        in={isFilled}
        style={{
          height: `calc(100% + ${borderWidth * 2 - 2}px)`,
          width: `calc(100% + ${borderWidth * 2 - 2}px)`,
          top: -borderWidth + 2,
          left: -borderWidth + 2,
          position: "absolute",
        }}
      >
        <Box
          sx={{
            borderRadius: "50%",
            backgroundColor: backgroundColor,
            width: 1,
            height: 1,
          }}
        ></Box>
      </Fade>
    </Box>
  );
}
