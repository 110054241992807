import Box from "@mui/material/Box";

import VerticalMenuPage from "./VerticalMenuPage";
import HorizontalMenuPage from "./HorizontalMenuPage";

import KioskTimeout from "../../components/KioskTimeout/KioskTimeout";
import MultipleLayersMenuPage from "./MultipleLayersMenuPage/MultipleLayersMenuPage";
import { useAppSelector } from "../../../global/utils/redux/store";
import WelcomePopupScreen from "../../../qr/components/WelcomePopupScreen";
import { OnetimeUpsell } from "../../../global/utils/hooks/useOpenOneTimeUpsellDialog";
import { PiggyScanCardDialog } from "../../components/piggy/PiggyScanCardDialog";
import CikamDialog from "../../../global/components/Cikam/CikamDialog";
import { useScrollToArticleGroup } from "../../utils/UseScrollToArticleGroup.tsx";
import { CatalogView } from "../../../global/utils/types/CatalogView.ts";
import VouchersAddDiscountedProducts from "../../components/VouchersV2/AddDiscountedProducts/VouchersAddDiscountedProducts.tsx";
import PiggyDialog from "../../components/piggy/PiggyDialog.tsx";
import AiUpsellService from "../../../qr/components/AiUpsell/AiUpsellService.tsx";

export default function MenuPage() {
  const catalogView: CatalogView = useAppSelector((state) => state.global.salesarea?.orientation_of_categories);
  const shouldShowWelcomePopup = useAppSelector((state) => state.global.salesarea?.show_welcome_screen);

  useScrollToArticleGroup();
  return (
    <>
      <Box
        data-cy={"menu-page"}
        sx={{
          width: 1,
          height: 1,
          display: "flex",
          flexDirection: catalogView !== "VERTICAL" ? "column" : "row",
        }}
      >
        {shouldShowWelcomePopup ? <WelcomePopupScreen /> : null}
        <PiggyScanCardDialog />
        <PiggyDialog />
        <KioskTimeout />
        <AiUpsellService />
        <VouchersAddDiscountedProducts />

        {catalogView === "VERTICAL" ? <VerticalMenuPage /> : null}

        {catalogView === "HORIZONTAL" ? <HorizontalMenuPage /> : null}
        {catalogView === "MULTIPLE_LAYERS" ? <MultipleLayersMenuPage /> : null}
      </Box>
      <CikamDialog />
      <OnetimeUpsell where={"onOpenMenuPage"} />
    </>
  );
}
