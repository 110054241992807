import { usePiggyUser } from "../../utils/piggy/piggy.tsx";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";

export default function PiggyPopupAtCheckoutPage() {
  const { isLoggedIn: piggyUserIsLoggedIn, piggyEnabled } = usePiggyUser();
  const intl = useIntl();
  useEffect(() => {
    if (piggyEnabled && !piggyUserIsLoggedIn) {
      toast.warning(intl.formatMessage({ id: "Don't miss out on points, scan your loyalty card!" }));
    }
  }, [piggyEnabled, piggyUserIsLoggedIn, intl]);
  return null;
}
