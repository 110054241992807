import React from "react";
import { CSSProperties } from "@mui/material/styles/createMixins";
import { BoxProps } from "@mui/material";

export default function NutsIcon({
  innerColor = "#fefefe",
  outerColor = "#cf4d51",
  className,
  propsStyle,
}: {
  innerColor?: CSSProperties["color"];
  outerColor?: CSSProperties["color"];
  className?: string;
  propsStyle?: BoxProps["style"];
}) {
  const style = {
    fill: innerColor,
  };

  return (
    <svg viewBox={`0 0 216 216`} className={className} style={{ color: outerColor, ...propsStyle }}>
      <path
        d="M107.51 215.86C48.05 215.86 0 167.34 0 107.75 0 47.82 48.05 0 107.51 0c59.81 0 107.88 47.82 107.88 107.75 0 59.59-48.07 108.11-107.88 108.11z"
        fill={"currentColor"}
      />
      <path
        style={style}
        d="M132.78 171.26c5.34-1.06 9.62-3.21 13.52-5.34 2.87-4.64 4.28-10.71 6.06-16.06 1.06 2.86 1.06 8.56 1.06 11.77 8.55-5 11.4-14.98 12.11-26.76 1.42-27.12-3.56-47.46-16.73-62.8-5.69-6.43-11.4-11.77-17.8-15.69-9.26-5.35-19.94-9.64-23.13-20.34-4.99 13.56-8.19 30.33-10.69 47.1-1.07 6.43-2.84 13.2-3.2 19.63-1.78 24.98 1.07 49.95 8.9 65.64.72 1.42 1.07 3.57 2.86 3.92-1.79-12.48-9.26-27.83-10.33-43.17-2.14-32.47 5.34-64.58 16.02-84.2v.35c-5.34 12.48-6.76 26.04-8.9 43.53-1.07 8.92-3.2 18.9-3.2 27.46.36 18.92 1.42 37.47 7.48 49.96 5.34-13.21 14.59 7.13 23.84 4.63-4.98-7.49-2.13-19.98-1.07-29.97 1.06 10.01 2.14 20.36 3.2 30.34zm2.14-37.82c-2.49-6.42-3.21-15.34-.72-22.12 2.5 6.43 3.2 16.07.72 22.12zm6.04 24.26c0-8.2-.35-16.05 1.79-21.76 1.78 6.42 3.92 17.85-1.79 21.76zm-4.26-68.13c8.9 2.49 8.55 14.62 9.61 25.33-3.91-7.5-6.41-17.13-9.61-25.33zm21.36 45.3c-2.5-9.99-1.43-23.55-6.05-31.4 8.54 2.51 8.9 20.7 6.05 31.4zm-7.48-44.96c-6.42-9.64-14.6-17.48-18.87-29.25 7.13 7.14 16.38 15.7 18.87 29.25zm-37.73-37.45c2.13 6.77 5.7 12.13 5.7 20.68-4.28-4.63-7.13-11.41-5.7-20.68zm-2.5 48.16c1.79 6.78 2.5 15.69 4.28 21.41-5.7-2.15-8.19-13.56-4.28-21.41zm-.35 29.97c1.42 11.06 7.12 17.84 10.69 27.11-5.35-5.7-16.74-16.05-10.69-27.11zm14.95-15.34c-1.79-7.5-2.5-16.42-2.15-25.68 6.06 4.62 2.86 17.12 2.15 25.68z"
      />
      <path
        style={style}
        d="M87.22 169.12c-.71-2.5-2.85-6.42-1.42-9.99 1.77 2.5 3.56 4.64 4.97 7.49 1.42 0 1.42-1.42 2.49-1.77 2.14 2.14 4.63 6.41 7.48 6.77-11.39-14.26-13.53-36.03-14.59-58.16-1.08-27.82 8.54-49.24 16.01-69.22-9.25 7.5-20.99 10.71-29.55 19.63-14.24 14.26-21.72 39.6-22.79 64.58-.35 2.86-.35 6.06 0 8.91.36 7.85 8.19 13.92 12.11 18.2-2.13-2.5-4.27-7.14-2.86-11.77 7.5 10.34 11.06 24.98 28.15 25.33zm-7.48-59.94c-3.2-10.35-4.26-28.18 4.27-33.9-3.2 8.21-3.56 22.49-4.27 33.9zm-.36-44.24c2.5-4.63 2.14-8.2 9.62-8.56-5.7 4.64-6.76 13.92-14.6 16.77-.35-3.93 2.85-4.99 4.98-8.21zm-6.06 73.14c1.08 2.85 3.21 5.36 2.5 9.99-2.13-.71-1.78-2.49-2.85-4.63-1.07-2.15-3.55-3.58-4.27-5.72-2.13-5.7.35-12.49.72-18.9 2.13 4.63 2.13 13.2 3.9 19.26zm-6.39-54.95c2.12 5 2.49 17.13.36 22.12-2.86-6.42-3.57-14.98-.36-22.12zm-8.91 19.28c-1.06 10.7 0 23.55 1.43 34.61-5.34-8.57-8.55-25.7-1.43-34.61z"
      />
    </svg>
  );
}
