import { useEffect, useMemo, useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CloseDialogButton from "./Dialog/CloseDialogButton.tsx";
import useSalesareaIsOpen from "../../global/utils/useSalesareaIsOpen";

import md5 from "md5";
import { useAppSelector } from "../../global/utils/redux/store";
import { selectAppLanguage } from "../../kiosk/components/LanguageSelector/useLanguage";
import { postAnalyticsEvent } from "../../global/utils/analytics/useAnalytics.ts";
import isQr from "../../global/utils/helpers/isQr.tsx";
import isKiosk from "../../global/utils/helpers/isKiosk.tsx";
import FormattedMessageJamezz from "../../global/components/FormattedMessageJamezz.tsx";

const isShownMd5 = sessionStorage.getItem("WelcomePopupScreen.isShownMd5");

export default function WelcomePopupScreen() {
  const isOpen = useSalesareaIsOpen();
  const [open, setOpen] = useState(false);

  const translations = useAppSelector((state) => state.global.salesarea?.htmlTranslations);

  const selectedLanguage = useAppSelector(selectAppLanguage);

  const md5Calculated = useMemo(() => {
    if (translations?.[selectedLanguage]?.infoSplashContent) {
      return md5(translations?.[selectedLanguage]?.infoSplashContent);
    } else {
      return "";
    }
  }, [selectedLanguage, translations]);
  const welcomeScreen = useMemo(() => {
    const welcomeScreen = { title: "", body: "" };
    if (translations?.[selectedLanguage]?.infoSplashTitle) {
      welcomeScreen.title = translations[selectedLanguage].infoSplashTitle;
    }
    if (translations?.[selectedLanguage]?.infoSplashContent) {
      welcomeScreen.body = translations[selectedLanguage].infoSplashContent;
    }

    return welcomeScreen;
  }, [selectedLanguage, translations]);

  useEffect(() => {
    if (isOpen && (welcomeScreen.body || welcomeScreen.title)) {
      if (isQr() && md5Calculated !== isShownMd5) {
        sessionStorage.setItem("WelcomePopupScreen.isShownMd5", md5Calculated);
        setOpen(true);
      } else if (isKiosk()) {
        setOpen(true);
      }
    }
  }, [isOpen, md5Calculated, welcomeScreen?.body, welcomeScreen?.title]);

  return (
    <Dialog
      open={open}
      keepMounted={false}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle sx={{ mt: 8, wordBreak: "break-word" }}>{welcomeScreen.title}</DialogTitle>
      <CloseDialogButton
        onClose={() => {
          postAnalyticsEvent({
            category: "WelcomePopupScreen",
            action: "close",
          });
          setOpen(false);
        }}
      />
      <DialogContent>
        <div
          dangerouslySetInnerHTML={{
            __html: welcomeScreen.body,
          }}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          variant={"contained"}
          onClick={() => {
            postAnalyticsEvent({
              category: "WelcomePopupScreen",
              action: "close",
            });
            setOpen(false);
          }}
        >
          <FormattedMessageJamezz id={"Let's go!"} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
