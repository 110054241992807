import Box from "@mui/material/Box";
import { useEffect } from "react";
import {
  PackagingType,
  selectAllPackagingsTypesFromShoppingCartItems,
  userSelectedPackagingTypeId,
} from "../../../global/utils/redux/packagingsSlice";
import store, { useAppSelector } from "../../../global/utils/redux/store";
import useAskForWhichPackagingTypeToUse from "./useAskForWhichPackagingTypeToUse";
import {
  OptionTranslationsContext,
  OptionTranslationType,
  useOptionTranslations,
} from "../../../global/utils/translations/useOptionTranslations";
import { Button, Collapse, Divider, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { selectPackagingItemsOrderValue } from "../../../global/utils/redux/accountSlice";
import useCurrency, { CurrencyLocation } from "../../../global/utils/useCurrency";

export default function PackagingCheckout() {
  const packagingsPerType = useAppSelector(selectAllPackagingsTypesFromShoppingCartItems);

  const userSelectedPackagingType = useAppSelector((state) =>
    state.packagings.userSelectedPackagingTypeId
      ? state.packagings.packagingTypes[state.packagings.userSelectedPackagingTypeId]
      : undefined
  );
  const askForPackagingType = useAskForWhichPackagingTypeToUse();
  useEffect(() => {
    if (userSelectedPackagingType == null && Object.keys(packagingsPerType).length > 1) {
      askForPackagingType();
    } else if (userSelectedPackagingType == null && Object.keys(packagingsPerType).length === 1) {
      store.dispatch(userSelectedPackagingTypeId(Object.keys(packagingsPerType)[0]));
    }
  }, []);

  return (
    <>
      {Object.keys(packagingsPerType).length > 1 && userSelectedPackagingType && (
        <OptionTranslationsContext.Provider value={userSelectedPackagingType.translations}>
          <SelectedPackaging packagingType={userSelectedPackagingType} />
        </OptionTranslationsContext.Provider>
      )}
    </>
  );
}

function SelectedPackaging({ packagingType }: { packagingType: PackagingType }) {
  const { translate } = useOptionTranslations();
  const askForPackagingType = useAskForWhichPackagingTypeToUse();
  return (
    <Box sx={{ display: "inline-flex", justifyContent: "space-between", alignItems: "center", width: 1 }}>
      <Typography sx={{ textAlign: "center" }}>
        <FormattedMessageJamezz id={"Packaging"} />: {translate(packagingType.name, OptionTranslationType.OptionGroup)}
      </Typography>
      <Button
        variant={"contained"}
        onClick={() => {
          askForPackagingType();
        }}
      >
        <FormattedMessageJamezz id={"Change packaging?"} />
      </Button>
    </Box>
  );
}

export function PackagingFee({ showDivider }: { showDivider: boolean }) {
  const packagingFee = useAppSelector(selectPackagingItemsOrderValue);
  const toCurrency = useCurrency({ location: CurrencyLocation.Checkout });
  return (
    <>
      <Collapse in={packagingFee > 0}>
        {showDivider ? <Divider sx={{ width: 1 }} /> : null}
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 4 }} className={"JS-PackagingFee-Wrapper"}>
          <Typography sx={{ fontStyle: "italic" }} className={"JS-PackagingFee-Title"}>
            <FormattedMessageJamezz id={"Packaging fee"} />
          </Typography>

          <Typography
            align={"right"}
            sx={{ fontStyle: "italic", whiteSpace: "nowrap" }}
            data-cy="packaging-costs"
            className={"JS-PackagingFee-Amount"}
          >
            {toCurrency(Number(packagingFee))}
          </Typography>
        </Box>
      </Collapse>
    </>
  );
}
