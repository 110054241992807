import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { useEffect, useState } from "react";
import QrArticleSuggestionPage from "../../pages/ArticleSuggestionPage/ArticleSuggestionPage.tsx";
import KioskArticleSuggestionPage from "../../../kiosk/pages/ArticleSuggestionPage/ArticleSuggestionPage.tsx";
import SlideUpTransition from "../../../global/components/SlideUpTransition.tsx";
import { useDialog } from "../../../global/utils/dialog/DialogProvider.tsx";
import useDDAiData, { getUpsellItems } from "../../../global/utils/ddai/useDDAiData";
import { selectArticleHashNamesMap } from "../../../global/utils/redux/selectors/selectArticleHashNamesMap.ts";
import isKiosk from "../../../global/utils/helpers/isKiosk.tsx";
import useIsWidescreen from "../../../global/utils/useIsWidescreen.ts";

export default function AiUpsellService() {
  const { openDialog } = useDialog();
  const aiUpsellData = useAppSelector((state) => state.aiData.currentUpsell);
  const aiLastUpsellSource = useAppSelector((state) => state.aiData.lastUpsellSource);
  const productsLoaded = useAppSelector((state) => state.menuData.productsLoaded);
  const articleHashNamesMap = useAppSelector(selectArticleHashNamesMap);
  const useUpsellScreens = useAppSelector((state) => state.global.salesarea?.custom_data?.ai?.useBaksetUpsell ?? true);
  const max_popups = useAppSelector((state) => state.global.salesarea?.custom_data?.ai?.max_popups ?? 3);
  const max_suggestions = useAppSelector((state) => state.global.salesarea?.custom_data?.ai?.max_suggestions ?? 4);
  const suggestion_timeout = useAppSelector(
    (state) => state.global.salesarea?.custom_data?.ai?.suggestion_timeout ?? 10
  );

  const isWidescreen = useIsWidescreen();
  const [aiLastShowTime, setAiLastShowTime] = useState(Math.floor(Date.now() / 1000));
  const [aiPopUpCount, setAiPopUpCount] = useState(0);
  useDDAiData();
  useEffect(() => {
    const ArticleSuggestionPage = isKiosk() ? KioskArticleSuggestionPage : QrArticleSuggestionPage;
    const elapsedTime = Math.floor(Date.now() / 1000) - aiLastShowTime;
    if (
      aiUpsellData &&
      aiUpsellData.length > 0 &&
      productsLoaded &&
      useUpsellScreens &&
      elapsedTime > suggestion_timeout &&
      aiPopUpCount < max_popups &&
      aiLastUpsellSource != "AiUpsellCart"
    ) {
      const articles = getUpsellItems(aiUpsellData, articleHashNamesMap, max_suggestions);
      if (articles.length > 0) {
        setAiLastShowTime(Math.floor(Date.now() / 1000));
        setAiPopUpCount(aiPopUpCount + 1);
        setTimeout(() => {
          openDialog({
            children: (
              <ArticleSuggestionPage
                title={"Others also bought"}
                upsellType={"AiUpsell"}
                upsellSource={"AiUpsellService"}
                articles={articles}
              />
            ),
            fullScreen: isKiosk() ? "almostFullScreen" : !isWidescreen,
            TransitionComponent: SlideUpTransition,
          });
        }, 200);
      }
    }
  }, [
    aiUpsellData,
    productsLoaded,
    useUpsellScreens,
    aiLastUpsellSource,
    articleHashNamesMap,
    openDialog,
    max_popups,
    max_suggestions,
    suggestion_timeout,
    aiLastShowTime,
    setAiLastShowTime,
    aiPopUpCount,
    isWidescreen,
    setAiPopUpCount,
  ]);
  return null;
}
