import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import {
  selectArrangementMaxRounds,
  selectArrangementMaxTime,
} from "../../../global/utils/redux/arrangements/arrangementRuleSelectors.ts";
import {
  selectCreatedAtTicket,
  selectCurrentOrderRound,
} from "../../../global/utils/redux/arrangements/arrangementsSlice.tsx";
import { useCallback, useEffect } from "react";
import { differenceInSeconds } from "date-fns";
import { getNow } from "../../../global/utils/models/JamezzHours.ts";
import { useDialog } from "../../../global/utils/dialog/DialogProvider.tsx";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";
import { AyceRuleResult, useCheckAyceRules } from "../../utils/arrangements/useCheckAyceRules.ts";
import { getOrderTimerAsSecondsLeft } from "../../utils/arrangements/useOrderTimer.ts";
import { useCustomTexts } from "../../../global/utils/useCustomTexts.tsx";
import { useIntl } from "react-intl";
import { selectActiveArrangement } from "../../../global/utils/redux/arrangements/selectActiveArrangement.tsx";

const FIFTEEN_MINUTES = 15 * 60;
let open = false;

export default function AyceAlmostOverNotificationTimer() {
  const arrangement = useAppSelector(selectActiveArrangement);
  const enabled =
    arrangement?.variant === "regular"
      ? arrangement.almost_done_reminder_enabled && arrangement.visit_length === "duration"
      : false;

  const maxTimeInMinutes = useAppSelector(selectArrangementMaxTime);
  const createdAtTicket = useAppSelector(selectCreatedAtTicket) ?? "";
  const currentOrderRound = useAppSelector(selectCurrentOrderRound);
  const maxRounds = useAppSelector(selectArrangementMaxRounds);
  const roundsLeft = maxRounds - currentOrderRound;
  const ayceRuleResult = useCheckAyceRules();
  const intl = useIntl();

  const ct = useCustomTexts(["Almost finished reminder - title", "Almost finished reminder - body"]);

  const { openDialog, closeDialog } = useDialog();

  const showNotification = useCallback(
    (seconds: number) => {
      if (open) return;

      let timeLeft;
      if (seconds > 60) {
        timeLeft = Math.floor(seconds / 60);
      } else {
        timeLeft = seconds;
      }

      if (seconds - getOrderTimerAsSecondsLeft() <= 0) {
        return;
      }

      const formatAsMinutes = seconds > 60;

      open = true;
      openDialog({
        children: (
          <Dialog open={true} data-cy="arrangements-almost-done-notification">
            <DialogTitle>
              {ct["Almost finished reminder - title"] ??
                intl.formatMessage({ id: "AYCE.ticket-timer-almost-over.title-default" })}
            </DialogTitle>
            <DialogContent>
              <p>
                <FormattedMessageJamezz
                  id="AYCE.ticket-timer-almost-over.time-remaining"
                  values={{
                    timeLeft:
                      timeLeft +
                      " " +
                      intl.formatMessage({
                        id: formatAsMinutes ? "minute(s)" : "second(s)",
                      }),
                  }}
                />
              </p>
              {(ct["Almost finished reminder - body"] ?? roundsLeft >= 0) ? (
                <p>
                  <FormattedMessageJamezz id="AYCE.ticket-timer-almost-over.body-default" />
                </p>
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  closeDialog();
                  open = false;
                }}
              >
                <FormattedMessageJamezz id="Understood" />
              </Button>
            </DialogActions>
          </Dialog>
        ),
      });
    },
    [closeDialog, ct, intl, openDialog, roundsLeft]
  );

  useEffect(() => {
    let timeout_id: number;
    if (enabled && maxTimeInMinutes && createdAtTicket && ayceRuleResult !== AyceRuleResult.ROUND_COUNTER_EXCEEDED) {
      const secondsLeft = maxTimeInMinutes * 60 - differenceInSeconds(getNow(), new Date(createdAtTicket));
      if (secondsLeft <= FIFTEEN_MINUTES && secondsLeft > 0) {
        showNotification(secondsLeft);
      } else if (secondsLeft - FIFTEEN_MINUTES > 0) {
        timeout_id = window.setTimeout(() => showNotification(FIFTEEN_MINUTES), (secondsLeft - FIFTEEN_MINUTES) * 1000);
      }
    }

    return () => {
      if (timeout_id) {
        window.clearTimeout(timeout_id);
      }
    };
  }, [maxTimeInMinutes, createdAtTicket, enabled, ayceRuleResult, showNotification]);

  return <></>;
}
