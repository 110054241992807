import { useEffect, useMemo, useState } from "react";
import OrderArticle, {
  initOrderArticle,
  OrderArticleAnalytics,
  OrderArticleOrigin,
} from "../../../global/utils/models/order/OrderArticle";
import { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import Article from "../../../global/utils/models/menu/Article";
import OrderArticleSelectorPageContent from "./OrderArticleSelectorPageContent";
import { OptionTranslationsContext } from "../../../global/utils/translations/useOptionTranslations";
import { selectArticlesMap } from "../../../global/utils/redux/selectors/selectArticlesMap";
import Articlegroup from "../../../global/utils/models/menu/Articlegroup.ts";
import { OrderArticleContext } from "../../../global/utils/contexts/OrderArticleContext.ts";

interface Props extends Pick<OrderArticleAnalytics, "upsellType" | "upsellSource" | "upsellId"> {
  orderArticle?: OrderArticle;
  article?: Article;
  articlegroup?: Articlegroup;
  isUpselled?: boolean;
  isPiggyReward?: true;
  fixedCount?: number;
  onOrderArticleOk?: (orderArticle: OrderArticle) => void;
  callAfterAdding?: () => void;
}

export default function OrderArticleSelectorPage(props: Props) {
  const [orderArticle, setOrderArticle] = useState<OrderArticle | null>(null);

  const dispatch = useAppDispatch();

  const articlesMap = useAppSelector(selectArticlesMap);

  const article = useMemo<Article | null>(() => {
    if (props.orderArticle) {
      return { ...props.orderArticle.article, articlegroup: props.articlegroup };
    } else if (props.article) {
      return { ...props.article, articlegroup: props.articlegroup };
    }
    return null;
  }, [props.article, props.articlegroup, props.orderArticle]);

  const defaultOrderArticle = useMemo<OrderArticle | null>(() => {
    return props.orderArticle
      ? { ...props.orderArticle, articlegroup: props.articlegroup }
      : article
        ? initOrderArticle(
            articlesMap,
            article,
            props.fixedCount ?? 1,
            "",
            !props.isPiggyReward,
            false,
            props.isUpselled ?? false,
            props.isPiggyReward ? OrderArticleOrigin.PIGGY : OrderArticleOrigin.MENU,
            undefined,
            undefined,
            props.upsellType,
            props.articlegroup,
            props.article?.sortKey,
            props.upsellSource,
            undefined,
            undefined,
            props.upsellId
          )
        : null;
  }, [
    articlesMap,
    props.orderArticle,
    props.isPiggyReward,
    props.isUpselled,
    props.articlegroup,
    props.upsellType,
    props.upsellSource,
    props.upsellId,
    props.article,
    props.fixedCount,
    article,
  ]);

  useEffect(() => {
    return () => {
      setOrderArticle(null);
    };
  }, [dispatch]);

  useEffect(() => {
    setOrderArticle(defaultOrderArticle);
  }, [article, dispatch, props.orderArticle]); // do not add defaultOrderArticle, it breaks

  if (!article || !orderArticle || orderArticle.article.id !== defaultOrderArticle?.article.id) {
    return null;
  }
  return (
    <OrderArticleContext.Provider value={{ orderArticle, setOrderArticle }}>
      <OptionTranslationsContext.Provider value={orderArticle.article.translations}>
        <OrderArticleSelectorPageContent
          orderArticle={orderArticle}
          isPiggyReward={props.isPiggyReward}
          modifyingOrderArticle={props.orderArticle}
          fixedCount={props.fixedCount}
          onOrderArticleOk={props.onOrderArticleOk}
          callAfterAdding={props.callAfterAdding}
        />
      </OptionTranslationsContext.Provider>
    </OrderArticleContext.Provider>
  );
}
