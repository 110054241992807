import Article from "../../../../utils/models/menu/Article.ts";
import store from "../../../../utils/redux/store.tsx";
import {
  ActiveArrangement,
  findArrangementGroupForArticle,
  findArrangementGroupsForArticle,
} from "../../../../utils/redux/arrangements/arrangementsSlice.tsx";
import { selectArticleArticlegroupsMap } from "../../../../utils/redux/selectors/selectArticleArticlegroupsMap.ts";
import { DialogOption } from "../../../../utils/dialog/DialogProvider.tsx";
import { ArrangementLimitationCheck, CheckResult } from "../../ArrangementLimitations.tsx";
import OrderArticle from "../../../../utils/models/order/OrderArticle.ts";
import { IntlShape } from "react-intl";
import { selectActiveArrangement } from "../../../../utils/redux/arrangements/selectActiveArrangement.tsx";
import { ArrangementGroup } from "../../../../../types/qr/arrangements.ts";

function maxPerRoundPerCover(article: Article): number {
  const state = store.getState();
  const activeArrangement = selectActiveArrangement(state);

  if (activeArrangement == null) {
    return 0;
  }
  const groups = findArrangementGroupsForArticle(article, activeArrangement.groups);

  /* in the case where the item is part of more than one active group,
   * find the "strictest" rule.
   * For instance, if you have two groups. One without a limit and one with limit of 1, the limit should be 1:
   *     [infinite, 1] => 1
   */
  return groups.reduce(function (lowest, group) {
    const groupMaximum = group.max_per_round_per_cover === 0 ? Infinity : group.max_per_round_per_cover;
    if (groupMaximum < lowest) {
      return groupMaximum;
    }
    return lowest;
  }, Infinity);
}

function countArrangementGroupItemsInShoppingCart(article: Article): {
  count: number;
  arrangementGroup: ArrangementGroup | undefined;
} {
  const state = store.getState();
  const activeArrangement = selectActiveArrangement(state);
  const articleArticlegroupsMap = selectArticleArticlegroupsMap(state);
  const shoppingCartItems = state.shoppingCart.items;

  if (!activeArrangement) return { count: 0, arrangementGroup: undefined };

  const g1 = findArrangementGroupForArticle(article, articleArticlegroupsMap, activeArrangement.groups);
  return {
    count: shoppingCartItems.reduce(function (count, item) {
      const g2 = findArrangementGroupForArticle(item.article, articleArticlegroupsMap, activeArrangement.groups);
      if (g1 === g2) {
        return count + item.count;
      }
      return count;
    }, 0),
    arrangementGroup: g1,
  };
}

export const checkMaxPerRoundPerCover: ArrangementLimitationCheck = async (
  { article }: OrderArticle,
  arrangement: ActiveArrangement,
  openDialog: (option: DialogOption) => string,
  closeDialog: () => void,
  intl: IntlShape
) =>
  new Promise((resolve) => {
    const maximumCredits = maxPerRoundPerCover(article);
    if (maximumCredits === Infinity || arrangement.total_covers == null) {
      resolve({ checkResult: CheckResult.VALID });
      return;
    }

    const { count, arrangementGroup } = countArrangementGroupItemsInShoppingCart(article);
    const valid = maximumCredits * arrangement.total_covers > count;

    if (valid) {
      resolve({ checkResult: CheckResult.VALID });
      return;
    } else {
      resolve({
        checkResult: CheckResult.INVALID,
        messageText: intl.formatMessage(
          {
            id: "AYCE.limitation-checks.arrangement-groups.max-per-round-per-cover",
          },
          {
            name: arrangementGroup?.name,
            maximum: maximumCredits,
            current: count,
          }
        ),
      });
    }
  });
