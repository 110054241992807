import store, { useAppSelector } from "../../../global/utils/redux/store";
import { Button, DialogActions, DialogTitle, Stack } from "@mui/material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import { useCallback, useState } from "react";
import {
  selectAllPackagingsTypesFromShoppingCartItems,
  userSelectedPackagingTypeId,
} from "../../../global/utils/redux/packagingsSlice";
import Box from "@mui/material/Box";

function AskForWhichPackagingTypeToUse({ onClose }: { onClose: (packagingTypeId: string) => void }) {
  const packagingsPerType = useAppSelector(selectAllPackagingsTypesFromShoppingCartItems);
  const packagingTypes = useAppSelector((state) => state.packagings.packagingTypes);
  const [closing, setClosing] = useState(false);
  return (
    <>
      <DialogTitle>
        <FormattedMessageJamezz id="Popup.askForPackagingType.title" />
      </DialogTitle>

      <DialogActions>
        <Stack sx={{ width: 1 }} justifyContent={"center"} direction={"row"} gap={4}>
          {Object.keys(packagingsPerType).map((packagingTypeId) => {
            const packagingType = packagingTypes[packagingTypeId];
            if (!packagingType) {
              return null;
            }
            return (
              <Box>
                <Button
                  key={packagingTypeId}
                  size={"large"}
                  onClick={() => {
                    if (!closing) {
                      onClose(packagingTypeId);
                      setClosing(true);
                    }
                  }}
                  variant="contained"
                >
                  {packagingType.name}
                </Button>
              </Box>
            );
          })}
        </Stack>
      </DialogActions>
    </>
  );
}

export default function useAskForWhichPackagingTypeToUse() {
  const { openDialog, closeDialog } = useDialog();

  return useCallback(() => {
    openDialog({
      disabledBackdropClick: true,
      children: (
        <AskForWhichPackagingTypeToUse
          onClose={(packagingTypeId) => {
            closeDialog();
            store.dispatch(userSelectedPackagingTypeId(packagingTypeId));
          }}
        />
      ),
    });
  }, [openDialog, closeDialog]);
}
