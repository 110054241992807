import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import Articlegroup, { getArticlegroupName } from "../../../global/utils/models/menu/Articlegroup";
import { ButtonBase, Typography } from "@mui/material";
import { useScroll } from "../../../global/utils/ScrollProvider";
import Box from "@mui/material/Box";
import clsx from "clsx";
import Image from "../../../global/components/Image";
// @ts-ignore
import JamezzLogo from "../../../assets/images/JamezzLogo.svg";
import IconButton from "@mui/material/IconButton";
import { ArrowBackIos } from "@mui/icons-material";
// @ts-ignore
import scrollIntoView from "scroll-into-view";

import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import { setSelectedArticlegroup } from "../../../global/utils/redux/menuSlice";
import { useGoToHomeKiosk } from "../ReturnPage/ReturnPage";
import { selectArticlegroupsMap } from "../../../global/utils/redux/selectors/selectArticlegroupsMap";
import { selectAppLanguage } from "../../components/LanguageSelector/useLanguage.ts";
import { AreYouSureStartOverDialog } from "../../components/AreYouSureStartOverDialog.tsx";

interface Props {
  articlegroups: Articlegroup[];
  verticalArticlegroupTabRef: MutableRefObject<any>;
  width: number;
}

function VerticalArticlegroupTab(props: Props) {
  const { scrollTabBlocked } = useScroll();
  const dispatch = useAppDispatch();
  const { openDialog } = useDialog();
  const lang = useAppSelector(selectAppLanguage);
  const goToHome = useGoToHomeKiosk();

  const selectedArticlegroup = useAppSelector((state) => state.menu.selectedArticlegroup) ?? null;
  const articlegroupsShortcutPage = useAppSelector((state) => state.global.salesarea?.articlegroups_shortcut_page);
  const logoImage = useAppSelector(
    (state) => state.global.salesarea?.media_urls?.logoImage?.[0]?.conversions?.optimized?.url
  );
  const [isToTopScrollable, setToTopIsScrollable] = useState(false);
  const [isToDownScrollable, setToDownIsScrollable] = useState(false);
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);

  const box = useRef<any>(null);
  const home = useRef<any>(null);

  const onScroll = useCallback(() => {
    if (box.current) {
      if (box.current.scrollTop + box.current.clientHeight < box.current.scrollHeight) {
        if (!isToDownScrollable) {
          setToDownIsScrollable(true);
        }
      } else {
        if (isToDownScrollable) {
          setToDownIsScrollable(false);
        }
      }
      if (box.current.scrollTop > 0) {
        if (!isToTopScrollable) {
          setToTopIsScrollable(true);
        }
      } else {
        if (isToTopScrollable) {
          setToTopIsScrollable(false);
        }
      }
    }
  }, [isToDownScrollable, isToTopScrollable]);

  useEffect(() => {
    const element = box.current;
    if (element) {
      element.addEventListener("scroll", onScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", onScroll);
      }
    };
  }, [onScroll]);

  useEffect(() => {
    onScroll();
  }, []);

  return (
    <Box
      ref={props.verticalArticlegroupTabRef}
      className={"JS-VerticalArticlegroupTab-Root"}
      sx={{
        width: props.width,
        height: 1,
        backgroundColor: "primary.light",
        position: "relative",
      }}
    >
      <Box
        className={"JS-VerticalArticlegroupTab-Container"}
        sx={{
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
          paddingX: 4,
          paddingY: 2,
          height: 1,
          position: "relative",
        }}
        ref={box}
      >
        {/*<FixSelectedArticlegroup articlegroups={props.articlegroups} />*/}
        <ButtonBase
          ref={home}
          id={"JS-VerticalArticlegroupTab-Home"}
          className={clsx("JS-VerticalArticlegroupTab-Home")}
          onClick={() => {
            if (articlegroupsShortcutPage && selectedArticlegroup) {
              dispatch(setSelectedArticlegroup({ articlegroup: null, articlegroupsMap: {} }));
              return;
            }
            openDialog({
              children: (
                <AreYouSureStartOverDialog
                  message={<FormattedMessageJamezz id={"Are you sure you want to start over?"} />}
                  onOk={() => {
                    goToHome();
                  }}
                  CancelText={<FormattedMessageJamezz id={"Cancel order"} />}
                  OkText={
                    <>
                      <FormattedMessageJamezz id={"Continue ordering"} />
                      ...
                    </>
                  }
                />
              ),
              sx: {
                ".MuiDialog-paper": {
                  borderRadius: 6,
                },
              },
            });
          }}
          sx={{ width: 1 }}
        >
          <Box
            sx={{
              width: 1,
              height: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ height: 1, width: 1, flex: "1", textAlign: "center", padding: 1 }}>
              {!logoImage && <img src={JamezzLogo} style={{ height: "100%", width: "100%", objectFit: "contain" }} />}
              {logoImage && <Image src={logoImage} style={{ height: "100%", width: "100%", objectFit: "contain" }} />}
            </Box>
            {/*{articlegroup.imageSrcs[0] ? (*/}
            {/*  <img*/}
            {/*    src={articlegroup.imageSrcs[0]}*/}
            {/*    style={{*/}
            {/*      width: "100%",*/}
            {/*      flex: 1,*/}
            {/*      objectFit: "contain",*/}
            {/*    }}*/}
            {/*  />*/}
            {/*) : (*/}
            {/*  <Typography sx={{ width: 1, flex: 1, height: 0, minHeight: 150 }}></Typography>*/}
            {/*)}*/}
            {/*<Typography*/}
            {/*  className={"JS-VerticalArticlegroupTab-Text"}*/}
            {/*  sx={{ fontSize: 32, color: "primary.main", width: 1, overflowWrap: "break-word" }}*/}
            {/*>*/}
            {/*  {articlegroup.alternativeName1 ?? articlegroup.name}*/}
            {/*</Typography>*/}
          </Box>
        </ButtonBase>

        {props.articlegroups?.map((articlegroup) => {
          if (articlegroup.showInCategoryMenu) {
            return (
              <ButtonBase
                key={articlegroup.id}
                id={"ArticlegroupTab-" + articlegroup.id}
                className={clsx(
                  "JS-VerticalArticlegroupTab-Button",
                  articlegroup.id === selectedArticlegroup?.id && "JS-VerticalArticlegroupTab-Button-Selected"
                )}
                onClick={() => {
                  scrollTabBlocked.current = true;
                  dispatch(setSelectedArticlegroup({ articlegroup, articlegroupsMap }));
                }}
                sx={{
                  width: "100%",
                  height: 350,
                  borderBottom: articlegroup.id === selectedArticlegroup?.id ? "5px solid" : "unset",
                  borderColor: "primary.main",
                  transition: "all 800ms",
                }}
              >
                <Box
                  sx={{
                    width: 1,
                    height: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: articlegroup.images[0] ? undefined : "center",
                  }}
                >
                  {
                    articlegroup.images[0] ? (
                      <Image
                        srcSet={articlegroup.images[0]?.conversions?.responsive?.srcset}
                        style={{
                          width: "150px",
                          minHeight: 200,
                          flex: 1,
                          objectFit: "contain",
                          margin: "0 auto",
                        }}
                      />
                    ) : null // <Typography sx={{ width: 1, flex: 1, height: 0, minHeight: 150 }}></Typography>
                  }

                  <Typography
                    className={clsx(
                      "JS-VerticalArticlegroupTab-Text",
                      articlegroup.id === selectedArticlegroup?.id && "JS-VerticalArticlegroupTab-Text-Selected"
                    )}
                    sx={{
                      fontSize: 24,
                      color: "background.paper",
                      fontWeight: 800,
                      width: 1,
                      textTransform: "uppercase",
                      overflowWrap: "break-word",
                      transition: "all 100ms",
                    }}
                  >
                    {getArticlegroupName(articlegroup, lang)}
                  </Typography>
                </Box>
              </ButtonBase>
            );
          }
        })}
      </Box>
      {isToTopScrollable && (
        <IconButton
          className={"JS-VerticalArticlegroupTab-ButtonTop"}
          size={"large"}
          sx={{
            zIndex: 10,
            position: "absolute",
            top: 0,
            marginX: "auto",
            width: 1,
            color: "primary.dark",
          }}
          aria-label="close"
          onClick={() => {
            const element = home.current;
            scrollIntoView(
              element,
              {
                time: 200,
                align: {
                  top: 0,
                  topOffset: 600 - box.current.scrollTop, // margin
                },
                validTarget: () => true,
              },
              () => {}
            );
          }}
        >
          {/*<Bouncer>*/}
          <ArrowBackIos sx={{ width: 120, height: 120, transform: "rotate(90deg)", transformOrigin: "center" }} />
          {/*</Bouncer>*/}
        </IconButton>
      )}
      {isToDownScrollable && (
        <IconButton
          className={"JS-VerticalArticlegroupTab-ButtonDown"}
          size={"large"}
          sx={{
            zIndex: 10,
            position: "absolute",
            bottom: 0,
            marginX: "auto",
            width: 1,
            color: "primary.dark",
          }}
          aria-label="close"
          onClick={() => {
            const element = home.current;
            scrollIntoView(
              element,
              {
                time: 200,
                align: {
                  top: 0,
                  topOffset: -600 - box.current.scrollTop, // margin
                },
                validTarget: () => true,
              },
              () => {}
            );
          }}
        >
          {/*<Bouncer>*/}
          <ArrowBackIos sx={{ width: 120, height: 120, transform: "rotate(-90deg)", transformOrigin: "center" }} />
          {/*</Bouncer>*/}
        </IconButton>
      )}
    </Box>
  );
}

export default VerticalArticlegroupTab;
