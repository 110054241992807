import { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import store, { useAppSelector } from "../../../global/utils/redux/store";
import { setMenuTagPreferences } from "../../../global/utils/redux/menuStateSlice";
import MenuTag, { getMenuTagTranslatedName } from "../../../global/utils/models/menu/MenuTag";
import { addAllergenOrderArticle, removeAllergenOrderArticle } from "../../../global/utils/redux/shoppingCartSlice";
import { initOrderArticle } from "../../../global/utils/models/order/OrderArticle";
import { selectArticlesMap } from "../../../global/utils/redux/selectors/selectArticlesMap";
import { unstable_LowPriority, unstable_scheduleCallback } from "scheduler";
import { selectAppLanguage } from "../../../kiosk/components/LanguageSelector/useLanguage.ts";
import { postAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics.ts";
import { Checkbox, createTheme, Paper, ThemeProvider, Typography } from "@mui/material";
import Image from "../../../global/components/Image.tsx";
import TouchRipple, { TouchRippleActions } from "@mui/material/ButtonBase/TouchRipple";
import { green, red } from "@mui/material/colors";
import { allergensDictionary } from "../../../global/utils/api/parseApis/useParseApiAllergen.ts";

export type FilterType = "PREFERENCE" | "ALLERGY";

export default function MenuFilterV2({
  menuTag,
  articleId,
  index,
  filterType,
}: {
  menuTag: MenuTag;
  articleId?: number;
  index: number;
  filterType: FilterType;
}) {
  const menuTagPreferenceStore = useAppSelector((state) => state.menuState.menuTagPreferences[menuTag.id]);
  const [menuTagPreference, setMenuTagPreference] = useState(menuTagPreferenceStore);

  const selectedLanguage = useAppSelector(selectAppLanguage);

  const Comp = menuTag.image ? allergensDictionary[menuTag.image]?.image : undefined;

  useEffect(() => {
    if (articleId) {
      if (menuTagPreference?.checked) {
        const articlesMap = selectArticlesMap(store.getState());
        const article = articlesMap[articleId];
        if (article) {
          unstable_scheduleCallback(unstable_LowPriority, function () {
            store.dispatch(
              addAllergenOrderArticle({
                allergenId: menuTag.id,
                orderArticle: initOrderArticle(articlesMap, article, 1, "", false),
              })
            );
          });
        }
      } else {
        unstable_scheduleCallback(unstable_LowPriority, function () {
          store.dispatch(removeAllergenOrderArticle({ allergenId: menuTag.id }));
        });
      }
    }
  }, [menuTag.id, menuTagPreference, articleId]);

  const onChange = useCallback(() => {
    postAnalyticsEvent({
      category: "MenuFilter",
      action: "setMenuTagPreferences(" + menuTag.name + ")",
    });
    const newChecked = !menuTagPreference?.checked;

    setMenuTagPreference({
      checked: newChecked,
      menuTag: menuTag,
    });

    unstable_scheduleCallback(unstable_LowPriority, function () {
      store.dispatch(
        setMenuTagPreferences({
          id: menuTag.id,
          value: { checked: newChecked, menuTag: menuTag },
        })
      );
    });

    if (articleId) {
      if (newChecked) {
        const articlesMap = selectArticlesMap(store.getState());
        const article = articlesMap[articleId];
        if (article) {
          unstable_scheduleCallback(unstable_LowPriority, function () {
            store.dispatch(
              addAllergenOrderArticle({
                allergenId: menuTag.id,
                orderArticle: initOrderArticle(articlesMap, article, 1, "", false),
              })
            );
          });
        }
      } else {
        unstable_scheduleCallback(unstable_LowPriority, function () {
          store.dispatch(removeAllergenOrderArticle({ allergenId: menuTag.id }));
        });
      }
    }
  }, [articleId, menuTag, menuTagPreference?.checked]);
  const rippleRef = useRef<TouchRippleActions>(null);

  return (
    <>
      <Paper
        onMouseDown={(e) => {
          if (rippleRef.current) {
            rippleRef.current.start(e);
          }
        }}
        onMouseUp={(e) => {
          if (rippleRef.current) {
            rippleRef.current.stop(e);
          }
        }}
        sx={{
          position: "relative",
          backgroundColor: menuTagPreference?.checked
            ? filterType == "PREFERENCE"
              ? green[500]
              : red[500]
            : "background.paper",
          width: 1,
          gridArea: `${index + 1} / ${1} / ${index + 2} / ${4}`,
        }}
        onClick={() => {
          onChange();
        }}
      >
        <TouchRipple ref={rippleRef} center={false} />
      </Paper>
      <Box
        sx={{
          position: "relative",
          pointerEvents: "none",
          aspectRatio: 1,
          gridArea: `${index + 1} / ${1} / ${index + 2} / ${2}`,
          alignSelf: "center",
          height: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {Comp ? (
          <Comp
            propsStyle={{ width: 32, height: 32, objectFit: "contain" }}
            className={"JS-MenuTag-Allergen JS-MenuTag-Allergen-" + menuTag.id}
          />
        ) : menuTag.mediaUrl ? (
          <Image
            style={{ width: "32px", height: "32px" }}
            srcSet={menuTag.mediaUrl.conversions?.responsive?.srcset}
            className={"JS-MenuTag-Allergen JS-MenuTag-Allergen-" + menuTag.id}
          />
        ) : null}
      </Box>

      <Typography
        sx={{
          position: "relative",
          pointerEvents: "none",
          gridArea: `${index + 1} / ${2} / ${index + 2} / ${3}`,
          justifySelf: "flex-start",
          alignSelf: "center",
        }}
      >
        {getMenuTagTranslatedName(menuTag, selectedLanguage)}
      </Typography>
      <ThemeProvider
        theme={createTheme({ palette: { primary: { main: filterType == "PREFERENCE" ? green[900] : red[900] } } })}
      >
        <Checkbox
          sx={{
            position: "relative",
            pointerEvents: "none",
            gridArea: `${index + 1} / ${3} / ${index + 2} / ${4}`,
          }}
          checked={menuTagPreference?.checked ?? false}
          onChange={onChange}
        />
      </ThemeProvider>
    </>
  );
}
