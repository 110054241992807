import { useCallback } from "react";
import store from "../../../utils/redux/store.tsx";
import { selectArticleTriggers } from "../../../utils/redux/selectors/upsells/selectArticleTriggers.ts";
import { selectMenuTriggers } from "../../../utils/redux/selectors/upsells/selectMenuTriggers.ts";
import OrderArticle from "../../../utils/models/order/OrderArticle.ts";
import Rule from "../../../utils/automation/Rule.ts";
import _ from "lodash";

export default function useRulesForOrderArticle() {
  return useCallback((orderArticle: OrderArticle) => {
    const articleRules = selectArticleTriggers(store.getState());
    const menuRules = selectMenuTriggers(store.getState());

    const rules: Rule[] = [];
    if (orderArticle.articlegroup?.id) {
      menuRules[orderArticle.articlegroup?.id]?.forEach((rule: Rule) => {
        rules.push(rule);
      });
    }
    articleRules[orderArticle.article.id]?.forEach((rule: Rule) => {
      rules.push(rule);
    });

    // *------------------------------------------------------------*
    // | THIS IS A WORKAROUND                                       |
    // | The pages are sent to the frontend in the _correct_ order. |
    // | However, because popups are shown using a stack, they      |
    // | appear in reverse order to the customer.                   |
    // | Manually reverting the pages here solves the issue         |
    // *------------------------------------------------------------*
    return _.reverse(_.uniq(rules));
  }, []);
}
