import { useCallback, useEffect, useMemo, useRef } from "react";
import Box from "@mui/material/Box";
import { Button, Collapse, Grid, Paper, Skeleton, Typography } from "@mui/material";
import useCheckPayment from "../../../global/utils/useCheckPayment";
import { routes } from "../../../global/utils/config";
import JamezzLoading from "../../../global/components/JamezzLoading/JamezzLoading";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import useKioskSendCheckout2 from "../../utils/useKioskSendCheckout2";
import store, { useAppSelector } from "../../../global/utils/redux/store";
import { NewPiggyPointsStatus, useShowNewPiggyPoints } from "../../../global/utils/piggy/useShowNewPiggyPoints";
import useAppNavigate from "../../../global/utils/useAppNavigate";
import { useSearchParams } from "react-router-dom";
import { OrderState } from "../../../types/shared/checkPayment";
import { orderSucceeded, selectAutoAddedItems } from "../../../global/utils/redux/shoppingCartSlice";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import { postAnalyticsEvent, submitEComEvent } from "../../../global/utils/analytics/useAnalytics";
import StopButton from "./StopButton.tsx";
import BackgroundMediaUrlFromSalesarea from "../../hooks/useBackgroundMediaUrl.tsx";
import { useCustomTexts } from "../../../global/utils/useCustomTexts";
import { selectVoucherOrderItems } from "../../../global/utils/redux/selectors/selectVoucherOrderItems.tsx";

export function useGoToHomeKiosk() {
  const navigate = useAppNavigate();
  const { closeAllDialogs } = useDialog();
  return useCallback(() => {
    const startUrl = store.getState().global.salesarea.start_url;
    closeAllDialogs();
    if (startUrl) {
      window.location.href = startUrl;
    } else {
      navigate(routes.kiosk.rootPath, { replace: true });
    }
  }, [closeAllDialogs, navigate]);
}

function ReturnPage() {
  const { orderState, responseData } = useCheckPayment();
  const goToHome = useGoToHomeKiosk();
  const afterOrderTimeout = useAppSelector((state) => state.global.salesarea?.afterOrderTimeout);
  const { sendOrder } = useKioskSendCheckout2();
  const orderMessage = useRef<HTMLElement>(null);
  const { newlyReceivedPoints, onAfterPayment, status: piggyNewPointsStatus } = useShowNewPiggyPoints();
  const customTexts = useCustomTexts(["order_dispatch_error"]);
  const items = useMemo(() => {
    return store.getState().shoppingCart.items;
  }, []);
  const autoAddedItems = useMemo(() => {
    return selectAutoAddedItems(store.getState());
  }, []);

  const voucherOrderItems = useMemo(() => {
    return selectVoucherOrderItems(store.getState());
  }, []);

  useEffect(() => {
    if (orderState === OrderState.SUCCESS) {
      onAfterPayment(responseData.current.timestamp);
    }
  }, [onAfterPayment, orderState, responseData]);

  useEffect(() => {
    let timeout: number | null = null;
    if (orderState === OrderState.SUCCESS) {
      store.dispatch(orderSucceeded());
      postAnalyticsEvent({
        category: "ReturnPage",
        action: "OrderState.SUCCESS",
      });
      submitEComEvent("purchase", autoAddedItems, items, voucherOrderItems, {
        value: responseData.current.orderValue,
        transaction_id: responseData.current.payTransID ?? "n/a",
      });
      submitEComEvent("add_payment_info", autoAddedItems, items, voucherOrderItems, {
        value: responseData.current.orderValue,
        payment_type: responseData.current.payMethod ?? "CASH",
      });
      timeout = window.setTimeout(
        () => {
          goToHome();
        },
        Math.max(3000, afterOrderTimeout * 1000)
      );
    }
    return () => {
      if (timeout != null) {
        clearTimeout(timeout);
      }
    };
  }, [orderState, afterOrderTimeout, goToHome, responseData, autoAddedItems, items, voucherOrderItems]);

  const isLoading = useMemo(() => {
    return !(
      orderState === OrderState.SUCCESS ||
      orderState === OrderState.FAILED ||
      orderState === OrderState.PAYMENT_FAILED ||
      orderState === OrderState.ORDER_FAILED ||
      orderState === OrderState.PRINT_FAILED
    );
  }, [orderState]);

  const isNotDispatched = useMemo(() => {
    return !isLoading && orderState === OrderState.ORDER_FAILED;
  }, [orderState, isLoading]);

  const isPending = useMemo(() => {
    return !isLoading && orderState === OrderState.PAYMENT_PENDING;
  }, [orderState, isLoading]);

  const isFailed = useMemo(() => {
    return !isLoading && orderState !== OrderState.SUCCESS;
  }, [orderState, isLoading]);

  const themeColor = useMemo(() => {
    if (isLoading) {
      return "primary.main";
    } else if (isFailed) {
      return "error.main";
    } else {
      return "#68ab81";
    }
  }, [isFailed, isLoading]);

  useEffect(() => {
    if (orderMessage.current && responseData.current?.orderMsg) {
      orderMessage.current.innerHTML = responseData.current.orderMsg;
    }
  }, [orderMessage, orderState, responseData]);

  const message = useMemo(() => {
    switch (orderState) {
      case OrderState.WAITING_FOR_PAYMENT:
        return (
          <Typography sx={{ color: "lightgrey" }}>
            <FormattedMessageJamezz id={"Waiting for payment..."} />
          </Typography>
        );
      case OrderState.WAITING_FOR_PRINT:
        return (
          <Typography sx={{ color: "lightgrey" }}>
            <FormattedMessageJamezz id={"Printing ticket..."} />
          </Typography>
        );
      case OrderState.WAITING_ON_DISPATCH:
        return (
          <Typography sx={{ color: "lightgrey", whiteSpace: "nowrap" }}>
            <FormattedMessageJamezz id={"Waiting for order response..."} />
          </Typography>
        );
      case OrderState.INIT:
        return (
          <Typography sx={{ color: "lightgrey" }}>
            <FormattedMessageJamezz id={"Checking order..."} />
          </Typography>
        );
      case OrderState.SUCCESS:
        return (
          <Typography sx={{ color: "lightgrey" }}>
            <FormattedMessageJamezz id={"Your order is placed successfully!"} />
          </Typography>
        );
      case OrderState.FAILED:
        return (
          <Typography sx={{ color: "error.main" }}>
            <FormattedMessageJamezz id={"There was some error!"} />
          </Typography>
        );
      case OrderState.PAYMENT_FAILED:
        return (
          <Typography sx={{ color: "error.main" }}>
            <FormattedMessageJamezz id={"Payment failed..."} />
          </Typography>
        );
      case OrderState.ORDER_FAILED:
        return (
          <Typography sx={{ color: "error.main", whiteSpace: "break-spaces", textAlign: "center" }}>
            {customTexts["order_dispatch_error"] ?? <FormattedMessageJamezz id={"Dispatch failed"} />}
          </Typography>
        );
      case OrderState.PRINT_FAILED:
        return (
          <Typography sx={{ color: "error.main" }}>
            <FormattedMessageJamezz id={"Printing failed..."} />
          </Typography>
        );
    }
    return (
      <Typography sx={{ color: "lightgrey" }}>
        <FormattedMessageJamezz id={"Checking order..."} />
      </Typography>
    );
  }, [customTexts, orderState]);

  const piggyResult =
    piggyNewPointsStatus === NewPiggyPointsStatus.PiggyNotEnabled ? null : (
      <Box data-cy="orderpage-piggy-points-earned" sx={{ mt: 2, textAlign: "center" }}>
        <Typography variant="h2" fontSize="1.2em" sx={{ mb: 1, mt: 2 }}>
          <FormattedMessageJamezz id="Piggy.gainedPoints.title" />
        </Typography>
        {piggyNewPointsStatus === NewPiggyPointsStatus.Loading ? (
          <Typography>
            <Skeleton variant="text" sx={{ width: "80vw" }} />
          </Typography>
        ) : piggyNewPointsStatus === NewPiggyPointsStatus.MissedOutOnPoints ? (
          <Typography>
            <FormattedMessageJamezz id="Piggy.gainedPoints.missedOut" />
          </Typography>
        ) : piggyNewPointsStatus === NewPiggyPointsStatus.Success ? (
          <Typography>
            <FormattedMessageJamezz
              id="Piggy.gainedPoints.receivedPoints"
              values={{
                newPoints: newlyReceivedPoints,
              }}
            />
          </Typography>
        ) : piggyNewPointsStatus === NewPiggyPointsStatus.Error ? (
          <Typography>
            <FormattedMessageJamezz id="Piggy.gainedPoints.error" />
          </Typography>
        ) : null}
      </Box>
    );

  return (
    <>
      <BackgroundMediaUrlFromSalesarea
        keyName={"extra_settings__kioskv5_background_of_return_page"}
        sx={{
          display: "flex",
          width: 1,
          height: 1,
          position: "relative",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="JS-ReturnPage-Root"
        fallbackBackgroundColor={"primary.light"}
      >
        <Paper
          sx={{
            width: 720,
            zIndex: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 8,
            borderRadius: 8,
          }}
        >
          <Box sx={{ width: 300, height: 300 }}>
            <JamezzLoading
              isLoading={isLoading}
              color={themeColor}
              isSuccessful={!isFailed && !isLoading && !isPending}
            />
          </Box>
          <Box sx={{ marginBottom: 5, textAlign: "center" }}>{message}</Box>
          <Collapse in={piggyNewPointsStatus !== NewPiggyPointsStatus.Loading}>{piggyResult}</Collapse>
          <Collapse in={!isLoading && !isFailed && !isPending && !isNotDispatched}>
            <Box sx={{ width: 1, display: "flex", flexDirection: "column" }}>
              <Typography sx={{ marginY: 3 }} ref={orderMessage} />
              <Button
                data-cy="start-new-order-btn"
                variant={"contained"}
                color={"primary"}
                sx={{ color: "white" }}
                onClick={() => {
                  goToHome();
                }}
              >
                <FormattedMessageJamezz id={"Start new order"} />
              </Button>
            </Box>
          </Collapse>
          <Collapse unmountOnExit in={!isLoading && isFailed && !isPending ? true : false}>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <Button
                  sx={{ paddingX: 8, paddingY: 4, color: "white", height: 1, width: 1 }}
                  variant={"contained"}
                  color={"error"}
                  size={"large"}
                  onClick={() => {
                    sendOrder();
                  }}
                >
                  <FormattedMessageJamezz id={"Retry"} />
                </Button>
              </Grid>
              <Grid item xs={6}>
                <StopButton />
              </Grid>
            </Grid>
          </Collapse>
        </Paper>
      </BackgroundMediaUrlFromSalesarea>
    </>
  );
}

export function ReturnPageWrapper() {
  const [params] = useSearchParams();

  const oid = params.get("oid") ?? "oid";

  return <ReturnPage key={oid} />;
}
