import { Button, SxProps } from "@mui/material";
import { ReactNode } from "react";
import { Theme } from "@mui/material/styles";
import { useAppSelector } from "../../global/utils/redux/store";
import useTippingAsync from "../pages/CheckoutPage/TippingAtCheckout.tsx";
import { useAccountSubTotalValue } from "../../global/utils/redux/accountSlice.tsx";
import { useSendOrder } from "../pages/OrderPage/SendOrder.tsx";

interface Props {
  isValid: boolean;
  children: ReactNode;
  sx?: SxProps<Theme>;
}

export default function SendOrderButton(props: Props) {
  const isValid = props.isValid;
  const items = useAppSelector((state) => state.shoppingCart.items);

  const subTotalAmount = useAccountSubTotalValue();
  const { tipComponent, askForTip } = useTippingAsync(subTotalAmount);
  const sendOrder = useSendOrder(askForTip);
  return (
    <>
      {tipComponent}
      <Button
        data-cy="btn-send-order"
        sx={{
          margin: 4,
          marginBottom: 8,
          backgroundColor: "primary.contrastText",
          color: "primary.light",
          borderRadius: "30px",
          fontSize: 48,
          fontWeight: 800,
          padding: "20px 48px",
          ...props.sx,
        }}
        variant={"contained"}
        type={"submit"}
        size={"large"}
        className={"JS-SendOrderButton-Root"}
        disabled={!isValid || items.length === 0}
        onClick={sendOrder}
      >
        {props.children}
      </Button>
    </>
  );
}
