import { useEffect } from "react";
import Box from "@mui/material/Box";
import LanguageSelector from "../../components/LanguageSelector/LanguageSelector";
import OrderModeSelector from "../../components/OrderModeSelector/OrderModeSelector";
import useOrderModeSelector from "../../components/OrderModeSelector/useOrderModeSelector";
import { Paper, Stack, Typography } from "@mui/material";
import useSalesareaIsOpen from "../../../global/utils/useSalesareaIsOpen";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import {
  defaultCurrency,
  setCurrency,
  setPriceKey,
  setSelectedArticlegroup,
} from "../../../global/utils/redux/menuSlice";
import {
  enabledHideItemsBySelectedAllergens,
  setArticleSearchText,
  orderCreated,
} from "../../../global/utils/redux/globalSlice";
import { setStateOfArticleTraits } from "../../../global/utils/redux/traitFilterSlice";
import { currencies } from "../../../global/utils/models/currencies/Currency";
import { useResetLanguage } from "../../components/LanguageSelector/useLanguage";
import { clearShoppingCart } from "../../../global/utils/redux/shoppingCartSlice";
import { BlinkstickStatus, setBlinkstickStatus } from "../../../global/utils/redux/blinkstickSlice";
import { setAllMenuTagPreferences } from "../../../global/utils/redux/menuStateSlice";
import { clearOrderCustomFields } from "../../../global/utils/redux/customFieldsSlice";
import { useScroll } from "../../../global/utils/ScrollProvider";
import { resetProppos } from "../../../global/utils/redux/propposSlice";
import useResetPiggy from "../../../global/utils/piggy/useResetPiggy";
import { toast } from "react-toastify";
import { kioskResetted, setWheelchairFriendly } from "../../../global/utils/redux/kioskSlice";
import { useInvalidatePiggyCache } from "../../../global/utils/redux/api/piggyApi";
import { resetUserInputKiosk } from "../../../global/utils/redux/userInputSlice";
import { useIdleTimer } from "react-idle-timer";
import { selectedPackagingTypeResetted } from "../../../global/utils/redux/packagingsSlice";
import { BackgroundUsingMediaUrl, useFindScheduledMedia } from "../../hooks/useBackgroundMediaUrl";
import ReactPlayer from "react-player";

export default function HomePage() {
  const { orderModes, onClickOrderMode } = useOrderModeSelector();
  const dispatch = useAppDispatch();
  const { resetScroll } = useScroll();
  const isOpen = useSalesareaIsOpen();

  const resetLanguage = useResetLanguage();
  const resetPiggy = useResetPiggy();

  // @deprecated
  const backgroundVideo = useAppSelector((state) => state.global.salesarea?.backgroundVideo);
  const scheduledHomePage = useFindScheduledMedia();
  const mediaUrlOpen = useAppSelector(
    (state) => state.global.salesarea?.media_urls?.extra_settings__kioskv5_background_of_home_page_open?.[0]
  );
  const mediaUrlClosed = useAppSelector(
    (state) => state.global.salesarea?.media_urls?.extra_settings__kioskv5_background_of_home_page_closed?.[0]
  );
  const valuta = useAppSelector((state) => state.global.salesarea?.valuta);
  const salesareaId = useAppSelector((state) => state.global.salesarea?.id);
  const refreshTimeout = useAppSelector((state) => state.global.salesarea?.refreshTimeout);

  useIdleTimer({
    onIdle: () => {
      resetLanguage();
    },
    timeout: Math.max(30000, Math.min(24 * 3600 * 1000, refreshTimeout * 1000)),
  });

  const invalidatePiggyCache = useInvalidatePiggyCache();
  useEffect(() => {
    _.defer(() => {
      resetPiggy();
      toast.dismiss();
      dispatch(orderCreated(null));
      dispatch(clearShoppingCart());
      dispatch(clearOrderCustomFields());
      dispatch(setSelectedArticlegroup({ articlegroup: null, articlegroupsMap: {} }));
      dispatch(setArticleSearchText(""));
      dispatch(setPriceKey(""));
      dispatch(setStateOfArticleTraits({}));
      dispatch(setCurrency(currencies[valuta] ?? defaultCurrency));
      dispatch(setBlinkstickStatus(BlinkstickStatus.Default));
      dispatch(setAllMenuTagPreferences({}));
      dispatch(resetProppos());
      dispatch(enabledHideItemsBySelectedAllergens(false));
      dispatch(resetUserInputKiosk());
      dispatch(selectedPackagingTypeResetted());
      resetScroll();
      dispatch(setWheelchairFriendly(false));
      resetLanguage();
      invalidatePiggyCache();
      dispatch(kioskResetted());
    });
  }, [dispatch, resetLanguage, valuta]);

  if (!salesareaId) {
    return null;
  }

  const mediaUrl = scheduledHomePage ?? (isOpen ? mediaUrlOpen : (mediaUrlClosed ?? mediaUrlOpen));

  return (
    <BackgroundUsingMediaUrl
      mediaUrl={mediaUrl}
      data-cy="homescreen"
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        width: 1,
        height: 1,
        position: "relative",
        flexDirection: "column",
        backgroundSize: "cover",
      }}
      sxWrapper={{
        background:
          mediaUrl || backgroundVideo
            ? undefined
            : `url(${"https://jamezz.blob.core.windows.net/jamezz/appv5/assets/images/homescreen.webp"})`,
      }}
      styleReactPlayer={{ display: "inline-flex" }}
      className={"JS-HomeScreen-Root"}
      onClick={() => {
        if ((orderModes?.length ?? 1) <= 1 && isOpen) {
          onClickOrderMode(orderModes?.[0]);
        }
      }}
    >
      {mediaUrlOpen == null && scheduledHomePage == null && backgroundVideo ? (
        <ReactPlayer
          style={{ position: "fixed" }}
          width={"100%"}
          height={"100%"}
          url={backgroundVideo}
          loop={true}
          playing={true}
          controls={false}
          playsinline={true}
          muted={true}
          config={{}}
        />
      ) : null}
      <Stack gap={21} sx={{ mb: 32 }}>
        <Box
          className={"JS-HomeScreen-Content"}
          sx={{
            display: "flex",
            alignItems: "center",
            width: 1,
            justifyContent: "center",
            // flex: "1 1 0%"
          }}
        >
          {!isOpen ? (
            <Paper
              sx={{
                width: 720,
                zIndex: 3,
                flexDirection: "column",
                alignItems: "center",
                paddingY: 8,
                borderRadius: 8,
              }}
              className={"JS-HomeScreen-Content-KioskClosedMessage"}
            >
              <Typography sx={{ fontSize: 72, textAlign: "center", width: 1 }}>
                <FormattedMessageJamezz id={"This kiosk is turned off!"} />
              </Typography>
            </Paper>
          ) : null}
          {isOpen ? <OrderModeSelector /> : null}
        </Box>
        <Box className={"JS-HomeScreen-LanguageSelector-Wrapper"} sx={{ width: 1 }}>
          {isOpen ? <LanguageSelector /> : null}
        </Box>
      </Stack>
    </BackgroundUsingMediaUrl>
  );
}
