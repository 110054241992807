import { shoppingCartApi, Transaction } from "../redux/api/shoppingCartApi";
import {
  clearSharedShoppingCart,
  clearShoppingCart,
  orderArticlesReceivedFromTransactions,
  sharedShoppingCartReceived,
  sharedShoppingCartTransactionsReceived,
} from "../redux/shoppingCartSlice";
import store from "../redux/store";
import { setServerTimestamp } from "../redux/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BlockUserInput } from "@jamezz/react-components";

export type ShoppingCart = {
  uuid: string;
  webcode?: string;
};

export type SharedShoppingCart = ShoppingCart & {
  transactions: Transaction[];
  readyToOrder: any;
};

export function fetchSharedShoppingCart({ showLoading }: { showLoading: boolean } = { showLoading: false }) {
  if (store.getState().shoppingCart.sharedShoppingCart == null) {
    store.dispatch(clearShoppingCart());
  }

  store.dispatch(
    shoppingCartApi.util.invalidateTags([{ type: "sharedShoppingCart-get", id: store.getState().global.webcode }])
  );
  const result = store.dispatch(
    shoppingCartApi.endpoints.fetchSharedShoppingCart.initiate(store.getState().global.webcode)
  );
  let loadingUuid: string | null = null;
  if (showLoading) {
    loadingUuid = uuidv4();
    BlockUserInput.blockingStarted(loadingUuid);
  }
  result
    .then((response) => {
      processSharedShoppingCart(response);
    })
    .finally(() => {
      if (loadingUuid) {
        BlockUserInput.blockingStopped(loadingUuid);
      }
    });
  result.unsubscribe();
}

export function processSharedShoppingCart(response: any) {
  if ((response.data as any)?.data?.uuid) {
    const sharedShoppingCart: SharedShoppingCart = (response.data as any)?.data as SharedShoppingCart;
    if (sharedShoppingCart.uuid != store.getState().shoppingCart.sharedShoppingCart?.uuid) {
      store.dispatch(clearSharedShoppingCart());
      store.dispatch(clearShoppingCart());
    }

    store.dispatch(sharedShoppingCartReceived(sharedShoppingCart));
    store.dispatch(setServerTimestamp((response.data as any)?.timestamp));

    store.dispatch(
      sharedShoppingCartTransactionsReceived(
        sharedShoppingCart.transactions.map((transaction) => ({ uuid: transaction.uuid }))
      )
    );
    store.dispatch(
      orderArticlesReceivedFromTransactions(
        sharedShoppingCart.transactions.map((transaction) => transaction.orderArticle).filter((a) => a)
      )
    );
  } else {
    store.dispatch(clearSharedShoppingCart());
    store.dispatch(clearShoppingCart());
    store.dispatch(setServerTimestamp((response.data as any)?.timestamp));
  }
}
