import Article from "../models/menu/Article";
import useMenuTagFilters from "../menu/filters/useMenuTagFilters";
import { useMemo } from "react";
import MenuTag from "../models/menu/MenuTag";

export default function useMenuTagsForArticle(article: Article | undefined) {
  const allMenuTags = useMenuTagFilters();

  return useMemo<MenuTag[]>(() => {
    return (
      (article?.menuTagIds?.map((id) => allMenuTags.find((tag) => tag.id === id)).filter((t) => t) as MenuTag[]).sort(
        (a, b) => a.id.localeCompare(b.id)
      ) ?? []
    );
  }, [article, allMenuTags]);
}
