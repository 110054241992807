import { useEffect, useMemo, useRef } from "react";
import { useScroll } from "../../../../global/utils/ScrollProvider";
import { useAppSelector } from "../../../../global/utils/redux/store";
import { useArticleTraitFiltering } from "../../../../global/utils/menu/filters/useArticleTraitFiltering";
import { getArticlesByArticlegroupId } from "../../../../global/utils/models/menu/Menu";
import Box from "@mui/material/Box";
import LazyLoadItems from "../LazyLoadItems";
// @ts-ignore
import scrollIntoView from "scroll-into-view";
import { Grid, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz";
import Image from "../../../../global/components/Image";
import { selectArticleTraitsMap } from "../../../../global/utils/redux/selectors/selectArticleTraitsMap";
import { selectArticlesMap } from "../../../../global/utils/redux/selectors/selectArticlesMap";
import { selectArticlegroupArticlesMap } from "../../../../global/utils/redux/selectors/selectArticlegroupArticlesMap";
import MultipleArticlegroupBanner from "./MultipleArticlegroupBanner";
import { MediaUrl } from "../../../../global/utils/redux/api/menuDataApi.ts";

interface Props {
  pixelHeightCartButton: string;
}

export default function MultipleLayersMenuContent(props: Props) {
  const { scrollContent } = useScroll();
  const selectedArticlegroup = useAppSelector((state) => state.menu.selectedArticlegroup);
  const { articleTraitsMap } = useAppSelector(selectArticleTraitsMap);
  const stateOfArticleTraits = useAppSelector((state) => state.traitFilter.stateOfArticleTraits);
  const { articleTraitFilter } = useArticleTraitFiltering();
  const articlesMap = useAppSelector(selectArticlesMap);
  const articlegroupArticlesMap = useAppSelector(selectArticlegroupArticlesMap);
  const articles = useMemo(() => {
    if (selectedArticlegroup) {
      return (
        getArticlesByArticlegroupId(articlesMap, articlegroupArticlesMap, selectedArticlegroup.id).filter(
          articleTraitFilter
        ) ?? []
      );
    } else {
      return (
        Object.keys(articlesMap)
          .map((articleId) => articlesMap[articleId])
          .filter(articleTraitFilter) ?? []
      );
    }
  }, [articlesMap, articlegroupArticlesMap, selectedArticlegroup, articleTraitFilter]);

  const topRef = useRef<any>(null);
  const setRenderUpToArticleIndexRef = useRef<any>(null);

  const widthPercentage = useMemo(() => {
    if (selectedArticlegroup) {
      return 1 / (selectedArticlegroup.numberOfColumns ?? 2);
    } else {
      return 1 / 3; // TODO customizable
    }
  }, [selectedArticlegroup]);

  useEffect(() => {
    if (topRef.current) {
      scrollIntoView(topRef.current, {
        time: 500,
        align: {
          top: 0,
        },
      });
    }
    if (setRenderUpToArticleIndexRef.current) {
      setRenderUpToArticleIndexRef.current(MultipleLayersMenuContent.itemsInitialRender);
    }
  }, [selectedArticlegroup]);

  const articleTraitBanner = useMemo<MediaUrl | undefined>(() => {
    let imageSrc: MediaUrl | undefined = undefined;

    Object.keys(stateOfArticleTraits).some((traitKey: string) => {
      if (stateOfArticleTraits[traitKey].length === 1) {
        imageSrc = articleTraitsMap[traitKey]?.values[stateOfArticleTraits[traitKey]?.[0]]?.mediaUrls?.[0];
        if (imageSrc) {
          return true;
        }
      }
      return false;
    });

    return imageSrc;
  }, [stateOfArticleTraits, articleTraitsMap]);

  return (
    <Box sx={{ flex: 1 }}>
      <div ref={topRef} />
      {articleTraitBanner != null ? (
        <Image
          srcSet={articleTraitBanner?.conversions?.responsive?.srcset}
          style={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
          }}
          className={"JS-ArticlegroupContent-Image"}
        />
      ) : null}
      {selectedArticlegroup && !articleTraitBanner ? (
        <MultipleArticlegroupBanner articlegroup={selectedArticlegroup} />
      ) : null}
      {articles.length === 0 ? (
        <Grid
          item
          xs={12}
          md={6}
          className={"JS-ArticleContent-Root"}
          sx={{ display: "flex", flexDirection: "column", margin: 3 }}
        >
          <Typography sx={{ fontSize: 32, fontStyle: "italic" }}>
            <FormattedMessageJamezz id={"No articles found..."} />
          </Typography>
        </Grid>
      ) : null}
      <LazyLoadItems
        items={articles}
        setRenderUpToArticleIndexRef={setRenderUpToArticleIndexRef}
        numberOfItemsToRenderInitially={MultipleLayersMenuContent.itemsInitialRender}
        numberOfItemsToAddEachLoad={MultipleLayersMenuContent.itemsPerRender}
        scrollContent={scrollContent}
        width={widthPercentage}
        pixelHeightCartButton={props.pixelHeightCartButton}
      />
    </Box>
  );
}

MultipleLayersMenuContent.itemsInitialRender = 20;
MultipleLayersMenuContent.itemsPerRender = 20;
