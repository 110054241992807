import { Stack, Typography } from "@mui/material";
import QRCode from "react-qr-code";
import store, { RootState, useAppSelector } from "../../../../global/utils/redux/store.tsx";
import {
  piggyContactIdentifierDialogOpened,
  piggyScanDialogOpened,
} from "../../../../global/utils/redux/piggySlice.tsx";
import Image from "../../../../global/components/Image.tsx";
import QrCodeScanIcon from "./QrCodeScanIcon.tsx";
import { usePiggyUser } from "../../../utils/piggy/piggy.tsx";
import { LoyaltyCard } from "../../../components/piggy/Loyalty/LoyaltyCard/LoyaltyCard.tsx";
import Box from "@mui/material/Box";

export default function PiggyScanLoyaltyButton({ showText }: { showText: boolean }) {
  const showLoyalty = useAppSelector(
    (state) => state.global.salesarea.piggy.loyalty.enabled && state.global.salesarea.piggy.loyalty.show_loyalty_in_app
  );
  const prepaidEnabled = useAppSelector((state) => state.global.salesarea.piggy.prepaid.enabled);
  const { isLoggedIn } = usePiggyUser();
  const signUpUrl = useAppSelector((state) => state.global.salesarea?.piggy?.custom_data?.signUpUrl);
  const image = useAppSelector(
    (state: RootState) => state.global.salesarea.media_urls?.extra_settings__kioskv5_piggy_signup_image?.[0]
  );

  if (showLoyalty || prepaidEnabled) {
    return (
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={image ? "flex-end" : "space-around"}
        sx={{ height: 1, color: "white", width: 1 }}
        onClick={(e) => {
          e.stopPropagation();
          if (!isLoggedIn) {
            store.dispatch(piggyScanDialogOpened());
          } else {
            store.dispatch(piggyContactIdentifierDialogOpened());
          }
        }}
      >
        {isLoggedIn ? (
          <Box sx={{ px: 3, width: 1 }}>
            <LoyaltyCard isCompactView={true} />
          </Box>
        ) : (
          <>
            {image ? (
              <Image
                srcSet={image?.conversions?.responsive?.srcset ?? image?.conversions?.optimized?.srcset}
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            ) : (
              <>
                {showText ? (
                  <Stack sx={{ height: 1, mr: 1 }} justifyContent={"center"} alignItems={"center"}>
                    <Typography sx={{ fontSize: 24 }}>Sign up,</Typography>
                    <Typography sx={{ fontSize: 24 }}>Scan &</Typography>
                    <Typography sx={{ fontSize: 24 }}>Claim</Typography>
                  </Stack>
                ) : null}
                <QrCodeScanIcon innerColor={"white"} width={140} height={200} />
                {/*<QrCodeScan style={{ height: "100%", color: "white" }} color={"#ffffff"} />*/}
              </>
            )}
            {signUpUrl ? (
              <QRCode
                value={signUpUrl}
                style={{ height: "calc(100% - 32px)", margin: "16px 0 16px 0", aspectRatio: 1 }}
                size={168}
              />
            ) : null}
          </>
        )}
      </Stack>
    );
  } else {
    return null;
  }
}
