import { piggyErrorCodes, PiggyReward } from "../../../../../../types/shared/piggy.ts";
import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../../../../../global/utils/redux/store.tsx";
import { selectArticlesMap } from "../../../../../../global/utils/redux/selectors/selectArticlesMap.ts";
import { useClaimRewardMutation } from "../../../../../../global/utils/redux/api/piggyApi.ts";
import { useDialog } from "../../../../../../global/utils/dialog/DialogProvider.tsx";
import { assertIsPiggyErrorMessage } from "../../../../../../global/utils/piggy/usePiggyRewards.ts";
import { toast } from "react-toastify";
import {
  initOrderArticle,
  OrderArticleOrigin,
  shouldShowOrderArticleSelector,
} from "../../../../../../global/utils/models/order/OrderArticle.ts";
import OrderArticleSelectorPage from "../../../../../pages/OrderArticleSelectorPage/OrderArticleSelectorPage.tsx";
import SlideUpTransition from "../../../../../../global/components/SlideUpTransition.tsx";
import { piggyRewardClaimed } from "../../../../../../global/utils/redux/shoppingCartSlice.tsx";

import { findJamezzArticleForPiggyItem } from "./findJamezzArticleForPiggyItem.tsx";
import { BlockUserInput } from "@jamezz/react-components";

export function useClaimPiggyRewardDialog(item: PiggyReward | undefined) {
  const intl = useIntl();
  const articlesMap = useAppSelector(selectArticlesMap);

  const [
    claimReward,
    { data: rewardClaimedResponse, isSuccess: rewardClaimedSuccessfully, reset, isLoading, error, isError },
  ] = useClaimRewardMutation();
  const { openDialog } = useDialog();
  const dispatch = useAppDispatch();

  BlockUserInput.useBlockUserInput(isLoading);

  useEffect(() => {
    if (!item) return;
    if (isError) {
      try {
        assertIsPiggyErrorMessage(error);
      } catch (e) {
        toast.error(intl.formatMessage({ id: "Piggy.messages.error.unknownApiResponse" }));
        reset();
        return;
      }
      switch (error.data.context.code) {
        case piggyErrorCodes["claim limit reached"]:
          toast.error(intl.formatMessage({ id: "Piggy.messages.error.claimLimitReached" }, { item: item.title }));
          break;
        case piggyErrorCodes["out of stock"]:
          toast.error(intl.formatMessage({ id: "Piggy.messages.error.outOfStock" }, { item: item.title }));
          break;
        default:
          toast.error(intl.formatMessage({ id: "Piggy.messages.error.unknownApiResponse" }));
      }
      reset();
    }
  }, [intl, isError, error, item, reset]);

  useEffect(() => {
    if (!item) return;
    if (rewardClaimedSuccessfully && rewardClaimedResponse) {
      const article = findJamezzArticleForPiggyItem(item, articlesMap);
      if (!article) return;
      const order = initOrderArticle(articlesMap, article, 1);
      order.added_origin = OrderArticleOrigin.PIGGY;

      if (shouldShowOrderArticleSelector(order)) {
        openDialog({
          children: <OrderArticleSelectorPage article={article} isPiggyReward={true} />,
          fullScreen: "almostFullScreen",
          TransitionComponent: SlideUpTransition,
        });
      } else {
        toast.success(
          intl.formatMessage(
            {
              id: "Piggy.messages.rewardAdded",
            },
            {
              name: item.title,
            }
          )
        );
        dispatch(piggyRewardClaimed({ item, orderArticle: order }));
      }

      reset();
    }
  }, [dispatch, intl, item, articlesMap, openDialog, reset, rewardClaimedResponse, rewardClaimedSuccessfully]);

  const onClose = useCallback(
    (claimed: boolean) => {
      if (!item) return;
      if (claimed) {
        claimReward(item.uuid);
      }
    },
    [item, claimReward]
  );

  return {
    piggyRewardLoading: isLoading,
    onPiggyRewardDialogClose: onClose,
  };
}
