import useServiceArticles from "../../../global/components/useServiceArticles.tsx";
import AreYouSureDialog from "../AreYouSureDialog.tsx";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";
import { ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import SlideUpTransition from "../../../global/components/SlideUpTransition.tsx";
import React, { useMemo } from "react";
import { postAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics.ts";
import { useDialog } from "../../../global/utils/dialog/DialogProvider.tsx";
import useSendArticles from "../../../global/utils/useSendArticles.ts";
import clsx from "clsx";

export default function useServiceArticleListItem() {
  const serviceArticles = useServiceArticles();
  const { openDialog } = useDialog();
  const sendArticles = useSendArticles();

  return useMemo(
    () =>
      serviceArticles.map((serviceArticle, index) => {
        return (
          <ListItem
            className={clsx(
              "JS-ServiceBottomDrawer-ListItem",
              `JS-ServiceBottomDrawer-ListItem-ArticleId{${serviceArticle.articleId}}`
            )}
            key={"ServiceItem" + index}
            disablePadding
          >
            <ListItemButton
              data-cy={"service-drawer-service-request-id-" + serviceArticle.articleId}
              sx={{
                pl: 5,
                mx: 2,
                // border: "1px solid grey",
                borderRadius: 4,
                boxShadow: "0 1px 10px 0px rgba(0,0,0,0.1)",
              }}
              onClick={() => {
                postAnalyticsEvent({
                  category: "JamezzShowOrderButton",
                  action: "AreYouSureDialog",
                });
                openDialog({
                  children: (
                    <AreYouSureDialog
                      message={
                        <FormattedMessageJamezz id={"Are you sure you want to send the following service request?"} />
                      }
                      subtitle={
                        <Typography sx={{ textAlign: "center", fontSize: "1.1rem", fontWeight: 800, marginTop: 1 }}>
                          {serviceArticle.name}
                        </Typography>
                      }
                      onOk={() => {
                        sendArticles([serviceArticle.articleId]);
                      }}
                    />
                  ),
                  TransitionComponent: SlideUpTransition,
                });
              }}
            >
              {/*<ListItemIcon>{serviceArticle.icon}</ListItemIcon>*/}
              <ListItemText primary={serviceArticle.name} />
            </ListItemButton>
          </ListItem>
        );
      }),
    [openDialog, sendArticles, serviceArticles]
  );
}
