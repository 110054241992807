import { Button, Collapse, Divider, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useRef } from "react";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import OrderArticleContent from "./OrderArticleContent";
import CheckoutPage from "../CheckoutPage/CheckoutPage";
import SlideUpTransition from "../../../global/components/SlideUpTransition";
import useCurrency, { CurrencyLocation } from "../../../global/utils/useCurrency";
import CloseDialogButton from "../../components/Dialog/CloseDialogButton";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import useOpenOneTimeUpsellDialog, { OnetimeUpsell } from "../../../global/utils/hooks/useOpenOneTimeUpsellDialog";
import ArticleSuggestionPage from "../ArticleSuggestionPage/ArticleSuggestionPage";
import useSalesareaIsOpen from "../../../global/utils/useSalesareaIsOpen";
import AccountDiscount from "../../components/AccountDiscount/AccountDiscount";
import useIsWidescreen from "../../../global/utils/useIsWidescreen";
import store, { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import { useCustomTexts } from "../../../global/utils/useCustomTexts";
import { postAnalyticsEvent, submitEComEvent } from "../../../global/utils/analytics/useAnalytics";
import { simphonyCheckCalculate } from "../../../global/utils/redux/listeners/simphonyCheckCalculatorListener";
import useUpsellNoFoodOrNoDrinksInShoppingCart, {
  UpsellType,
} from "../../../global/utils/hooks/useUpsellNoFoodInShoppingCart";
import VoucherInput from "../../components/VoucherInput";
import { useAccountSubTotalValue, useAccountTotalValue } from "../../../global/utils/redux/accountSlice";
import DiscountsCheckCalculator from "../../../global/components/MWise/DiscountsCheckCalculator";
import { selectArticlesSum, selectAutoAddedItems } from "../../../global/utils/redux/shoppingCartSlice";
import { OptionTranslationsContext } from "../../../global/utils/translations/useOptionTranslations";
import { AyceCheckoutPage } from "../Arrangements/AyceCheckoutPage";
import { AyceRuleResult, useCheckAyceRules } from "../../utils/arrangements/useCheckAyceRules";
import { ArrangementOverText } from "../../components/Arrangements/ArrangementOverText";
import BackgroundMediaUrlFromSalesarea from "../../../kiosk/hooks/useBackgroundMediaUrl";
import { TransitionGroup } from "react-transition-group";
import { selectPiggyGiftcardAmount } from "../../../global/utils/redux/selectors/selectPiggyGiftcardAmount.tsx";
import { selectVoucherOrderItems } from "../../../global/utils/redux/selectors/selectVoucherOrderItems.tsx";
import DisplayAIUpsellOrderPage from "../../../global/utils/ddai/DisplayAIUpsellOrderPage";
import { BlockUserInput } from "@jamezz/react-components";
import ScrollShadowsQr from "../../components/ScrollShadowQr.tsx";

function OrderPage() {
  const voucherOrderItems = useAppSelector(selectVoucherOrderItems);
  const subTotalValue = useAccountSubTotalValue();
  const dispatch = useAppDispatch();
  const { openDialog } = useDialog();
  const toCurrency = useCurrency({ location: CurrencyLocation.Checkout });
  const items = useAppSelector((state) => state.shoppingCart.items);
  const itemsCount = useAppSelector(selectArticlesSum);
  const autoAddedItems = useAppSelector(selectAutoAddedItems);
  const openOnetimeUpsellDialog = useOpenOneTimeUpsellDialog();
  const { isActive: isActiveNoFood, openUpsell: openUpsellNoFood } = useUpsellNoFoodOrNoDrinksInShoppingCart(
    UpsellType.upsellNoFood
  );
  const { isActive: isActiveNoDrinks, openUpsell: openUpsellNoDrinks } = useUpsellNoFoodOrNoDrinksInShoppingCart(
    UpsellType.upsellNoDrinks
  );
  const { blockingStarted, blockingStopped } = BlockUserInput.useBlockUserInput();
  const payDirect = useAppSelector((state) => state.global.salesarea.payDirect);
  const minOrderValue = useAppSelector((state) => state.global.sessionState.minOrderValue);
  const maxOrderValue = useAppSelector((state) => state.global.salesarea.maxOrderValue);
  const orderLimits = useAppSelector((state) => state.global.salesarea.orderLimits);
  const isOpen = useSalesareaIsOpen();
  const ayce = useAppSelector((state) => state.global.salesarea.use_arrangements);
  const arrangementRuleResult = useCheckAyceRules();
  const totalAmount = useAccountTotalValue() as number;
  const piggyGiftcardAmount = useAppSelector(selectPiggyGiftcardAmount) as number;

  useEffect(() => {
    postAnalyticsEvent({
      category: "JamezzShowOrderButton",
      action: "OrderPage",
    });
    submitEComEvent("view_cart", autoAddedItems, items, voucherOrderItems, {
      value: totalAmount - piggyGiftcardAmount,
    });
  }, []);

  useEffect(() => {
    if (store.getState().global.salesarea.check_calculate_settings.enabled) {
      blockingStarted();
      simphonyCheckCalculate(store.getState(), dispatch).finally(() => {
        blockingStopped();
      });
    }
  }, []);

  useEffect(() => {
    // TODO performance: Refactor 'isActiveNoFood' to getIsActiveNoFood(store.getState) to prevent rerenders
    if (isActiveNoFood) {
      openUpsellNoFood(ArticleSuggestionPage);
    }
  }, []);

  useEffect(() => {
    // TODO performance: Refactor 'isActiveNoDrinks' to getIsActiveNoDrinks(store.getState) to prevent rerenders
    if (isActiveNoDrinks) {
      openUpsellNoDrinks(ArticleSuggestionPage);
    }
  }, []);

  const isWideScreen = useIsWidescreen();
  const scrollingRef = useRef<HTMLElement>(null);
  const allowVouchers = useAppSelector((state) => state.global.salesarea?.allowVouchers);

  const customTexts = useCustomTexts(["checkout button", "go to order button"]);
  return (
    <>
      <BackgroundMediaUrlFromSalesarea
        keyName={"extra_settings__qrv5_background_of_order_page"}
        data-cy="orderPage-shopping-cart-items"
        className="JS-OrderPage-BackgroundImage"
        sx={{
          width: 1,
          height: 1,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 4,
        }}
        fallbackBackgroundColor={"primary.light"}
      >
        {!isWideScreen ? <CloseDialogButton /> : null}

        <Typography
          className="JS-OrderPage-Title"
          data-cy="orderpage-title"
          variant={"h5"}
          sx={{ fontStyle: "italic", fontWeight: 800 }}
        >
          <FormattedMessageJamezz id={"My Order"} />
        </Typography>
        <Box sx={{ overflowY: "auto", flex: "1 1 0%", width: 1 }} ref={scrollingRef}>
          <ScrollShadowsQr containerRef={scrollingRef}>
            <Stack sx={{ width: 1 }} spacing={2}>
              <DiscountsCheckCalculator />
              <DisplayAIUpsellOrderPage />
              <TransitionGroup style={{ width: "100%" }} component={null}>
                {autoAddedItems.map((orderArticle) => (
                  <Collapse key={orderArticle.uuid} sx={{ width: 1 }}>
                    <OptionTranslationsContext.Provider
                      key={orderArticle.uuid}
                      value={orderArticle.article.translations}
                    >
                      <OrderArticleContent key={orderArticle.uuid} orderArticle={orderArticle} />
                    </OptionTranslationsContext.Provider>
                  </Collapse>
                ))}
                {items.map((orderArticle, index) => (
                  <Collapse key={orderArticle.uuid} sx={{ width: 1 }}>
                    <OptionTranslationsContext.Provider
                      key={orderArticle.uuid}
                      value={orderArticle.article.translations}
                    >
                      <OrderArticleContent
                        key={orderArticle.uuid}
                        orderArticle={orderArticle}
                        usePiggyVouchers={true}
                        index={index}
                      />
                    </OptionTranslationsContext.Provider>
                  </Collapse>
                ))}
                {voucherOrderItems.map((orderArticle) => (
                  <Collapse key={orderArticle.uuid} sx={{ width: 1 }}>
                    <OptionTranslationsContext.Provider
                      key={orderArticle.uuid}
                      value={orderArticle.article.translations}
                    >
                      <OrderArticleContent key={orderArticle.uuid} orderArticle={orderArticle} />
                    </OptionTranslationsContext.Provider>
                  </Collapse>
                ))}
              </TransitionGroup>
            </Stack>
          </ScrollShadowsQr>
        </Box>

        {arrangementRuleResult === AyceRuleResult.ROUND_COUNTER_EXCEEDED ||
        arrangementRuleResult === AyceRuleResult.EATING_TIME_OVER ? (
          <ArrangementOverText rule={arrangementRuleResult} />
        ) : (
          <>
            <Divider sx={{ height: 5, width: 1, marginBottom: 1 }} />
            <Box sx={{ maxWidth: 400, width: 1 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography className="JS-OrderPage-Subtotal">
                  <FormattedMessageJamezz id={"Subtotal"} />
                </Typography>

                <Typography className="JS-OrderPage-SubtotalValue">
                  {toCurrency(subTotalValue, { name: "OrderPage-Subtotal" })}
                </Typography>
              </Box>
              {allowVouchers ? <VoucherInput /> : null}
              <AccountDiscount />
              {(subTotalValue >= minOrderValue && subTotalValue <= maxOrderValue && orderLimits) || !orderLimits ? (
                itemsCount > 0 && (
                  <Button
                    data-cy="orderpage-order-button"
                    className="JS-OrderPage-OrderOrCheckoutButton"
                    sx={{ marginTop: 4, marginBottom: 4, width: 1 }}
                    variant={"contained"}
                    disabled={!isOpen}
                    onClick={async () => {
                      const state = store.getState();
                      const upsellSettings = state.global.salesarea.upsellSettings;
                      if (
                        upsellSettings &&
                        upsellSettings.is_active &&
                        upsellSettings.oneTimeUpsell &&
                        upsellSettings.oneTimeUpsell.isActive &&
                        isWideScreen &&
                        upsellSettings.oneTimeUpsell.shownAt === "onOpenShoppingCart"
                      ) {
                        await openOnetimeUpsellDialog(ArticleSuggestionPage);
                      }
                      openDialog({
                        children: ayce ? <AyceCheckoutPage /> : <CheckoutPage />,
                        fullScreen: !isWideScreen,
                        TransitionComponent: SlideUpTransition,
                      });
                    }}
                  >
                    {payDirect
                      ? (customTexts["checkout button"] ?? <FormattedMessageJamezz id={"Go to checkout"} />)
                      : (customTexts["go to order button"] ?? <FormattedMessageJamezz id={"Go to order"} />)}
                  </Button>
                )
              ) : subTotalValue < minOrderValue ? (
                <Box style={{ textAlign: "center" }}>
                  <br />
                  <b className="JS-CheckoutPage-MinMaxOrderValueText">
                    <FormattedMessageJamezz id={"Minordervalue"} values={{ amount: toCurrency(minOrderValue) }} />
                  </b>
                </Box>
              ) : (
                subTotalValue > maxOrderValue && (
                  <Box style={{ textAlign: "center" }}>
                    <br />
                    <b className="JS-CheckoutPage-MinMaxOrderValueText">
                      <FormattedMessageJamezz id={"Maxordervalue"} values={{ amount: toCurrency(maxOrderValue) }} />
                    </b>
                  </Box>
                )
              )}
            </Box>
          </>
        )}
      </BackgroundMediaUrlFromSalesarea>

      <OnetimeUpsell where={"onOpenShoppingCart"} />
    </>
  );
}

export default OrderPage;
