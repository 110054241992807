import { useClaimRewardMutation } from "../../../global/utils/redux/api/piggyApi";
import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  lighten,
  LinearProgress,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { piggyRewardClaimed } from "../../../global/utils/redux/shoppingCartSlice";
import Image from "../../../global/components/Image";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import {
  initOrderArticle,
  OrderArticleOrigin,
  shouldShowOrderArticleSelector,
} from "../../../global/utils/models/order/OrderArticle";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import Article from "../../../global/utils/models/menu/Article";
import OrderArticleSelectorPage from "../../pages/OrderArticleSelectorPage/OrderArticleSelectorPage";
import SlideUpTransition from "../../../global/components/SlideUpTransition";
import { useAgeCheck } from "../../../global/components/AgeCheckDialog";
import { AgeCheckResult } from "../../../global/utils/ageCheck/ageCheckUtils";
import useIsWidescreen from "../../../global/utils/useIsWidescreen";
import { selectArticlesMap } from "../../../global/utils/redux/selectors/selectArticlesMap";

import { piggyErrorCodes, PiggyReward } from "../../../types/shared/piggy";

import { assertIsPiggyErrorMessage } from "../../../global/utils/piggy/usePiggyRewards";
import { postAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics.ts";
import isKiosk from "../../../global/utils/helpers/isKiosk.tsx";
import { BlockUserInput } from "@jamezz/react-components";

export interface ConfirmationDialogRawProps {
  open: boolean;
  onClose: (claimed: boolean) => void;
}

function ClaimPiggyRewardDialog({
  item,
  props: { onClose, open },
  piggyPoints,
}: {
  item: PiggyReward;
  piggyPoints: number | undefined;
  props: ConfirmationDialogRawProps;
}) {
  const checkAge = useAgeCheck();
  const articlesMap = useAppSelector(selectArticlesMap);
  const article = findJamezzArticleForPiggyItem(item, articlesMap);
  const hasEnoughPoints = piggyPoints != null && item.required_credits <= piggyPoints;

  return (
    <Dialog open={open}>
      <DialogTitle>
        <FormattedMessageJamezz id="Piggy.rewards.confirmationDialog.title" />
        <Typography
          variant="body2"
          sx={{
            color: lighten("#000", 0.6),
            fontSize: "14px",
          }}
        >
          <FormattedMessageJamezz id="Piggy.rewards.confirmationDialog.warning.noRefunds" />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{item.title}</Typography>
        {hasEnoughPoints ? (
          <>
            <Typography
              variant="body1"
              fontStyle="italic"
              sx={{
                color: lighten("#000", 0.6),
              }}
            >
              {item.description?.length ? item.description : <>&nbsp;</>}
            </Typography>
            <Typography variant="body1">
              <FormattedMessageJamezz
                id="Piggy.rewards.confirmationDialog.cost"
                values={{
                  cost: item.required_credits,
                }}
              />
            </Typography>
          </>
        ) : (
          <>
            <Typography sx={{ mt: 2, mb: 0.5 }} data-cy="piggy-dialog-claimReward-notEnoughPoints" color="error">
              <FormattedMessageJamezz id="Piggy.rewards.confirmationDialog.error.notEnoughPoints" />
            </Typography>
            <Typography>
              <FormattedMessageJamezz
                id="Piggy.rewards.confirmationDialog.error.pointsNeeded"
                values={{
                  balance: piggyPoints,
                  pointsRequired: item.required_credits,
                }}
              />
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", width: "100%", gridGap: "8px" }}>
        {hasEnoughPoints ? (
          <>
            <Button variant="outlined" onClick={() => onClose(false)}>
              <FormattedMessageJamezz id="Cancel" />
            </Button>
            <Button
              data-cy="piggy-btn-claim-reward"
              variant="contained"
              onClick={async () => {
                postAnalyticsEvent({
                  category: "ClaimPiggyRewardDialog",
                  action: "claimReward",
                });
                const order = initOrderArticle(articlesMap, article, 1);
                const canOrder = await checkAge(order);
                if (canOrder === AgeCheckResult.Accepted) {
                  onClose(true);
                } else if (canOrder === AgeCheckResult.Denied) {
                  onClose(false);
                }
              }}
            >
              <FormattedMessageJamezz id="Piggy.rewards.confirmationDialog.claimReward" />
            </Button>
          </>
        ) : (
          <Button variant="outlined" onClick={() => onClose(false)}>
            <FormattedMessageJamezz id="Ok" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

function findJamezzArticleForPiggyItem(item: PiggyReward, articlesMap: Record<string, Article>): Article {
  const found = articlesMap[String(item.artikelid)]; //  Object.entries(articlesMap).find(([, article]) => String(item.artikelid) === article.id);
  if (found) {
    return found;
  }
  throw new Error("Not found, no unlinked items should ever show up in the data. They're filtered in the query");
}

export function PiggyRewardItem({
  item,
  piggyPoints,
  noClaim,
}: {
  item: PiggyReward;
  piggyPoints: number | undefined;
  noClaim?: true;
}) {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const balance: number = piggyPoints ?? 0;
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const { openDialog } = useDialog();
  const isWidescreen = useIsWidescreen();
  const articlesMap = useAppSelector(selectArticlesMap);

  const [
    claimReward,
    { data: rewardClaimedResponse, isSuccess: rewardClaimedSuccessfully, reset, isLoading, isError, error },
  ] = useClaimRewardMutation();

  BlockUserInput.useBlockUserInput(isLoading);
  useEffect(() => {
    if (!item) return;
    if (isError) {
      try {
        assertIsPiggyErrorMessage(error);
      } catch (e) {
        toast.error(intl.formatMessage({ id: "Piggy.messages.error.unknownApiResponse" }));
        reset();
        return;
      }
      switch (error.data.context.code) {
        case piggyErrorCodes["claim limit reached"]:
          toast.error(intl.formatMessage({ id: "Piggy.messages.error.claimLimitReached" }, { item: item.title }));
          break;
        case piggyErrorCodes["out of stock"]:
          toast.error(intl.formatMessage({ id: "Piggy.messages.error.outOfStock" }, { item: item.title }));
          break;
        default:
          toast.error(intl.formatMessage({ id: "Piggy.messages.error.unknownApiResponse" }));
      }
      reset();
    }
  }, [intl, isError, error, item, reset]);

  useEffect(() => {
    if (rewardClaimedSuccessfully && rewardClaimedResponse) {
      const article = findJamezzArticleForPiggyItem(item, articlesMap);
      const order = initOrderArticle(articlesMap, article, 1);
      order.added_origin = OrderArticleOrigin.PIGGY;

      if (shouldShowOrderArticleSelector(order)) {
        openDialog({
          children: <OrderArticleSelectorPage article={article} isPiggyReward={true} />,
          fullScreen: isKiosk() ? "almostFullScreen" : !isWidescreen,
          TransitionComponent: SlideUpTransition,
        });
      } else {
        const article = findJamezzArticleForPiggyItem(item, articlesMap);
        if (!article) {
          toast.success(
            intl.formatMessage(
              {
                id: "Unknown error",
              },
              {
                name: item.title,
              }
            )
          );
          return;
        }
        toast.success(
          intl.formatMessage(
            {
              id: "Piggy.messages.rewardAdded",
            },
            {
              name: item.title,
            }
          )
        );

        const orderArticle = initOrderArticle(articlesMap, article, 1);
        orderArticle.added_origin = OrderArticleOrigin.PIGGY;
        dispatch(piggyRewardClaimed({ item, orderArticle }));
      }

      reset();
    }
  }, [
    dispatch,
    intl,
    item,
    articlesMap,
    isWidescreen,
    openDialog,
    reset,
    rewardClaimedResponse,
    rewardClaimedSuccessfully,
  ]);

  const balanceOverPointsRequiredPercentage = Math.min(100, (balance / item.required_credits) * 100);
  return (
    <Paper
      data-cy="piggy-reward-item"
      sx={{
        width: 1,
      }}
    >
      <ClaimPiggyRewardDialog
        item={item}
        piggyPoints={balance}
        props={{
          open: confirmationDialogOpen,
          onClose: (claimed) => {
            postAnalyticsEvent({
              category: "PiggyRewardItem",
              action: "claimReward",
            });
            setConfirmationDialogOpen(false);
            if (claimed) {
              claimReward(item.uuid);
            }
          },
        }}
      />
      <Box sx={{ width: 1 }}>
        <ButtonBase
          disableRipple={noClaim}
          onClick={() => {
            if (!noClaim) {
              postAnalyticsEvent({
                category: "PiggyRewardItem",
                action: "setConfirmationDialogOpen",
              });
              setConfirmationDialogOpen(true);
            }
          }}
          sx={[
            {
              width: 1,
              borderRadius: 3,
              display: "grid",
              gridTemplateColumns: "33% 1fr",
              p: 1,
            },
            noClaim
              ? {
                  cursor: "inherit",
                }
              : false,
          ]}
        >
          <Box sx={{ maxHeight: 100, pr: 1, display: "flex" }}>
            {item.media ? (
              <Image
                src={item.media?.value}
                style={{ maxHeight: 84, minHeight: 84, maxWidth: "100%", objectFit: "cover" }}
              />
            ) : (
              <Box />
            )}
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr",
              textAlign: "left",
              boxSizing: "content-box",
              height: "100%",
            }}
          >
            <Typography sx={{ fontWeight: 800 }}>{item.title}</Typography>
            <Typography
              sx={{
                color: lighten("#000", 0.6),
                paddingRight: 1,
                width: 1,
                fontSize: "14px",
                fontStyle: "italic",
                textOverflow: "ellipsis",
                wordBreak: "break-all",
                lineClamp: "2",
                WebkitLineClamp: "2",
                overflow: "hidden",
              }}
              className={"JS-ArticleContent-Description"}
              dangerouslySetInnerHTML={
                item.description?.length
                  ? {
                      __html: item.description,
                    }
                  : undefined
              }
            >
              {item.description?.length ? undefined : <>&nbsp;</>}
            </Typography>
            <Typography sx={{ fontWeight: 800 }}>
              {balance == null ? <Skeleton variant="rectangular" sx={{ display: "inline" }} /> : balance} /{" "}
              {item.required_credits} points
            </Typography>
          </Box>
        </ButtonBase>
        <LinearProgress
          sx={{ w: 1, height: "8px", borderBottomLeftRadius: "3px", borderBottomRightRadius: "3px" }}
          color={balanceOverPointsRequiredPercentage === 100 ? "success" : "primary"}
          variant="determinate"
          value={balance == null ? 0 : balanceOverPointsRequiredPercentage}
        />
      </Box>
    </Paper>
  );
}
