import checkOutstandingBalance from "../api/scanAndPay/checkOutstandingBalance";
import { useEffect, useRef } from "react";
import store, { useAppSelector } from "../redux/store";
import { setTableIsOpenInPos } from "../redux/globalSlice";
import { useDialog } from "../dialog/DialogProvider";
import { TableIsNotOpenMessageDialog } from "../../components/CheckTableOpen/TableIsNotOpenMessage";
import { TableIsOpenMessageDialog } from "../../components/CheckTableOpen/TableIsOpenMessage";
import _ from "lodash";
import { BlockUserInput } from "@jamezz/react-components";

export default function useCheckTableIsOpenInPos() {
  const requestActive = useRef<boolean>(false);
  const enabled = useAppSelector((state) => state.global.salesarea.checkTableIsOpen.enabled);
  const tableIsOpenInPos = useAppSelector((state) => state.global.tableIsOpenInPos);
  const tableWasDisabled = useRef<boolean>(false);
  const { blockingStarted, blockingStopped } = BlockUserInput.useBlockUserInput();
  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | null = null;

    function doRequest() {
      if (!requestActive.current) {
        requestActive.current = true;
        if (tableIsOpenInPos === undefined) {
          blockingStarted();
        }
        checkOutstandingBalance()
          .then((response) => {
            if (response?.items && response?.items.length > 0) {
              store.dispatch(setTableIsOpenInPos(true));
            } else {
              store.dispatch(setTableIsOpenInPos(false));
            }
          })
          .catch(() => {
            store.dispatch(setTableIsOpenInPos(false));
          })
          .finally(() => {
            requestActive.current = false;
            blockingStopped();
          });
      }
    }

    if (enabled && !tableIsOpenInPos) {
      doRequest();
      interval = setInterval(() => {
        doRequest();
      }, 30000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [enabled, tableIsOpenInPos, blockingStarted, blockingStopped]);

  const { openDialog } = useDialog();
  useEffect(() => {
    if (tableIsOpenInPos === false) {
      tableWasDisabled.current = true;
    }
  }, [tableIsOpenInPos]);
  useEffect(() => {
    if (enabled && tableWasDisabled.current) {
      if (tableIsOpenInPos === false) {
        _.defer(() => {
          openDialog({ children: <TableIsNotOpenMessageDialog /> });
        });
      } else if (tableIsOpenInPos === true) {
        _.defer(() => {
          openDialog({ children: <TableIsOpenMessageDialog /> });
        });
      }
    }
  }, [enabled, openDialog, tableIsOpenInPos]);
}
