import { useMemo, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { useAppSelector } from "../../../global/utils/redux/store";
import { useFetchTableStateQuery, useRefreshTableStateMutation } from "../../../global/utils/redux/api/arrangementsApi";
import OrderArticle, { initOrderArticle } from "../../../global/utils/models/order/OrderArticle";
import { selectArticlesMap } from "../../../global/utils/redux/selectors/selectArticlesMap";
import { differenceInSeconds, format } from "date-fns";
import { IntlShape, useIntl } from "react-intl";
import { LoadingButton } from "@mui/lab";
import _ from "lodash";
import { OrderRound } from "./rounds/OrderRound.tsx";
import { AyceRoundNavigation } from "./rounds/AyceRoundNavigation.tsx";
import CloseDialogButton from "../Dialog/CloseDialogButton.tsx";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { TicketOrder } from "../../../types/qr/arrangements.ts";
import { OrderStatusTick } from "./rounds/OrderStatusTick.tsx";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";

function formatTimeDifferenceForHumans(seconds: number, intl: IntlShape) {
  if (seconds < 60) {
    return intl.formatMessage({ id: "AYCE.order-rounds-overview.last_sync_at.less_than_one_minute" });
  }
  return intl.formatMessage(
    { id: "AYCE.order-rounds-overview.last_sync_at.minutes" },
    { minutes: Math.floor(seconds / 60) }
  );
}

const mainHeaderStyles = { fontWeight: 800, mb: 4, mx: 2, textAlign: "center", fontSize: "2.5em" };
const noOrdersYetStyle = {
  position: "absolute",
  left: "50vw",
  bottom: "50vh",
  transform: "translate(-50%, 50%)",
  textAlign: "center",
  color: "gray",
};
const lastLoadedAtBoxStyles = { marginBottom: 2 };
const mainContainerStyles = {
  width: 1,
  minHeight: 1,
  padding: 4,
  display: "flex",
  flexDirection: "column",
};

export function AYCERoundsDialog() {
  const pincode = useAppSelector((state) => state.arrangements.ticket_pincode.pincode);
  const { data, isFetching } = useFetchTableStateQuery(pincode);
  const [refreshTableState, refreshTableStateResult] = useRefreshTableStateMutation();
  const intl = useIntl();
  const ticket = data?.data?.ticket;
  const articlesMap = useAppSelector(selectArticlesMap);

  const ticket_orders: (TicketOrder & { orderItems: OrderArticle[]; header: JSX.Element })[] = useMemo(() => {
    let UIroundCounter = 0;

    return (ticket?.ticket_orders ?? []).map((ticket_order) => {
      if ("count_as_round" in ticket_order && ticket_order.count_as_round) {
        UIroundCounter += 1;
      }
      return {
        ..._.cloneDeep(ticket_order),
        header: (
          <>
            <Typography variant="h2" sx={{ fontSize: "1.5em" }} component="span">
              {ticket_order.by_app_user ? (
                ticket_order.count_as_round ? (
                  <FormattedMessageJamezz
                    id="AYCE.order-history.round-title.round-n"
                    values={{
                      roundNum: UIroundCounter,
                    }}
                  />
                ) : (
                  <FormattedMessageJamezz id="AYCE.order-history.round-title.drinks-and-extras" />
                )
              ) : (
                <FormattedMessageJamezz id="AYCE.order-history.round-title.POSOrder" />
              )}
            </Typography>
            <Typography
              variant="body2"
              sx={{ display: "inline-flex", alignItems: "end", color: "gray" }}
              fontWeight="bold "
            >
              &nbsp;
              {format(new Date(ticket_order.created_at), "HH:mm")}
              &nbsp;
              <OrderStatusTick orderStatus={ticket_order.orderStatus} />
            </Typography>
          </>
        ),

        orderItems: ticket_order.items
          .filter((item) => item.product)
          .map((item) => {
            return initOrderArticle(
              articlesMap,
              // @ts-ignore
              {
                name: item.product.name,
                price: item.product.price,
                id: item.product.id.toString(),
              },
              item.count
            );
          }),
      };
    });
  }, [ticket, articlesMap]);
  const scrollContainer = useRef<HTMLDivElement>();

  const ticketLastSyncedAt = formatTimeDifferenceForHumans(
    differenceInSeconds(new Date(), new Date(ticket?.table_state_retrieved_at ?? "")),
    intl
  );

  const navigation_items = useMemo(() => {
    return [...ticket_orders.map((order) => ({ count_as_round: order.count_as_round, id: order.id }))];
  }, [ticket_orders]);

  const scrollContainerStyle = useMemo(
    () => ({
      width: `calc(100% - 24px)`,
      marginBottom: `calc(${window.innerHeight}px - 100px)`,
    }),
    []
  );

  return (
    <Box sx={mainContainerStyles}>
      <CloseDialogButton />
      <Typography variant="h1" sx={mainHeaderStyles}>
        <FormattedMessageJamezz id="AYCE.order-history.previous-rounds" />
      </Typography>
      <Box sx={lastLoadedAtBoxStyles}>
        {ticket ? (
          <FormattedMessageJamezz
            id="AYCE.order-history.last-loaded-at"
            values={{
              time: ticketLastSyncedAt,
            }}
          />
        ) : null}
        &nbsp;
        <LoadingButton
          variant="contained"
          size="small"
          onClick={() => {
            refreshTableState();
          }}
          loading={isFetching || refreshTableStateResult.status == QueryStatus.pending}
        >
          <FormattedMessageJamezz id="Reload" />
        </LoadingButton>
      </Box>
      <Box>
        <Box>
          {navigation_items.length === 0 ? (
            <Typography sx={noOrdersYetStyle} data-cy="no-orders-yet">
              <FormattedMessageJamezz id="AYCE.order-history.no-orders-yet" />
              <br />
              <br />
              <FormattedMessageJamezz id="AYCE.order-history.empty" />
            </Typography>
          ) : null}
          <Box ref={scrollContainer} sx={scrollContainerStyle}>
            {ticket_orders.map((order) => {
              return (
                <Box key={order.id}>
                  <OrderRound
                    id={order.id}
                    order_items={order.orderItems ?? []}
                    count_as_round={order.count_as_round}
                    header={order.header}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
        <AyceRoundNavigation navigation_items={navigation_items} scrollContainer={scrollContainer} />
      </Box>
    </Box>
  );
}
