import { forwardRef, useCallback, useMemo, useState } from "react";
import { Avatar, Button, ButtonBase, Dialog, Tab, Tabs, Typography } from "@mui/material";
import ArticleTrait from "../../../../../global/utils/models/ArticleTraits/ArticleTrait";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
// @ts-ignore
import scrollIntoView from "scroll-into-view";
import { useAppDispatch, useAppSelector } from "../../../../../global/utils/redux/store";
import ArticleTraitView from "../../../../components/ArticleTraitView";
import FormattedMessageJamezz from "../../../../../global/components/FormattedMessageJamezz";
import useArticleTraitValues from "../../../../../global/utils/articleTraits/useArticleTraitValues";
import { setIsFilterPageOpen } from "../../../../../global/utils/redux/traitFilterSlice";
import { selectArticleTraitsMap } from "../../../../../global/utils/redux/selectors/selectArticleTraitsMap";
import { Theme } from "@mui/material/styles";

export const TransitionComponent = forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction={"right"} ref={ref} {...props} />;
});

export const tabsStyles = () => ({
  // width: "100%",

  "& .MuiTabs-indicator": {
    display: "none",
  },

  alignItems: "center",
  justifyContent: "center",
});

const tabItemStyles = (theme: Theme) => ({
  borderRadius: "30px",

  transition: "all .5s",
  padding: "10px 15px",

  width: 300,
  border: "1px solid",
  borderColor: theme.palette.text.primary,
  margin: "10px 30px",

  "&.Mui-selected": {
    "&, &:hover": {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.text.primary,
      boxShadow: "0 7px 10px -5px rgba(76, 175, 80, 0.4)",
    },
  },
});

export default function QuickTraitFilterDialog() {
  const { quickFilterOnArticleTrait, allArticleTraits } = useAppSelector(selectArticleTraitsMap);
  const stateOfArticleTraits = useAppSelector((state) => state.traitFilter.stateOfArticleTraits);
  const open = useAppSelector((state) => state.traitFilter.isFilterPageOpen);
  const [tabValue, setTabValue] = useState<string | false>(quickFilterOnArticleTrait?.apiId ?? false);
  const dispatch = useAppDispatch();
  const setDialogIsOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(setIsFilterPageOpen(isOpen));
    },
    [dispatch]
  );

  const handleClose = useCallback(
    (e: any) => {
      e.stopPropagation();
      setDialogIsOpen(false);
    },
    [setDialogIsOpen]
  );

  const transition: any = TransitionComponent;

  // const letters = [
  //   "A",
  //   "B",
  //   "C",
  //   "D",
  //   "E",
  //   "F",
  //   "G",
  //   "H",
  //   "I",
  //   "J",
  //   "K",
  //   "L",
  //   "M",
  //   "N",
  //   "O",
  //   "P",
  //   "Q",
  //   "R",
  //   "S",
  //   "T",
  //   "U",
  //   "V",
  //   "W",
  //   "X",
  //   "Y",
  //   "Z",
  // ];
  // const letters = useMemo(() => {
  //   let letters: string[] = [];
  //
  //   articleTraitValues.forEach((value) => {
  //     if (value.length > 0) {
  //       if (!letters.includes(value.charAt(0))) {
  //         letters.push(value.charAt(0));
  //       }
  //     }
  //   });
  //   letters = letters.sort();
  //   return letters;
  // }, [articleTraitValues]);

  const hasAnyValueSelected = useMemo(() => {
    return Object.keys(stateOfArticleTraits).some((arrKey) => {
      return stateOfArticleTraits[arrKey].length > 0;
    });
  }, [stateOfArticleTraits]);

  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={transition} fullScreen={true} sx={{ width: 0.8 }}>
      <Box sx={{ display: "inline-flex", justifyContent: "flex-end", paddingX: 2 }}>
        <Button
          size={"large"}
          variant={hasAnyValueSelected ? "contained" : "outlined"}
          sx={{ fontWeight: 800, fontSize: 48, marginY: 2 }}
          onClick={() => {
            setDialogIsOpen(false);
          }}
        >
          <FormattedMessageJamezz id={"Confirm"} />
        </Button>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <Tabs
          sx={tabsStyles}
          value={tabValue}
          onChange={(e, index) => {
            // console.log(index);
            setTabValue(index);
          }}
        >
          {allArticleTraits.map((articleTrait) => (
            <Tab
              key={articleTrait.apiId}
              sx={tabItemStyles}
              label={articleTrait.name}
              icon={
                articleTrait.mediaUrls?.[2] ? (
                  <Avatar srcSet={articleTrait.mediaUrls?.[2]?.conversions?.responsive?.srcset} />
                ) : undefined
              }
              iconPosition={"start"}
              value={articleTrait.apiId}
            />
          ))}
        </Tabs>
      </Box>
      {allArticleTraits.map((articleTrait) => (
        <TabPanel value={tabValue ? tabValue : ""} index={articleTrait.apiId} key={articleTrait.apiId}>
          <ArticleTraitCollection articleTrait={articleTrait} key={articleTrait.apiId} />
        </TabPanel>
      ))}
    </Dialog>
  );
}

interface PropsCollection {
  articleTrait: ArticleTrait;
}

function ArticleTraitCollection(props: PropsCollection) {
  const { articleTraitValues } = useArticleTraitValues(props.articleTrait);

  const letters = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  // console.log(articleTraitValues);
  return (
    <>
      <Box
        sx={{
          display: "inline-flex",
          flexWrap: "wrap",
          paddingTop: 5,
          backgroundColor: "background.default",
          zIndex: 10,
          paddingLeft: 2,
        }}
      >
        {articleTraitValues.length > 20 &&
          letters.map((letter) => {
            return (
              <ButtonBase
                key={letter}
                sx={{ paddingX: 2, marginBottom: 2 }}
                onClick={() => {
                  const elements = document.getElementsByClassName(
                    `${props.articleTrait.apiId}-QuickTraitValue-Letter-0-${letter}`
                  );
                  if (elements.length >= 1) {
                    const element = elements[0];
                    scrollIntoView(element, {
                      time: 1000,
                      align: {
                        top: 0,
                        topOffset: 0,
                      },
                      validTarget: () => true,
                    });
                  }
                }}
              >
                <Typography sx={{ textTransform: "uppercase", fontWeight: 800 }}>{letter}</Typography>
              </ButtonBase>
            );
          })}
      </Box>

      <ArticleTraitView articleTrait={props.articleTrait} nestedLevel={0} />
    </>
  );
}

interface TabPanelProps {
  children: React.ReactElement;
  index: string;
  value: string;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      sx={{ height: 0, flexGrow: 1 }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, height: 1, display: "flex", flexDirection: "column" }}>{children}</Box>}
    </Box>
  );
}
