import React from "react";
import { CSSProperties } from "@mui/material/styles/createMixins";
import { BoxProps } from "@mui/material";

export default function SulphiteIcon({
  innerColor = "#fefefe",
  outerColor = "#8d2f51",
  className,
  propsStyle,
}: {
  innerColor?: CSSProperties["color"];
  outerColor?: CSSProperties["color"];
  className?: string;
  propsStyle?: BoxProps["style"];
}) {
  const style = {
    fill: innerColor,
  };

  return (
    <svg viewBox={`0 0 216 216`} className={className} style={{ color: outerColor, ...propsStyle }}>
      <path
        d="M107.74 215.86C48.16 215.86 0 167.34 0 107.75 0 47.81 48.16 0 107.74 0c59.93 0 108.08 47.81 108.08 107.75.01 59.59-48.15 108.11-108.08 108.11z"
        fill={"currentColor"}
      />
      <path
        style={{
          fill: innerColor,
        }}
        d="M91.69 65.65H77.41v8.92h15.7v7.13H68.86V43.17H92.4v7.14H77.41v8.2h14.28zM112.01 69.57H97.03v-5.69h14.98zM136.99 81.71l-3.56-6.78c-1.43-2.85-2.5-4.64-3.58-7.13-.71 2.49-1.77 4.28-2.85 7.13l-3.22 6.78h-9.98l11.06-19.27-10.7-19.26h9.99l3.2 7.13c1.44 2.5 2.15 4.28 3.22 6.42.72-2.5 1.79-4.27 2.5-6.42l3.21-7.13h10l-10.7 18.91 11.41 19.62h-10zM171.59 152.71c-.72-1.78-2.5-2.85-4.28-2.85-1.78 0-3.21.71-3.92 2.14-.36.36-.36.36-.72.71v1.79c0 2.5 2.14 4.63 4.64 4.63 1.78 0 3.56-1.07 4.28-2.86.36-.7.36-1.06.36-1.77 0-.73 0-1.43-.36-1.79zm-71.34-2.5v-23.55h-4.99v21.05l-18.19 10.35 2.5 4.28 20.68-12.13zm17.12-25.69l2.14 4.28 19.26-11.41-2.14-3.93-19.26 11.06zm-68.84 1.07c2.85 0 4.63-2.14 4.63-4.64 0-2.86-1.78-4.99-4.63-4.99h-.37c-1.42.35-2.49 1.07-3.57 2.14v.36c-.35.71-.71 1.42-.71 2.5 0 .71.36 1.43.71 2.14.72 1.42 2.15 2.49 3.94 2.49zm8.19-4.99l21.76-12.13 25.33 14.63v25.32c1.42-.71 2.85-1.06 4.27-1.06 1.43 0 2.85.35 4.28 1.06V123.1l25.33-14.63 25.69 14.63v24.26c1.07-.72 2.5-1.08 3.92-1.08 1.43 0 2.84.36 4.28 1.08V118.1l-31.38-17.84v-7.13h-4.99v7.13l-27.13 15.7L80.62 99.9v-6.78h-4.64v7.13l-23.54 13.2c2.86 1.44 4.28 4.3 4.28 7.15zm102.75 29.61v-23.55h-4.64v21.05l-18.2 10.35 2.14 4.28 20.7-12.13zM57.8 124.52l2.5 4.28 19.26-11.41-2.5-3.93-19.26 11.06zm50.29 26.4c-2.85 0-4.63 2.14-4.63 4.64 0 2.5 1.78 4.29 3.92 4.64h1.43c2.14-.35 3.92-2.5 3.92-4.64 0-2.49-2.14-4.64-4.64-4.64zm51.02 4.29l-21.41 12.13-21.4-12.49v.71c0 2.85-1.42 5.35-3.93 6.78l22.85 13.2v6.78h4.99v-6.78l23.54-13.56c-2.5-1.42-4.64-3.92-4.64-6.77zm-106.31-2.5v-.35c-.72-1.79-2.49-2.85-4.27-2.85-1.79 0-3.22.71-3.94 2.14-.71.71-.71 1.42-.71 2.49 0 1.07 0 1.78.71 2.5.72 1.43 2.15 2.14 3.94 2.14 2.49 0 4.63-2.14 4.63-4.64 0-.37 0-1.07-.36-1.43zm-8.21-24.62V147c1.08-.72 2.49-1.07 3.94-1.07 1.42 0 3.2.36 4.27 1.43v-19.62c-1.07.7-2.49 1.42-4.27 1.42-1.44 0-2.86-.36-3.94-1.07zm55.31 27.48v-.71l-21.41 12.49-21.76-12.49c-.36 2.85-2.14 5.71-5 6.78l24.26 13.91v6.78h4.64v-6.78l22.84-13.2c-2.15-1.43-3.57-3.94-3.57-6.78z"
      />
    </svg>
  );
}
