import Article from "../../../../utils/models/menu/Article";
import store from "../../../../utils/redux/store.tsx";
import {
  ActiveArrangement,
  findArrangementGroupForArticle,
  findArrangementGroupsForArticle,
} from "../../../../utils/redux/arrangements/arrangementsSlice.tsx";
import { selectArticleArticlegroupsMap } from "../../../../utils/redux/selectors/selectArticleArticlegroupsMap.ts";
import { ArrangementLimitationCheck, CheckResult } from "../../ArrangementLimitations.tsx";
import { DialogOption } from "../../../../utils/dialog/DialogProvider.tsx";
import ConfirmMaxPerTicketPopup from "./ConfirmMaxPerTicketPopup.tsx";
import OrderArticle from "../../../../utils/models/order/OrderArticle.ts";
import { IntlShape } from "react-intl";
import { selectActiveArrangement } from "../../../../utils/redux/arrangements/selectActiveArrangement.tsx";
import { selectTableState } from "../../../../utils/redux/api/selectTableState.ts";

function maxItemCanBeOrderedPerTicket(article: Article) {
  const state = store.getState();
  const activeArrangement = selectActiveArrangement(state);

  if (activeArrangement == null) return 0;

  const groups = findArrangementGroupsForArticle(article, activeArrangement.groups);

  /* in the case where the item is part of more than one active group,
   * find the "strictest" rule.
   * For instance, if you have two groups. One without a limit and one with limit of 1, the limit should be 1:
   *     [infinite, 1] => 1
   */
  return groups.reduce(function (lowest, group) {
    const groupMaximum = group.maximum_per_ticket === 0 ? Infinity : group.maximum_per_ticket;
    if (groupMaximum < lowest) {
      return groupMaximum;
    }
    return lowest;
  }, Infinity);
}

function countArrangementGroupItemsInShoppingCart(article: Article) {
  const state = store.getState();
  const activeArrangement = selectActiveArrangement(state);
  const articleArticlegroupsMap = selectArticleArticlegroupsMap(state);
  const shoppingCartItems = state.shoppingCart.items;

  if (!activeArrangement) return 0;

  const g1 = findArrangementGroupForArticle(article, articleArticlegroupsMap, activeArrangement.groups);
  return shoppingCartItems.reduce(function (count, item) {
    const g2 = findArrangementGroupForArticle(item.article, articleArticlegroupsMap, activeArrangement.groups);
    if (g1 === g2) {
      return count + item.count;
    }
    return count;
  }, 0);
}

function countArrangementGroupItemsInTicket(article: Article) {
  const state = store.getState();
  const activeArrangement = selectActiveArrangement(state);
  const articleArticlegroupsMap = selectArticleArticlegroupsMap(state);
  const rounds = selectTableState(state)?.data?.data?.ticket?.ticket_orders ?? [];

  if (!activeArrangement) return 0;

  const g1 = findArrangementGroupForArticle(article, articleArticlegroupsMap, activeArrangement.groups);

  return rounds
    .filter((round) => round.count_as_round)
    .reduce(function (total, round) {
      const itemTotalInRound = round.items.reduce(function (roundTotal, roundItem) {
        const g2 = findArrangementGroupForArticle(
          { id: String(roundItem.product.id) },
          articleArticlegroupsMap,
          activeArrangement.groups
        );
        if (g1 === g2) {
          return roundTotal + roundItem.count;
        }
        return roundTotal;
      }, 0);

      return total + itemTotalInRound;
    }, 0);
}

// next
export const checkMaxPerTicket: ArrangementLimitationCheck = async (
  { article, count }: OrderArticle,
  arrangement: ActiveArrangement,
  openDialog: (option: DialogOption) => string,
  closeDialog: () => void,
  intl: IntlShape
) =>
  new Promise((resolve) => {
    const ticketMaximum = maxItemCanBeOrderedPerTicket(article);
    const limitedItemsPreviouslyOrdered = countArrangementGroupItemsInTicket(article);
    const limitedItemsInShoppingCartRightNow = countArrangementGroupItemsInShoppingCart(article);
    const totalItemCount = limitedItemsPreviouslyOrdered + limitedItemsInShoppingCartRightNow + count;

    if (ticketMaximum === Infinity) {
      resolve({ checkResult: CheckResult.VALID });
      return;
    }

    const valid = totalItemCount <= ticketMaximum * arrangement.total_covers;

    if (valid) {
      openDialog({
        children: (
          <ConfirmMaxPerTicketPopup
            article={article}
            onCancel={() => {
              resolve({ checkResult: CheckResult.SKIPPED });
            }}
            onConfirm={() => {
              resolve({ checkResult: CheckResult.VALID });
              // note, resolve(...) calls one instance of `closeDialog()` itself also, so this closes this confirmation popup
              // -and- the article popup
              closeDialog();
            }}
            maxPerTicket={ticketMaximum}
            totalItemCount={totalItemCount}
          />
        ),
      });
      return;
    }

    resolve({
      checkResult: CheckResult.INVALID,
      messageText: intl.formatMessage(
        { id: "AYCE.limitation-checks.arrangement-groups.max-per-ticket" },
        {
          name: article.name,
          maximum: ticketMaximum,
          current: totalItemCount - count,
        }
      ),
      // messageText: ``,
    });
  });
