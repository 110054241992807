import React, { useRef } from "react";
import { Stack, SwipeableDrawer } from "@mui/material";
import useServiceArticleListItem from "./useServiceArticleListItem.tsx";
import useIsWidescreen from "../../../global/utils/useIsWidescreen.ts";
import Box from "@mui/material/Box";
import ScrollShadowsQr from "../ScrollShadowQr.tsx";

type ServiceBottomDrawerProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ServiceBottomDrawer: React.FC<ServiceBottomDrawerProps> = ({ open, setOpen }) => {
  const isWidescreen = useIsWidescreen();
  const serviceArticleListItem = useServiceArticleListItem();

  let styles = { borderTopLeftRadius: "16px", borderTopRightRadius: "16px", maxHeight: "50%" };
  const widescreenStyles = {
    transform: "translateX(calc(50vw - 50% - 225px)) !important",
    padding: "15px",
    maxWidth: "500px",
    opacity: 0.9,
  };

  if (isWidescreen) {
    styles = { ...styles, ...widescreenStyles };
  }
  const scrollingRef = useRef<HTMLDivElement>(null);

  return (
    <SwipeableDrawer
      PaperProps={{
        sx: styles,
      }}
      classes={{
        paper: "JS-ServiceBottomDrawer-Root",
      }}
      anchor={"bottom"}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => {}}
      disableSwipeToOpen={true}
    >
      <Stack sx={{ width: 1, overflowY: "auto" }} gap={2} ref={scrollingRef}>
        <ScrollShadowsQr containerRef={scrollingRef}>{serviceArticleListItem}</ScrollShadowsQr>
      </Stack>
    </SwipeableDrawer>
  );
};

export default ServiceBottomDrawer;
