import { useCallback } from "react";
import { httpGet, httpPost } from "../../../global/utils/api/http";
import store, { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import { AfterPayArticle } from "../AfterPayPage/AfterPayPage";
import { toast } from "react-toastify";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { orderCreated } from "../../../global/utils/redux/globalSlice";
import { addTipItemIfNeeded, addTransactionFeeItemIfNeeded } from "../../../global/utils/order/addVariousItemsToOrder";
import OrderArticle from "../../../global/utils/models/order/OrderArticle";
import { BlockUserInput } from "@jamezz/react-components";

interface Props {
  amount: number;
  totalAmount: number;
  items: AfterPayArticle[];
}

function useSendAfterPay(props: Props) {
  const jamezzPaymentMethod = useAppSelector((state) => state.shoppingCart.jamezzPaymentMethod);
  const dispatch = useAppDispatch();
  const { blockingStarted, blockingStopped } = BlockUserInput.useBlockUserInput();

  const requestPayment = useCallback(
    (searchParams: string) => {
      httpGet("/v5_2/sessions/requestpayment?" + searchParams, null, { showToastSuccess: false, showSpinner: false })
        .then((requestPaymentResponse: any) => {
          const paymentUrl = requestPaymentResponse?.data?.data?.paymentData?.transaction?.paymentURL;

          if (requestPaymentResponse?.data?.data?.paymentError) {
            blockingStopped();
            toast.error(<FormattedMessageJamezz id={"Payment error!"} />);
          } else if (paymentUrl) {
            // toast.success(<FormattedMessageJamezz id={"Success"} />);
            window.location = paymentUrl;
          }
        })
        .catch((err) => {
          console.log(err);
          blockingStopped();
          requestPayment(searchParams);
        });
    },
    [blockingStopped]
  );

  return useCallback(() => {
    blockingStarted();
    const orderArticles: OrderArticle[] = [];

    addTipItemIfNeeded(orderArticles);
    addTransactionFeeItemIfNeeded(orderArticles);

    httpPost(
      "/v5_2/qr/createOutstandingPayment",
      {
        emailAdres: store.getState().customFields.orderCustomFields?.email?.value,
        payIssuer: jamezzPaymentMethod.payIssuer,
        payMethod: jamezzPaymentMethod.payMethod,
        paymentData: {
          items: props.items,
          payment: props.amount,
          total: props.totalAmount,
        },
        items: orderArticles,
        vouchers: [],
      },
      { showToastSuccess: false, showSpinner: false }
    )
      .then((response: any) => {
        if (response?.data?.data) {
          dispatch(orderCreated(response?.data?.data));
        }

        httpGet("/v5_2/sessions/paymentmethods", null, { showToastSuccess: false, showSpinner: false })
          .then((paymentMethodsResponse: any) => {
            const paymentMethodData = paymentMethodsResponse?.data?.data;
            if (paymentMethodData) {
              for (const provider of paymentMethodData) {
                const searchParams = new URLSearchParams();
                searchParams.set("payProvider", provider.providerName);

                switch (provider.providerName) {
                  case "SIXPAYMENTSTIM":
                  case "WESTPAY":
                  case "VERIFONE_FR":
                  case "ZVT":
                    searchParams.set("payMethod", "0");
                    break;
                  default:
                    searchParams.set("payMethod", "999");
                    break;
                }
                requestPayment(searchParams.toString());
              }
            }
          })
          .catch((err) => {
            console.log(err);
            blockingStopped();
            toast.error(<FormattedMessageJamezz id={"Payment error!"} />);
          });
      })
      .catch((err) => {
        console.log(err);
        blockingStopped();
        toast.error(<FormattedMessageJamezz id={"Payment error!"} />);
      });
  }, [
    dispatch,
    jamezzPaymentMethod.payIssuer,
    jamezzPaymentMethod.payMethod,
    props.items,
    props.amount,
    props.totalAmount,
    requestPayment,
    blockingStopped,
    blockingStarted,
  ]);
}

export default useSendAfterPay;
