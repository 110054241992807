import { useAppSelector } from "../../../global/utils/redux/store";
import { useGetLoggedinUserQuery } from "../../../global/utils/redux/api/piggyAuthApi";

export function usePiggyUser() {
  const contactIdentifierScan = useAppSelector((state) => state.piggy.contactIdentifierScan);
  const piggyLoyaltyEnabled = useAppSelector((state) => state.global.salesarea.piggy.loyalty.enabled);
  const piggyPrepaidEnabled = useAppSelector((state) => state.global.salesarea.piggy.prepaid.enabled);
  const piggyEnabled = piggyLoyaltyEnabled || piggyPrepaidEnabled;
  const {
    data: piggyUser,
    isSuccess,
    isLoading,
  } = useGetLoggedinUserQuery(undefined, {
    skip: !piggyEnabled || (piggyEnabled && contactIdentifierScan === null),
  });

  return {
    piggyUser,
    piggyUserScannedCard: contactIdentifierScan != null,
    isLoggedIn: isSuccess && piggyUser?.data.has_session,
    isLoading,
    piggyEnabled,
  };
}
