import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import CloseDialogButton from "../Dialog/CloseDialogButton.tsx";
import { TextField, Typography } from "@mui/material";
import { useCustomTexts } from "../../../global/utils/useCustomTexts";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { useLazyFetchCustomerRewardsQuery, useLoginMutation } from "../../../global/utils/redux/api/MWiseApi";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import BackgroundMediaUrlFromSalesarea from "../../../kiosk/hooks/useBackgroundMediaUrl.tsx";

export default function LoginDialog() {
  const customTexts = useCustomTexts(["mwise loyalty - login body", "mwise loyalty - login header"]);
  const [cardNumber, setCardNumber] = useState(sessionStorage.getItem("mwise_card_number") ?? "");
  const [password, setPassword] = useState(sessionStorage.getItem("mwise_password") ?? "");

  const { closeDialog } = useDialog();
  const [login, { isLoading, error, isError }] = useLoginMutation<any>();
  const [fetchRewards, {}] = useLazyFetchCustomerRewardsQuery();

  useEffect(() => {
    if (cardNumber) {
      sessionStorage.setItem("mwise_card_number", cardNumber);
    }
  }, [cardNumber]);
  useEffect(() => {
    if (password) {
      sessionStorage.setItem("mwise_password", password);
    }
  }, [password]);
  return (
    <BackgroundMediaUrlFromSalesarea
      keyName={"extra_settings__qrv5_background_of_order_page"}
      sx={{
        width: 1,
        maxWidth: 800,
        height: 1,
        padding: 4,
        display: "flex",
        flexDirection: "column",
      }}
      fallbackBackgroundColor={"primary.light"}
    >
      <CloseDialogButton />
      <Typography variant="h5" sx={{ fontWeight: 800, mb: 4, ml: 4, mr: 4, textAlign: "center" }}>
        {customTexts["mwise loyalty - login header"] ?? "Extraatjes"}
      </Typography>
      <Box>
        <Typography variant="h5">
          <FormattedMessageJamezz id="MWise.auth.header.login" />
        </Typography>
        <Typography variant="body1">
          {customTexts["mwise loyalty - login body"] ?? (
            <FormattedMessageJamezz id="MWise.auth.body.loyalty_explanation" />
          )}
        </Typography>
        <Box sx={{ marginTop: 4 }}></Box>
        <form
          style={{ display: "flex", gap: "8px", flexDirection: "column" }}
          onSubmit={async (e) => {
            e.preventDefault();
            try {
              const res = await login({ card_number: cardNumber, password }).unwrap();
              toast.success("Logged in");
              fetchRewards();
              closeDialog();
            } catch (e) {}
          }}
        >
          <TextField
            label={<FormattedMessageJamezz id="E-mail address" />}
            name="e-mail"
            sx={{ flexGrow: 1 }}
            required
            value={cardNumber}
            onChange={(e) => {
              setCardNumber(e.target.value);
            }}
            error={isError}
          />
          <TextField
            label={<FormattedMessageJamezz id="Password" />}
            type="password"
            name="password"
            required
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            error={isError}
            helperText={isError && error ? (error.data?.message ?? "Unknown error") : ""}
          />
          <LoadingButton variant="contained" type="submit" loading={isLoading}>
            <FormattedMessageJamezz id="Login" />
          </LoadingButton>
        </form>
      </Box>
    </BackgroundMediaUrlFromSalesarea>
  );
}
