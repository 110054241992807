import store, { useAppSelector } from "../redux/store";
import { useRequestShoppingCartConfirmationMutation } from "../redux/api/shoppingCartApi";
import { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import {
  requestSentForUuid,
  shoppingCartConfirmationResetted,
  waitingForRequestDialogIsOpened,
} from "../redux/shoppingCartSlice";
import useCancelRequest from "./useCancelRequest";
import useWebsocketChannel from "./useWebsocketChannel";
import useWatcherDenied from "./useWatcherDenied";
import WaitingForOrderConfirmationDialog from "./WaitingForOrderConfirmationDialog";
import { selectRequireAgeArticle } from "./selectRequireAgeArticle";
import { BlockUserInput } from "@jamezz/react-components";

export default function RequireAgeShoppingCartCheck() {
  const uuid = useAppSelector((state) => state.shoppingCart.serverSideShoppingCart?.uuid);

  const highestRequireAgeArticle = useAppSelector(selectRequireAgeArticle);
  const requestUuid = useAppSelector((state) => state.shoppingCart.shoppingCartConfirmation.requestSentForUuid);
  const waitingForRequestDialogIsOpen = useAppSelector(
    (state) => state.shoppingCart.shoppingCartConfirmation.waitingForRequestDialogIsOpen
  );
  const zoomFactor = useAppSelector((state) => state.global.salesarea?.custom_data?.kiosk?.zoom_factor ?? 0);
  const [requestOrderConfirmation] = useRequestShoppingCartConfirmationMutation();

  const [retry, setRetry] = useState("retry");
  const { blockingStopped, blockingStarted } = BlockUserInput.useBlockUserInput();

  useCancelRequest();
  useWebsocketChannel();
  useWatcherDenied();

  useEffect(() => {
    return () => {
      store.dispatch(shoppingCartConfirmationResetted());
    };
  }, [uuid]);

  useEffect(() => {
    if (requestUuid) {
      store.dispatch(waitingForRequestDialogIsOpened());
    }
  }, [requestUuid]);

  useEffect(() => {
    const copyUuid = uuid;

    if (highestRequireAgeArticle && copyUuid && copyUuid !== requestUuid && retry) {
      blockingStarted();

      requestOrderConfirmation({ shoppingCartUuid: copyUuid, requestType: "alcohol_confirmation" }).then((response) => {
        if ("data" in response) {
          blockingStopped();
          store.dispatch(requestSentForUuid(copyUuid));
        } else {
          setTimeout(() => {
            setRetry(new Date().toISOString());
          }, 5000);
        }
      });
    }
  }, [uuid, highestRequireAgeArticle, requestOrderConfirmation, requestUuid, retry, blockingStarted, blockingStopped]);

  return waitingForRequestDialogIsOpen ? (
    <Dialog
      disableAutoFocus={true} // Do not remove, is needed for iframe usage.
      // TransitionComponent={TransitionComponent}
      fullScreen={false}
      fullWidth={true}
      maxWidth={zoomFactor > 0 ? false : "md"}
      open={true}
    >
      <WaitingForOrderConfirmationDialog />
    </Dialog>
  ) : null;
}
