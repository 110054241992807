import useSalesareaType, { SalesareaType } from "../../../global/utils/hooks/useSalesareaType.tsx";
import { useCustomTextsFromModel } from "../../../global/utils/useCustomTexts.tsx";
import { Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";
import { AyceRuleResult } from "../../utils/arrangements/useCheckAyceRules.ts";
import { ArrangementOverText } from "../../components/Arrangements/ArrangementOverText.tsx";

export function OrderingUnavailableReason({
  articlegroupId,
  arrangementRuleResult,
}: {
  articlegroupId: string;
  arrangementRuleResult: AyceRuleResult;
}) {
  const salesareaType = useSalesareaType();
  const reasonDisabledOrderingText = useCustomTextsFromModel("menukaart", articlegroupId, "reason_disabled_ordering");

  if (salesareaType === SalesareaType.CATALOG) {
    return null;
  }

  const arrangementOver =
    arrangementRuleResult === AyceRuleResult.EATING_TIME_OVER ||
    arrangementRuleResult === AyceRuleResult.ROUND_COUNTER_EXCEEDED;
  return (
    <Typography
      className={"JS-OrderArticleSelectorPageContent-ThisItemIsNotAvailableText"}
      sx={{ textAlign: "center", fontSize: "0.9rem", mb: 2 }}
    >
      {arrangementOver ? (
        <ArrangementOverText rule={arrangementRuleResult} />
      ) : (
        (reasonDisabledOrderingText ?? <FormattedMessageJamezz id={"This item is not available at this moment"} />)
      )}
    </Typography>
  );
}
