import Campaign from "../../../global/utils/models/campaigns/Campaign";
import Box from "@mui/material/Box";
import Image from "../../../global/components/Image";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/material";

interface Props {
  campaign?: Campaign;
  sx?: SxProps<Theme>;
}

export default function CampaignBanner(props: Props) {
  return (
    <Box className={"JS-CampaignBanner-Root"} sx={{ width: 1, ...(props.sx ?? {}) }}>
      {props.campaign && props.campaign.mediaUrl ? (
        <Image
          className={"JS-CampaignBanner-Image"}
          srcSet={props.campaign.mediaUrl?.conversions?.responsive?.srcset}
          style={{ width: "100%", height: "100%", objectFit: "contain", objectPosition: "right" }}
        />
      ) : null}
    </Box>
  );
}
