import Box from "@mui/material/Box";
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import MenuFilter from "./MenuFilter";
import useMenuTagFilters from "../../../global/utils/menu/filters/useMenuTagFilters";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { menuTagNamesMap, MenuTagType } from "../../../global/utils/models/menu/MenuTag";
import { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import CloseDialogButton from "../../components/Dialog/CloseDialogButton.tsx";
import { enabledHideItemsBySelectedAllergens } from "../../../global/utils/redux/globalSlice";
import { useCustomTexts } from "../../../global/utils/useCustomTexts";
import { useIntl } from "react-intl";
import { unstable_LowPriority, unstable_scheduleCallback } from "scheduler";
import { selectAppLanguage } from "../../../kiosk/components/LanguageSelector/useLanguage.ts";

function MenuFilterDialog() {
  const menuTags = useMenuTagFilters();
  const dispatch = useAppDispatch();
  const salesarea = useAppSelector((state) => state.global.salesarea);
  const hideItemsBySelectedAllergens = useAppSelector((state) => state.global.hideItemsBySelectedAllergens);
  const intl = useIntl();
  const selectedLanguage = useAppSelector(selectAppLanguage);

  const myAllergens = useMemo(() => {
    return menuTags
      .filter((menuTag) => menuTag.type === MenuTagType.Exclude)
      .sort((a, b) => {
        return (
          a.name in menuTagNamesMap
            ? intl.formatMessage({ id: menuTagNamesMap[a.name as keyof typeof menuTagNamesMap] })
            : (a.translations?.[selectedLanguage]?.name ?? a.name)
        ).localeCompare(
          b.name in menuTagNamesMap
            ? intl.formatMessage({ id: menuTagNamesMap[b.name as keyof typeof menuTagNamesMap] })
            : (b.translations?.[selectedLanguage]?.name ?? b.name)
        );
      });
  }, [menuTags, intl, selectedLanguage]);

  const myPreferences = useMemo(() => {
    return menuTags
      .filter((menuTag) => menuTag.type === MenuTagType.Include)
      .sort((a, b) =>
        (a.translations?.[selectedLanguage]?.name ?? a.name).localeCompare(
          b.translations?.[selectedLanguage]?.name ?? b.name
        )
      );
  }, [menuTags, selectedLanguage]);

  const extraProductIds = useMemo(() => {
    return salesarea?.custom_data?.allergen_product_map ?? {};
  }, [salesarea]);
  const customTexts = useCustomTexts(["allergens_subtext", "tag_preferences_subtext"]);

  return (
    <Box
      data-cy="allergen-page"
      sx={{
        width: 1,
        height: 1,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 4,
      }}
    >
      <CloseDialogButton />
      {/*<Typography variant={"h5"}  sx={{ fontStyle: "italic", fontWeight: 800 }}>*/}
      {/*  <FormattedMessageJamezz id={"Filter menu"} />*/}
      {/*</Typography>*/}
      {myPreferences.length > 0 && (
        <Grid container spacing={3} sx={{ paddingY: 3 }}>
          <Grid item xs={12}>
            <Typography
              className={"JS-MenuFilterDialog-PreferencesTitle"}
              variant={"h5"}
              sx={{ fontStyle: "italic", fontWeight: 800, textAlign: "center" }}
            >
              <FormattedMessageJamezz id={"My preferences"} />
            </Typography>
            {customTexts["tag_preferences_subtext"] ? (
              <Typography className={"JS-MenuFilterDialog-Preference-Subtext"} sx={{ marginTop: 2 }}>
                {customTexts["tag_preferences_subtext"]}
              </Typography>
            ) : null}
          </Grid>
          <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", width: 1 }}>
            {myPreferences.map((menuTag) => (
              <Box key={menuTag.id}>
                <MenuFilter menuTag={menuTag} />
              </Box>
            ))}
          </Box>
        </Grid>
      )}
      {myAllergens.length > 0 && (
        <Grid className="JS-MenuFilterDialog-Allergies" container spacing={3} sx={{ paddingY: 3 }}>
          <Grid item xs={12}>
            <Typography
              className={"JS-MenuFilterDialog-AllergiesTitle"}
              variant={"h5"}
              sx={{ fontStyle: "italic", fontWeight: 800, textAlign: "center" }}
            >
              <FormattedMessageJamezz id={"My allergies"} />
            </Typography>
            {customTexts["allergens_subtext"] ? (
              <Typography className={"JS-MenuFilterDialog-Allergens-Subtext"} sx={{ marginTop: 2 }}>
                {customTexts["allergens_subtext"]}
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox />}
              label={<FormattedMessageJamezz id={"Hide items taking into account your allergies"} />}
              sx={{ fontSize: "0.8rem" }}
              defaultChecked={hideItemsBySelectedAllergens}
              onChange={(e: any) => {
                unstable_scheduleCallback(unstable_LowPriority, function () {
                  dispatch(enabledHideItemsBySelectedAllergens(e.target.checked));
                });
              }}
            />
          </Grid>
          <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", width: 1 }}>
            {myAllergens.map((menuTag) => (
              <Box key={menuTag.id}>
                <MenuFilter menuTag={menuTag} articleId={extraProductIds[menuTag.id]} />
              </Box>
            ))}
          </Box>
        </Grid>
      )}
    </Box>
  );
}

export default MenuFilterDialog;
