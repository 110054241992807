import { forwardRef, useMemo } from "react";
import CustomField from "../../../../types/shared/CustomField";
import { Chip, Collapse, FormControl, FormHelperText, TextField } from "@mui/material";
import PhoneInput, { getCountries, getCountryCallingCode, isValidPhoneNumber } from "react-phone-number-input";

import "react-phone-number-input/style.css";
import useCustomField from "./useCustomField";
import { TransitionGroup } from "react-transition-group";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz";
import { useFormContext } from "react-hook-form";
import { onFocusKeyboard } from "../../../../global/utils/redux/globalSlice";
import { useAppDispatch, useAppSelector } from "../../../../global/utils/redux/store";
import { jamezzApplicationType } from "../../../App";
import { useFocusCustomField } from "./useFocusCustomField";
import isKiosk from "../../../../global/utils/helpers/isKiosk";
import Explanation from "./Explanation";
import { CountryCode } from "libphonenumber-js";
import { useCustomfieldTranslatableText } from "../../../../global/utils/languages.ts";

interface Props {
  customField: CustomField;
}

const mapPhoneToCountryCode: {
  [key: string]: CountryCode;
} = {};
const countries = getCountries();
countries.forEach((countryCode) => {
  mapPhoneToCountryCode[getCountryCallingCode(countryCode).toString()] = countryCode;
});

function CustomFieldTelephoneContent(props: Props) {
  const customField = props.customField;
  const {
    formState: { errors },
  } = useFormContext();
  const { value, setCustomField } = useCustomField(props.customField, {
    validate: (value: string) => (customField.required || value ? isValidPhoneNumber(value) : true),
  });
  const dispatch = useAppDispatch();

  // const inputRef = useRef<HTMLInputElement | null>(null);

  const options: CountryCode[] = ["NL", "BE", "DE"];
  const defaultSMSLang = useAppSelector((state) => state.global.salesarea.defaultSMSLang);

  const defaultCountry = useMemo(() => {
    const defaultSMSCode = defaultSMSLang.replace(/[^0-9]*/, "");
    if (mapPhoneToCountryCode[defaultSMSCode]) {
      return mapPhoneToCountryCode[defaultSMSCode];
    } else {
      return "NL";
    }
  }, [defaultSMSLang]);

  const textField = useMemo(
    () =>
      forwardRef<HTMLInputElement>((props, ref) => {
        useFocusCustomField(typeof ref === "function" ? null : ref, customField.name);

        const customFieldText = useCustomfieldTranslatableText();
        const label = customFieldText(customField, "translationOverwrite") ?? customField.label;
        return (
          <FormControl sx={{ marginY: 1, width: 1 }}>
            <FormHelperText>{customField.description}</FormHelperText>
            <TextField
              {...props}
              helperText={<Explanation customField={customField} />}
              inputRef={ref}
              variant={"outlined"}
              type={isKiosk() ? "text" : "tel"}
              name={customField.name}
              inputProps={{
                required: customField.required,
                inputMode: isKiosk() ? "none" : undefined,
                autoComplete: isKiosk() ? "off" : undefined,
              }}
              onKeyDown={(e) => {
                if (isKiosk()) {
                  e.stopPropagation();
                  if (e.keyCode == 13) {
                    e.preventDefault();
                    return false;
                  }
                }
              }}
              disabled={customField.disabled}
              label={label}
              fullWidth
            />
          </FormControl>
        );
      }),
    [customField]
  );

  return (
    <>
      {jamezzApplicationType.type != "KIOSK" ? (
        <TransitionGroup>
          {errors[customField.name] ? (
            <Collapse>
              <Chip
                sx={{
                  backgroundColor: "error.light",
                  margin: 1,
                  fontWeight: 800,
                  color: "error.contrastText",
                }}
                label={<FormattedMessageJamezz id="CustomFields.telephone.feedback.use-valid-phone-number" />}
              />
            </Collapse>
          ) : null}
        </TransitionGroup>
      ) : null}

      <PhoneInput
        style={{ width: "100%" }}
        options={options}
        defaultCountry={defaultCountry}
        // country={defaultCountry}
        value={value}
        onChange={(value) => {
          setCustomField(value ?? "");
        }}
        inputComponent={textField}
        onFocus={() => {
          if (isKiosk()) {
            dispatch(onFocusKeyboard({ value, layout: "numpad" }));
          }
        }}
      />
    </>
  );
}

export default CustomFieldTelephoneContent;
