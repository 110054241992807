import { useAppSelector } from "./redux/store";
import { useCallback, useMemo } from "react";
import Currency from "./models/currencies/Currency";
import roundTwoDecimals from "./helpers/roundTwoDecimals";
import Box from "@mui/material/Box";

export enum CurrencyLocation {
  Menu = "Menu",
  ArticleDetail = "ArticleDetail",
  AddToOrderButton = "AddToOrderButton",
  Checkout = "Checkout",
  ShowAlways = "ShowAlways",
  PiggyVoucher = "PiggyVoucher",
  CikamPayment = "CikamPayment",
}

export default function useCurrency({
  location,
  prefixWithSign = false,
}: {
  location: CurrencyLocation;
  prefixWithSign?: boolean;
}) {
  const currency = useAppSelector((state) => state.menu.currency);
  const currencyRates = useAppSelector((state) => state.menu.currencyRates);

  const currencyRate = useMemo(() => {
    return currencyRates[currency.id]?.value ?? 1;
  }, [currency, currencyRates]);

  const symbol = currency.symbol;

  return useCallback(
    (
      number: number,
      {
        digits = 2,
        useCurrencyRate = true,
        currency,
        name,
      }: {
        digits?: number;
        useCurrencyRate?: boolean;
        currency?: Currency;
        name?: string;
      } = {}
    ) => {
      let value = number;
      if (useCurrencyRate) {
        if (currency) {
          value /= currencyRates[currency.id]?.value ?? 1;
        } else {
          value /= currencyRate;
        }
      }
      const symbolString = (currency?.symbol ?? symbol) + " ";
      const valueString = value.toFixed(roundTwoDecimals(digits));

      // This allows the return value of useCurrency to act as either a string or a React element
      return new Proxy(
        (
          <>
            <Box
              component="span"
              className={`JS-currency-symbol JS-currency-symbol-${location}${
                name ? ` JS-currency-symbol-${name}` : ""
              }`}
            >
              {symbolString}
            </Box>
            <Box
              component="span"
              className={`JS-currency-value JS-currency-value-${location}${name ? ` JS-currency-value-${name}` : ""}`}
            >
              {prefixWithSign && value > 0 ? "+" : ""}
              {valueString}
            </Box>
          </>
        ),
        {
          get: function (target, prop, receiver) {
            if (prop === "toString") {
              return () => symbolString + valueString;
            }
            return Reflect.get(target, prop, receiver);
          },
        }
      );
    },
    [currencyRate, currencyRates, location, prefixWithSign, symbol]
  );
}
