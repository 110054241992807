import React from "react";
import { CSSProperties } from "@mui/material/styles/createMixins";
import { BoxProps } from "@mui/material";

export default function SesameIcon({
  innerColor = "#fefefe",
  outerColor = "#a89a7b",
  className,
  propsStyle,
}: {
  innerColor?: CSSProperties["color"];
  outerColor?: CSSProperties["color"];
  className?: string;
  propsStyle?: BoxProps["style"];
}) {
  const style = {
    fill: innerColor,
  };

  return (
    <svg viewBox={`0 0 216 216`} className={className} style={{ color: outerColor, ...propsStyle }}>
      <path
        d="M107.74 215.86C48.15 215.86 0 167.34 0 107.75 0 47.81 48.15 0 107.74 0c59.93 0 108.08 47.81 108.08 107.75.01 59.59-48.15 108.11-108.08 108.11z"
        fill={"currentColor"}
      />
      <path
        style={{
          fill: innerColor,
        }}
        d="M164.46 87.06c-4.64-2.85-20.34 4.28-36.03 16.06-16.06 12.13-20.69 29.26-10.35 38.18 10.35 8.56 26.76 1.78 36.39-16.06 8.56-15.34 13.2-30.69 11.41-36.39-9.99 12.48-28.54 30.67-28.54 30.67-.35.36-.71.36-1.07.36-.37 0-.72 0-.72-.36-.35-.35-.35-1.06 0-1.77.01 0 18.92-18.56 28.91-30.69zM118.79 59.59c-6.06-9.64-13.19-16.77-17.48-18.56 2.15 7.49 5.71 20.33 8.92 28.54.35.36 0 1.07-.72 1.43h-.36c-.35 0-.71-.36-.71-.72-3.57-8.56-7.14-22.11-9.28-29.61h-.36c-3.2 1.43-4.28 13.92-2.14 27.48 2.14 13.92 10.71 22.47 19.62 18.9 8.58-3.2 10.01-15.69 2.51-27.46zM118.79 168.41c-9.98-10.35-20.33-17.13-24.96-16.77 7.85 11.42 16.05 24.61 16.05 24.97.36.35.36 1.07-.36 1.43 0 .36-.36.36-.71.36-.36 0-.72-.36-.72-.71-.35 0-8.2-13.19-16.41-24.98-2.49 3.57-.72 16.42 4.64 30.33 5.71 14.98 17.48 22.47 26.4 16.41 8.92-5.72 7.49-19.98-3.93-31.04zM87.76 95.62c-8.56-7.85-23.19-2.14-32.11 12.84-8.55 13.92-12.84 27.48-10.7 31.76 6.78-6.42 15.7-14.99 21.4-21.76.35-.35 1.07-.35 1.43 0 .72.35.72 1.06.36 1.79-5.71 6.41-14.27 14.98-21.04 21.4 5 1.43 17.83-3.93 30.68-13.21 14.26-10.34 18.9-24.96 9.98-32.82zM119.87 12.49c-8.56-3.21-16.41-4.64-19.98-3.57 4.99 3.57 13.2 9.63 18.9 13.2.36 0 .73.35.36.7 0 .36-.36.72-.71.72 0 0 0-.35-.37-.35-6.06-3.57-14.97-9.99-19.61-13.56 0 0-.37 0-.37.36-1.42 2.49 3.58 10.7 11.79 18.55 7.85 7.84 17.83 8.91 21.76 2.5 3.93-6.42-1.43-14.63-11.77-18.55z"
      />
    </svg>
  );
}
