import { useRef } from "react";
import CustomField from "../../../../types/shared/CustomField";
import { alpha, Grid, InputBase, styled, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Controller, useFormContext } from "react-hook-form";
import useCustomField from "./useCustomField";
import { onFocusKeyboard } from "../../../../global/utils/redux/globalSlice";
import { useAppDispatch } from "../../../../global/utils/redux/store";
import isKiosk from "../../../../global/utils/helpers/isKiosk";
import { useFocusCustomField } from "./useFocusCustomField";
import Explanation from "./Explanation.tsx";
import { useCustomfieldTranslatableText } from "../../../../global/utils/languages.ts";

interface Props {
  customField: CustomField;
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 32,
    position: "relative",
    // backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    // border: "1px solid",
    // borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 96,
    textAlign: "center",
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

function CustomFieldNumpadContent(props: Props) {
  const customField = props.customField;
  const { value, setCustomField } = useCustomField(props.customField);
  const inputRef = useRef<HTMLInputElement | null>(null);
  // const prev2Value = useRef<string>(value ?? "");
  // const prevValue = useRef<{ value: string; time: number }>({ value, time: new Date().getTime() });
  const dispatch = useAppDispatch();

  const { control } = useFormContext();
  const maxLength =
    customField.maxlength === undefined || Number.isNaN(customField.maxlength)
      ? 1024
      : Math.max(1, Number(customField.maxlength));
  useFocusCustomField(inputRef, customField.name);
  const customFieldText = useCustomfieldTranslatableText();
  const label = customFieldText(props.customField, "translationOverwrite") ?? customField.label;

  // useEffect(() => {
  //   if (Math.abs(prevValue.current?.time - new Date().getTime()) < 70) {
  //     setCustomField(prev2Value.current);
  //     prevValue.current = { value: prev2Value.current ?? "", time: new Date().getTime() };
  //   } else {
  //     prev2Value.current = prevValue.current.value;
  //     prevValue.current = { value: value ?? "", time: new Date().getTime() };
  //   }
  // }, [setCustomField, value]);

  return (
    <Grid item xs={12} container justifyContent={"center"} className={"JS-CustomFieldNumpadContent-Root"}>
      <Box sx={{ width: 1 }} className={"JS-CustomFieldNumpadContent-Wrapper"}>
        <Controller
          control={control}
          name={customField.name}
          rules={{ required: { value: customField.required ?? false, message: "Required!" } }}
          render={() => (
            <Box sx={{ width: 1, my: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Typography
                component={"span"}
                className={"JS-CustomFields-Input-Label JS-CustomFieldNumpadContent-Label"}
                fontSize={32}
              >
                {label}
              </Typography>
              <BootstrapInput
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                sx={{
                  width: 1,
                  maxWidth: 400,
                  "& .MuiInputBase-input": { mt: 2, width: 1, boxShadow: "0 1px 10px 0px rgba(0,0,0,0.8)" },
                }}
                inputRef={inputRef}
                name={customField.name}
                type={isKiosk() ? "text" : "number"}
                inputProps={{
                  pattern: customField.pattern ?? (customField.required ? ".*\\S+.*" : null),
                  required: customField.required,
                  className: "JS-CustomFields-Input JS-CustomFieldNumpadContent-Input",
                  inputMode: isKiosk() ? "none" : undefined,
                  autoComplete: isKiosk() ? "off" : undefined,
                  maxLength,
                }}
                value={value}
                onChange={(e) => {
                  if (!e.isTrusted || !isKiosk()) {
                    if (!maxLength || e.target.value.length <= maxLength) {
                      setCustomField(e.target.value);
                    }
                  }
                }}
                onFocus={() => {
                  if (isKiosk()) {
                    dispatch(
                      onFocusKeyboard({
                        value: value,
                        layout: "numonly",
                      })
                    );
                  }
                }}
              />
              <Explanation customField={customField} />
            </Box>
          )}
        />
      </Box>
    </Grid>
  );
}

export default CustomFieldNumpadContent;
