import { alpha, Badge, ButtonBase, Collapse, darken, IconButton, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { useDialog } from "../../../../global/utils/dialog/DialogProvider";
import { useMemo } from "react";
import { Close, Loyalty } from "@mui/icons-material";
import Article, {
  ArticleType,
  getArticleDescription,
  getArticleName,
} from "../../../../global/utils/models/menu/Article";
import CountDownTimer from "../../../../global/components/CountDownTimer";
import useArticle from "../../../../global/utils/menu/useArticle";
import OrderArticleSelectorPage from "../../OrderArticleSelectorPage/OrderArticleSelectorPage";
import SlideUpTransition from "../../../../global/components/SlideUpTransition";
import Image from "../../../../global/components/Image";
import ArticleCounter from "../components/ArticleCounter";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz";
import CampaignBanner from "../CampaignBanner";
import PriceWrapper from "./PriceWrapper";
import store, { useAppSelector } from "../../../../global/utils/redux/store";
import Articlegroup from "../../../../global/utils/models/menu/Articlegroup";
import { JSClassAnnotatedWithIds } from "../../../utils/Helpers";
import useMenuTagsForArticle from "../../../../global/utils/hooks/useMenuTagsForArticle";
import MenuTagView from "../../../../qr/components/MenuTagView";
import { useHasFilteredAllergens } from "../../../../global/utils/models/menu/MenuTag";
import { selectCountsArticles } from "../../../../global/utils/redux/shoppingCartSlice";
import { useIsPiggyOffer } from "../../../../global/utils/piggy/usePiggyRewards";
import { piggyScanDialogOpened } from "../../../../global/utils/redux/piggySlice";
import { useFetchPointsBalanceQuery } from "../../../../global/utils/redux/api/piggyApi";
import { ComponentOverlay } from "../../../../global/components/ComponentOverlay";
import { useGetLoggedinUserQuery } from "../../../../global/utils/redux/api/piggyAuthApi";
import JTooltip from "../../../components/JamezzMUI/JTooltip";
import { selectCampaignsMap } from "../../../../global/utils/redux/selectors/selectCampaignsMap";
import { selectArticleCampaignsMap } from "../../../../global/utils/redux/selectors/selectArticleCampaignsMap";
import { postAnalyticsEvent, postEcomAnalyticsEvent } from "../../../../global/utils/analytics/useAnalytics";
import { selectAppLanguage } from "../../../components/LanguageSelector/useLanguage.ts";
import { selectArticleMissingImagePlaceholder } from "../../../../global/utils/redux/selectors/selectArticleMissingImagePlaceholder.ts";
import { ClaimPiggyRewardDialog } from "../../../components/piggy/Loyalty/Rewards/RewardItem/ClaimPiggyRewardDialog.tsx";
import { useClaimPiggyRewardDialog } from "../../../components/piggy/Loyalty/Rewards/RewardItem/useClaimPiggyRewardDialog.tsx";

interface Props {
  article: Article;
  articlegroup?: Articlegroup;
  noShowCount?: boolean;
  disableEditing?: boolean;
}

/**
 * @param article  The article data object to display.
 * @param articlegroup  An object containing the articlegroup that the article belongs to.
 * @param disableEditing  A boolean value to indicate whether editing should be disabled.
 * @param noShowCount  A boolean value to indicate whether the article count should be displayed.
 */
function ArticleContent({ article, articlegroup, disableEditing, noShowCount }: Props) {
  const lang = useAppSelector(selectAppLanguage);
  const { openDialog } = useDialog();
  const theme = useTheme();
  const campaignsMap = useAppSelector(selectCampaignsMap);
  const articleCampaignsMap = useAppSelector(selectArticleCampaignsMap);

  // piggy
  const piggyOffer = useIsPiggyOffer(article);

  const piggyLoyaltyEnabled = useAppSelector((state) => state.global.salesarea.piggy.loyalty.enabled);
  const { data: loggedInPiggyUser } = useGetLoggedinUserQuery(undefined, { skip: !piggyLoyaltyEnabled });
  const { data: pointsBalance } = useFetchPointsBalanceQuery(undefined, {
    skip: !loggedInPiggyUser || !loggedInPiggyUser.data.has_session || !piggyLoyaltyEnabled,
  });
  const piggyRewardsClaimingEnabled = useAppSelector(
    (state) =>
      state.global.salesarea.piggy.loyalty.enabled && state.global.salesarea.piggy.loyalty.rewards_redeeming_enabled
  );
  const { onPiggyRewardDialogClose } = useClaimPiggyRewardDialog(piggyOffer);

  const articleMissingImagePlaceholder = useAppSelector(selectArticleMissingImagePlaceholder);
  const { secondsUntilEndTime, setCollapsed, collapsed, activeMenuTags } = useArticle(article);
  const countOfArticle = useAppSelector((state) => selectCountsArticles(state)[article.id]);
  const backgroundColor = useMemo(() => {
    // @ts-ignore
    return alpha(theme.palette.background.third, 1);
  }, [theme]);

  const campaigns = useMemo(() => {
    return (
      articleCampaignsMap[article.id]
        ?.map((campaignId) => {
          return campaignsMap[campaignId];
        })
        .filter((campaign) => campaign) ?? []
    );
  }, [articleCampaignsMap, campaignsMap, article.id]);

  const doCampaignsExist = useMemo(() => {
    return Object.keys(campaignsMap).length > 0;
  }, [campaignsMap]);
  const articleDescription = useMemo(() => {
    return <span dangerouslySetInnerHTML={{ __html: getArticleDescription(article, lang) }} />;
  }, [article, lang]);
  const menuTags = useMenuTagsForArticle(article);
  const hasFilteredAllergen = useHasFilteredAllergens(activeMenuTags);

  return (
    <Collapse
      data-cy="article-content-upper-wrapper"
      className={JSClassAnnotatedWithIds("JS-ArticleContent-Root", article)}
      orientation={"horizontal"}
      in={!collapsed}
      sx={{ width: 1, "& .MuiCollapse-wrapper": { width: 1 }, "& .MuiCollapse-wrapperInner": { width: 1 }, height: 1 }}
      unmountOnExit
    >
      <Collapse
        in={!collapsed}
        sx={{
          width: 1,
          height: 1,
          display: "flex",
          flexDirection: "column",
          // flex: "1 1 0%",
          "& .MuiCollapse-wrapper": { height: 1, width: 1 },
          "& .MuiCollapse-wrapperInner": {
            display: "flex",
            flexDirection: "column",
            width: 1,
            // flex: "1 1 0%",
          },
        }}
      >
        <Badge
          data-cy="piggy-article-reward-badge"
          invisible={piggyOffer == null || !piggyRewardsClaimingEnabled}
          badgeContent={
            piggyOffer ? (
              <ButtonBase
                sx={{ display: "inline-flex", alignItems: "center" }}
                onClick={() => {
                  if (!(piggyOffer == null || !piggyRewardsClaimingEnabled)) {
                    if (loggedInPiggyUser && loggedInPiggyUser.data.has_session) {
                      if (piggyOffer) {
                        openDialog({
                          children: (
                            <ClaimPiggyRewardDialog
                              item={piggyOffer}
                              piggyPoints={pointsBalance}
                              props={{
                                onClose: onPiggyRewardDialogClose,
                              }}
                            />
                          ),
                        });
                      }
                    } else {
                      store.dispatch(piggyScanDialogOpened());
                    }
                  }
                }}
              >
                <Loyalty
                  className="JS-ArticleContent-PiggyRewardBadge"
                  sx={(theme) => ({
                    color: darken(theme.palette.primary.main, 0.7),
                    translate: "1px 2px",
                    fontSize: "23px",
                    mr: "4px",
                  })}
                />
                <Typography sx={{ display: "inline", fontSize: "23px" }} className="JS-ArticleContent-PiggyRewardBadge">
                  <FormattedMessageJamezz
                    id="Piggy.menu-page.messages.get-for-free"
                    values={{
                      points: piggyOffer.required_credits,
                    }}
                  />
                </Typography>
              </ButtonBase>
            ) : null
          }
          color="primary"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{
            height: 1,
            "& .MuiBadge-badge": {
              transform: "translate(16px, 0)",
              // height: "42px",
            },
          }}
        >
          <Box
            data-cy="article-content"
            sx={{
              opacity: article.type === ArticleType.Filler ? 0 : hasFilteredAllergen ? 0.6 : 1,
              margin: 1,
              position: "relative",
              borderRadius: 3,
              // overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              flex: "1 1 0%",
              // height: 1000,
            }}
            className={JSClassAnnotatedWithIds("JS-ArticleContent-Paper", article, articlegroup)}
          >
            <ComponentOverlay
              disabled={!article.isOutOfStock && !article.isNotAvailable ? true : false}
              overlayContent={
                article.isOutOfStock ? (
                  <Typography variant={"h6"} sx={{ fontWeight: 1000 }} className={"JS-ArticleContent-OutOfStock-Text"}>
                    <FormattedMessageJamezz id={"OUT OF STOCK"} />
                  </Typography>
                ) : article.isNotAvailable ? (
                  <Typography variant={"h6"} sx={{ fontWeight: 1000 }} className={"JS-NotAvailable-NotAvailable-Text"}>
                    <FormattedMessageJamezz id={"NOT AVAILABLE"} />
                  </Typography>
                ) : (
                  <></>
                )
              }
              className={
                article.isOutOfStock
                  ? "JS-ArticleContent-OutOfStock"
                  : article.isNotAvailable
                    ? "JS-ArticleContent-NotAvailable"
                    : ""
              }
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: "1 1 0%",
                }}
                className={JSClassAnnotatedWithIds("JS-ArticleContent-FilterTag-Wrapper", article, articlegroup)}
              >
                <ButtonBase
                  className={JSClassAnnotatedWithIds("JS-ArticleContent-ButtonBase", article, articlegroup)}
                  disabled={articlegroup?.disableOrdering === true || disableEditing}
                  disableTouchRipple={disableEditing}
                  sx={{
                    width: 1,
                    borderRadius: 3,
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "row",
                    flex: "1 1 0%",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (
                      !(
                        article.isOutOfStock ||
                        article.isNotAvailable ||
                        article.type === ArticleType.InfoDismissable ||
                        article.type === ArticleType.Filler
                      )
                    ) {
                      postAnalyticsEvent({
                        category: "ArticleContent",
                        action: "OrderArticleSelectorPage(" + article.name + ")",
                      });
                      postEcomAnalyticsEvent("select_item", {
                        item_list_id: articlegroup?.id,
                        item_list_name: articlegroup?.name,
                        items: [
                          {
                            item_id: article.id,
                            item_name: article.name,
                            price: article.price,
                            quantity: 1,
                          },
                        ],
                      });
                      openDialog({
                        children: <OrderArticleSelectorPage article={article} articlegroup={articlegroup} />,
                        fullScreen: "almostFullScreen",
                        TransitionComponent: SlideUpTransition,
                        disabledBackdropClick: true,
                      });
                    }
                  }}
                >
                  <Box
                    sx={{
                      width: 0,
                      flex: "1 1 0%",
                      padding: 3,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      height: 1,
                    }}
                    className={JSClassAnnotatedWithIds("JS-ArticleContent-Wrapper1", article, articlegroup)}
                  >
                    {doCampaignsExist ? <CampaignBanner campaign={campaigns[0] ?? undefined} /> : null}
                    {article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset || articleMissingImagePlaceholder ? (
                      <Box
                        sx={{ height: 200, width: 1, padding: 1 }}
                        className={JSClassAnnotatedWithIds("JS-ArticleContent-ImageWrapper", article, articlegroup)}
                      >
                        <Image
                          srcSet={
                            article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset ??
                            articleMissingImagePlaceholder?.conversions?.optimized?.srcset
                          }
                          style={{ height: "100%", width: "100%", objectFit: "contain" }}
                        />
                      </Box>
                    ) : null}
                    <Typography
                      sx={{
                        fontWeight: 800,
                        fontSize: 32,
                        color: "primary.main",
                        flex: !articleDescription ? 1 : undefined,
                      }}
                      className={JSClassAnnotatedWithIds("JS-ArticleContent-Name", article, articlegroup)}
                    >
                      {article.score ? article.score + " -" : null}
                      {getArticleName(article, lang)}
                    </Typography>
                    <Typography
                      sx={{
                        opacity: 0.6,
                        paddingRight: 6,
                        width: 1,
                        fontSize: 22,
                        flex: 1,
                        height: 0,
                      }}
                      className={JSClassAnnotatedWithIds("JS-ArticleContent-Description", article, articlegroup)}
                    >
                      {articleDescription}
                    </Typography>
                    {noShowCount || piggyOffer
                      ? null
                      : !Number.isNaN(Number(countOfArticle)) && <ArticleCounter count={countOfArticle} />}
                    <Box
                      sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
                      className="JS-ArticleContent-MenuTags"
                    >
                      {menuTags
                        .filter((menuTag) => menuTag.showTag)
                        .map((menuTag) => (
                          <MenuTagView key={menuTag.id} menuTag={menuTag} width={32} margin={"4px"} />
                        ))}
                    </Box>
                    {/*{activeMenuTags?.map((activeMenuTag) => (*/}
                    {/*  <Box>*/}
                    {/*    <ActiveMenuTag menuTag={activeMenuTag} />*/}
                    {/*  </Box>*/}
                    {/*))}*/}
                    <PriceWrapper
                      price={article.price}
                      vaprice={article.vaprice}
                      localPrice={article.customData?.local_price > 0 ? article.customData?.local_price : null}
                      articleId={article.id}
                    />
                  </Box>
                </ButtonBase>
                {secondsUntilEndTime != null && secondsUntilEndTime < 900 ? (
                  <JTooltip
                    title={
                      <FormattedMessageJamezz
                        id={"{articleName} will be unavailable soon!}"}
                        values={{ articleName: getArticleName(article, lang) }}
                      />
                    }
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        backgroundColor: backgroundColor,
                        borderTopRightRadius: 12,
                        borderBottomLeftRadius: 12,
                        fontWeight: 800,
                        fontSize: "1.5rem",
                        paddingX: 2,
                        paddingY: 1,
                        zIndex: 10,
                        color: "white",
                      }}
                    >
                      <CountDownTimer seconds={secondsUntilEndTime} />
                    </Box>
                  </JTooltip>
                ) : null}
                {article.type === ArticleType.InfoDismissable || article.isOutOfStock ? (
                  <IconButton
                    size={"large"}
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      marginTop: 0,
                      marginRight: 0,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setCollapsed(true);
                    }}
                  >
                    <Close />
                  </IconButton>
                ) : null}
              </Box>
            </ComponentOverlay>
          </Box>
        </Badge>
      </Collapse>
    </Collapse>
  );
}

export default ArticleContent;
