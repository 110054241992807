import { useEffect } from "react";
import { walkParents } from "../../utils/domHelpers/DomHelpers";
import { useAppDispatch } from "../../utils/redux/store";
import { liveEditModeEnabled } from "../../utils/redux/devSlice";
import { liveInterfaceStylingPreviewChanged } from "../../utils/redux/globalSlice";

let mouseIdleEventInitiated = false;

function sendMessageToBackoffice(message: { type: string; [k: string]: any }) {
  if (window.top) {
    window.top.postMessage(message, "*");
  } else {
    console.warn("Live interface: couldn't send message to parent, no parent available");
  }
}

function initMouseIdleEvent() {
  if (mouseIdleEventInitiated) return;
  const options = {
    idleTimeout: 100,
    movementThreshold: 2, // Bias for small movements (in pixels)
  };

  let mouseIdleTimeout: number;

  const idleEvent = new CustomEvent("mouseidle");

  function onMouseMove({ movementX, movementY }: MouseEvent) {
    // euclidian distance
    const dist = Math.sqrt(Math.pow(movementX, 2) + Math.pow(movementY, 2));
    if (dist > options.movementThreshold) {
      clearTimeout(mouseIdleTimeout);
      mouseIdleTimeout = window.setTimeout(() => {
        document.dispatchEvent(idleEvent);
      }, options.idleTimeout);
    }
  }

  document.addEventListener("mousemove", onMouseMove);
  document.body.addEventListener("mouseleave", () => {
    clearTimeout(mouseIdleTimeout);
  });
  mouseIdleEventInitiated = true;
}

function paramsFromClassList(classList: DOMTokenList): { parameterName: string; value: number }[] {
  return [...classList]
    .filter((cls) => cls.includes("--"))
    .map((cls) => {
      const split = cls.split("--")[1];
      // id is special case, it has no annotation
      if (split.match(/^\d+$/)) {
        return {
          parameterName: "id",
          value: Number.parseInt(split),
        };
      } else {
        const matches = split.match(/^(?<parameterName>[A-Z][a-zA-Z]+)(?<parameter>\d+)$/);
        if (matches?.groups) {
          return {
            parameterName: matches.groups.parameterName,
            value: Number.parseInt(matches.groups.parameter),
          };
        }
        return null;
      }
    })
    .filter((v) => v != null) as { parameterName: string; value: number }[];
}

/**
 * Walks up the DOM to find the Root element, and extracts parameters if any
 * @param element
 */
function findJSClassParameters(element: HTMLElement) {
  for (let el of walkParents(element)) {
    if (!el.classList) continue;
    const hasRootJSClass = [...el.classList].find((cls) => cls.includes("-Root"));
    if (hasRootJSClass) {
      return paramsFromClassList(el.classList);
    }
  }
  return [];
}

function initLiveInterface() {
  const style = document.createElement("style");
  style.textContent = `
.Highlight-hover, .Highlight-unselected-hover {
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px inset, rgba(0, 0, 0, 0.2) 0px 0px 100px 750px inset !important;
  box-sizing: border-box !important;
  transition: 0.1s !important;
}
img.Highlight-hover {
  filter: drop-shadow(0 0 5px #f46e26) grayscale(1);
}
`;

  initMouseIdleEvent();

  let topLevelElement: {
    type: string;
    content: string[];
    contentWidth: number;
    contentHeight: number;
    contentMousePos: number;
    contentDescription: string;
    contentTopSpacing: number;
    contentLeftSpacing: number;
    parameters: { parameterName: string; value: number }[];
  };
  let elementsHigherInTree: string[][];

  document.head.appendChild(style);

  document.addEventListener("mouseidle", function () {
    sendMessageToBackoffice(topLevelElement);
    if (elementsHigherInTree.length > 0) {
      sendMessageToBackoffice({
        type: "ElementsHigherInTree",
        content: elementsHigherInTree,
      });
    }
  });

  document.addEventListener("mousemove", function (e) {
    if (!window.top) return;
    let topLevelElementFound = false;
    // these elements might also be relevant to edit. They might also be covered by their branches or leaves
    elementsHigherInTree = [];
    document.querySelectorAll(".Highlight-hover").forEach((el) => {
      el.classList.remove("Highlight-hover");
    });
    for (let el of walkParents(e.target as SVGElement | HTMLElement)) {
      if (!el.classList) continue;
      const JSClasses = [...el.classList.values()].filter((cls) => cls.startsWith("JS-"));
      if (JSClasses.length > 0) {
        if (!topLevelElementFound) {
          el.classList.add("Highlight-hover");
          const listener = function () {
            el.classList.remove("Highlight-hover");
            el.removeEventListener("mouseleave", listener);
          };
          el.addEventListener("mouseleave", listener);
          topLevelElement = {
            type: "Highlight-hover",
            content: JSClasses,
            contentWidth: "offsetWidth" in el ? el.offsetWidth : 0,
            contentHeight: "offsetHeight" in el ? el.offsetHeight : 0,
            contentMousePos: e.clientY,
            contentDescription: "innerText" in el ? el.innerText : "",
            contentTopSpacing: el.getBoundingClientRect().top,
            contentLeftSpacing: el.getBoundingClientRect().left,
            parameters: findJSClassParameters(el as HTMLElement),
          };
          topLevelElementFound = true;
        } else {
          elementsHigherInTree.push(JSClasses);
        }
      }
    }
  });
}

export default function LiveInterface() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.onmessage = function (ev) {
      //On loading live interface
      if (ev.data.type === "init") {
        initLiveInterface();
        dispatch(liveEditModeEnabled());
      }

      //Reloading live interface
      if (ev.data.type === "initReload") {
        window.location.reload();
      }

      //Hover custom component class
      if (ev.data.type === "addNewHoveredClass") {
        const cls = ev.data.classInfo.startsWith(".") ? ev.data.classInfo : `.${ev.data.classInfo}`;
        document.querySelectorAll(cls).forEach((el) => el.classList.add("Highlight-unselected-hover"));
      }

      //Hover remove custom component class
      if (ev.data.type === "removeNewHoveredClass") {
        const cls = ev.data.classInfo.startsWith(".") ? ev.data.classInfo : `.${ev.data.classInfo}`;
        document.querySelectorAll(cls).forEach((el) => el.classList.remove("Highlight-unselected-hover"));
      }

      if (ev.data.type === "updateStyling") {
        const previewStyles = ev.data as Record<string, Record<string, string> | "updateStyling">;
        const dottedClasses: Record<string, Record<string, string | Record<string, any>>> = Object.entries(
          previewStyles
        ).reduce(
          (acc, [cls, v]) => {
            if (v === "updateStyling") return acc;
            // Add a dot prefix to the class name and initialize a new nested object for its values
            acc[`.${cls}`] = Object.entries(v)
              .filter(([__, cssProp]) => cssProp != null && cssProp !== "")
              .reduce(
                (acc, [key, v2]) => {
                  // make display: none elements visible to have them be selectable in the live-editor
                  if (key === "display" && v2 === "none") {
                    // make display: none elements visible to have them be selectable in the live-editor
                    acc["opacity"] = "0.5 !important";
                  } else if (key === "pointerEvents" && v2.includes("none")) {
                    // ignore pointer-events: none
                    acc[key] = "initial";
                  } else {
                    acc[key] = `${v2} !important`;
                  }
                  return acc;
                },
                {} as Record<string, string | Record<string, any>>
              );
            return acc;
          },
          {} as Record<string, Record<string, string | Record<string, any>>>
        );
        dispatch(liveInterfaceStylingPreviewChanged(dottedClasses));
      }
    };

    sendMessageToBackoffice({ type: "LiveInterfaceReady" });

    return () => {
      window.onmessage = null;
    };
  }, [dispatch]);
  return <></>;
}
