import { useMemo } from "react";
import OrderOptionGroup from "../models/order/OrderOptionGroup";
import { getPropertyOfOptionGroup } from "../models/menu/OptionGroup.ts";
import Article from "../models/menu/Article.ts";

export enum Type {
  Radio,
  Optional,
  Countable,
}

export function optionGroupType(orderOptionGroup: OrderOptionGroup, article: Article) {
  if (
    !orderOptionGroup.optionGroup.countable &&
    getPropertyOfOptionGroup(orderOptionGroup.optionGroup, article, "maxCount") === 1 &&
    getPropertyOfOptionGroup(orderOptionGroup.optionGroup, article, "minCount") === 1
  ) {
    return Type.Radio;
  } else if (orderOptionGroup.optionGroup.countable) {
    return Type.Countable;
  } else {
    return Type.Optional;
  }
}

export default function useOptionGroup(orderOptionGroup: OrderOptionGroup, article: Article) {
  const type = useMemo(() => {
    return optionGroupType(orderOptionGroup, article);
  }, [orderOptionGroup, article]);
  return { type };
}
