import Box from "@mui/material/Box";
import { Alert, Button, Collapse, Divider, Grid, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import CheckoutArticleContent from "./CheckoutArticleContent";
import CustomFieldContent from "./CustomFieldContent";
import { FormProvider, useForm } from "react-hook-form";
import useCurrency, { CurrencyLocation } from "../../../global/utils/useCurrency";
import CustomField, { CustomFieldType } from "../../../types/shared/CustomField";
import useSendCheckout2 from "../../../global/utils/useSendCheckout2";
import CloseDialogButton from "../../components/Dialog/CloseDialogButton";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import JamezzPayment from "../../components/Payments/JamezzPayment";
import useSalesareaIsOpen from "../../../global/utils/useSalesareaIsOpen";
import { useAppSelector } from "../../../global/utils/redux/store";
import { useCustomTexts } from "../../../global/utils/useCustomTexts";
import {
  selectDeliveryFeeAmount,
  selectTransactionFeeAmount,
  useAccountSubTotalValue,
  useAccountTotalValue,
} from "../../../global/utils/redux/accountSlice";
import { TransitionGroup } from "react-transition-group";
import DiscountsCheckCalculator from "../../../global/components/MWise/DiscountsCheckCalculator";
import PiggyGiftcardCheckout from "../../components/Piggy/Giftcards/PiggyGiftcardCheckout";
import PiggyGiftcardCheckoutAmounts from "../../components/Piggy/Giftcards/PiggyGiftcardCheckoutAmounts";
import { selectArticlesSum, selectAutoAddedItems } from "../../../global/utils/redux/shoppingCartSlice";
import { useBetweenOrderPage } from "../../../global/components/BetweenOrderDialog";
import { OptionTranslationsContext } from "../../../global/utils/translations/useOptionTranslations";
import PackagingCheckout, { PackagingFee } from "../../components/Packagings/PackagingCheckout";
import { TermsAndConditions } from "./TermsAndConditions";
import { selectPaymentMethodCustomField } from "../../../global/utils/redux/selectors/selectPaymentMethodCustomField";
import { selectCustomFields } from "../../../global/utils/redux/selectors/selectCustomFields";
import useUpsellNoFoodOrNoDrinksInShoppingCart, {
  UpsellType,
} from "../../../global/utils/hooks/useUpsellNoFoodInShoppingCart";
import ArticleSuggestionPage from "../ArticleSuggestionPage/ArticleSuggestionPage";
import useIsWidescreen from "../../../global/utils/useIsWidescreen";
import BackgroundMediaUrlFromSalesarea from "../../../kiosk/hooks/useBackgroundMediaUrl";
import { postAnalyticsEvent, submitEComEvent } from "../../../global/utils/analytics/useAnalytics";
import VouchersV2CheckoutView from "./VouchersV2CheckoutView/VouchersV2CheckoutView.tsx";
import { selectPiggyGiftcardAmount } from "../../../global/utils/redux/selectors/selectPiggyGiftcardAmount.tsx";
import { selectVoucherOrderItems } from "../../../global/utils/redux/selectors/selectVoucherOrderItems.tsx";
import useTippingAsync from "../../../kiosk/pages/CheckoutPage/TippingAtCheckout.tsx";

/**
 * State machine:
 * from app
 * 1. sees checkout page
 * 2. Can go back (to app), or press pay button
 * 3. Pay button pressed -> open tip drawer
 * 4. User can go back (to 1), or decide on a tip (no tip or a tip amount)
 * 5. Tip decided -> Tip is set, order is sent.
 * @constructor
 */
function CheckoutPage() {
  const tipAmountValue = useAppSelector((state) => state.account.credits.tipAmount);
  const subTotalAmount = useAccountSubTotalValue();
  const totalAmount = useAccountTotalValue();
  const transactionFeeAmount = useAppSelector(selectTransactionFeeAmount);
  const deliveryFeeAmount = useAppSelector(selectDeliveryFeeAmount);
  const piggyGiftcardAmount = useAppSelector(selectPiggyGiftcardAmount);
  const items = useAppSelector((state) => state.shoppingCart.items);
  const itemsCount = useAppSelector(selectArticlesSum);
  const voucherOrderItems = useAppSelector(selectVoucherOrderItems);
  const autoAddedItems = useAppSelector(selectAutoAddedItems);
  const allItemsExceptShoppingCartItems = useMemo(
    () => [...voucherOrderItems, ...autoAddedItems],
    [voucherOrderItems, autoAddedItems]
  );
  const toCurrency = useCurrency({ location: CurrencyLocation.Checkout });
  const sendCheckout = useSendCheckout2();
  const payDirect = useAppSelector((state) => state.global.salesarea.payDirect);
  const { askForTip, tipComponent } = useTippingAsync(subTotalAmount);
  const customTexts = useCustomTexts(["pay button", "order button", "checkout_message"]);
  const handleBetweenOrder = useBetweenOrderPage();
  const usePackagings = useAppSelector((state) => state.global.salesarea.use_packagings);
  const shouldShowTermsAndConditions = useAppSelector(
    (state) => state.global.salesarea.terms_and_conditions.should_show
  );

  const userHasAcceptedTermsAndConditions = useAppSelector((state) => state.userInput.hasAcceptedTermsAndAgreements);
  useOneTimeUpsell();
  const useFormMethods = useForm({
    reValidateMode: "onSubmit",
    mode: "onSubmit",
    defaultValues: {},
    criteriaMode: "all",
  });
  const {
    trigger,
    formState: { errors },
  } = useFormMethods;

  const customFieldsExceptPayment = useAppSelector(selectCustomFields);
  const paymentCustomField = useAppSelector(selectPaymentMethodCustomField);
  const customFields = (
    paymentCustomField ? [...(customFieldsExceptPayment as []), paymentCustomField] : customFieldsExceptPayment
  ) as [];
  const isOpen = useSalesareaIsOpen();

  useEffect(() => {
    submitEComEvent("begin_checkout", autoAddedItems, items, voucherOrderItems, {
      value: totalAmount - piggyGiftcardAmount,
    });
    if ((customFieldsExceptPayment as []).length > 0) {
      submitEComEvent("add_shipping_info", autoAddedItems, items, voucherOrderItems, {
        shipping_tier: "ground",
        value: totalAmount - piggyGiftcardAmount,
      });
    }
  }, [
    sendCheckout,
    autoAddedItems,
    items,
    voucherOrderItems,
    totalAmount,
    piggyGiftcardAmount,
    customFieldsExceptPayment,
  ]);

  useEffect(() => {
    postAnalyticsEvent({
      category: "OrderPage",
      action: "openDialog->CheckoutPage",
    });
  }, [autoAddedItems, items, voucherOrderItems, totalAmount, piggyGiftcardAmount]);

  const customFieldsToShow = customFields
    .filter((customField: CustomField) => customField.type !== CustomFieldType.Hidden)
    .filter((customField: CustomField) => {
      return (
        customField.customfieldType === undefined ||
        (payDirect && customField.customfieldType === "payment_only") ||
        (!payDirect && customField.customfieldType === "order_only")
      );
    });

  return (
    <div data-cy="checkout-page" style={{ height: "100%" }}>
      {tipComponent}

      <FormProvider {...useFormMethods}>
        <form
          data-cy="checkout-page"
          style={{ height: "100%" }}
          onSubmit={async (e) => {
            e.preventDefault();

            if (!userHasAcceptedTermsAndConditions && shouldShowTermsAndConditions) {
              return;
            }
            const isValid = await trigger();
            if (isValid) {
              await handleBetweenOrder();
              if (payDirect) {
                await askForTip();
              }

              postAnalyticsEvent({
                category: "CheckoutPage",
                action: "sendCheckout",
              });
              sendCheckout();
            } else {
              console.log(errors);
            }
          }}
        >
          <BackgroundMediaUrlFromSalesarea
            keyName={"extra_settings__qrv5_background_of_checkout_page"}
            className="JS-CheckoutPage-BackgroundImage"
            sx={{
              display: "flex",
              width: 1,
              height: 1,
              justifyContent: "center",
              overflowY: "auto",
              position: "relative",
            }}
          >
            <CloseDialogButton />
            <Box
              sx={{
                width: 1,
                height: 1,
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 4,
                maxWidth: 600,
              }}
            >
              <Typography
                variant={"h5"}
                sx={{ fontStyle: "italic", fontWeight: 800 }}
                className="JS-CheckoutPage-Header"
              >
                {payDirect ? <FormattedMessageJamezz id={"Checkout"} /> : <FormattedMessageJamezz id={"Order"} />}
              </Typography>
              <DiscountsCheckCalculator />
              <Grid container>
                {items.map((orderArticle, index) => (
                  <OptionTranslationsContext.Provider key={orderArticle.uuid} value={orderArticle.article.translations}>
                    <CheckoutArticleContent
                      key={orderArticle.uuid}
                      orderArticle={orderArticle}
                      piggyDiscountsIndex={index}
                    />
                  </OptionTranslationsContext.Provider>
                ))}
                {allItemsExceptShoppingCartItems.map((orderArticle) => (
                  <OptionTranslationsContext.Provider key={orderArticle.uuid} value={orderArticle.article.translations}>
                    <CheckoutArticleContent key={orderArticle.uuid} orderArticle={orderArticle} />
                  </OptionTranslationsContext.Provider>
                ))}
              </Grid>
              <Divider sx={{ height: 4, width: 1, marginY: 2 }} />
              {customFieldsToShow.map((customField: CustomField) => (
                <CustomFieldContent key={customField.name} customField={customField} />
              ))}
              {customFieldsToShow.length ? <Divider sx={{ height: 4, width: 1, marginY: 2 }} /> : null}

              {shouldShowTermsAndConditions ? (
                <>
                  <TermsAndConditions />
                  <Divider sx={{ height: 4, width: 1, marginY: 2 }} />
                </>
              ) : null}

              {customTexts["checkout_message"] ? (
                <>
                  <Alert severity="info" className="JS-CheckoutPage-CheckoutMessage" icon={false}>
                    {customTexts["checkout_message"]}
                  </Alert>
                  <Divider sx={{ height: 4, width: 1, marginY: 2 }} />
                </>
              ) : null}
              <VouchersV2CheckoutView />
              {payDirect ? (
                <>
                  <PiggyGiftcardCheckout />
                  <Divider sx={{ width: 1 }} />
                </>
              ) : null}
              {totalAmount - piggyGiftcardAmount > 0 ? <JamezzPayment doPay={payDirect} /> : null}
              {usePackagings ? <PackagingCheckout /> : null}
              <Box sx={{ width: 1 }}>
                {usePackagings ? <PackagingFee showDivider={false} /> : null}
                <TransitionGroup style={{ width: "100%" }}>
                  {deliveryFeeAmount > 0 ? (
                    <Collapse>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ fontStyle: "italic", opacity: 0.6 }}>
                          <FormattedMessageJamezz id={"Delivery fee"} />
                        </Typography>

                        <Typography
                          align={"right"}
                          sx={{ fontStyle: "italic", opacity: 0.6, whiteSpace: "nowrap" }}
                          data-cy="delivery-costs"
                        >
                          {toCurrency(Number(deliveryFeeAmount))}
                        </Typography>
                      </Box>
                    </Collapse>
                  ) : null}

                  {transactionFeeAmount > 0 ? (
                    <Collapse>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ fontStyle: "italic", opacity: 0.6 }}>
                          <FormattedMessageJamezz id={"Transaction fee"} />
                        </Typography>

                        <Typography
                          align={"right"}
                          sx={{ fontStyle: "italic", opacity: 0.6, whiteSpace: "nowrap" }}
                          data-cy="transaction-costs"
                        >
                          {toCurrency(Number(transactionFeeAmount))}
                        </Typography>
                      </Box>
                    </Collapse>
                  ) : null}

                  {tipAmountValue > 0 ? (
                    <Collapse>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ fontStyle: "italic", opacity: 0.6 }}>
                          <FormattedMessageJamezz id={"Tip amount"} />
                        </Typography>

                        <Typography align={"right"} sx={{ fontStyle: "italic", opacity: 0.6, whiteSpace: "nowrap" }}>
                          {toCurrency(Number(tipAmountValue))}
                        </Typography>
                      </Box>
                    </Collapse>
                  ) : null}
                </TransitionGroup>
                <PiggyGiftcardCheckoutAmounts />
                <Box sx={{ display: "flex", justifyContent: "space-between", width: 1 }}>
                  <Typography className="JS-CheckoutPage-Total">
                    <FormattedMessageJamezz id={"Total amount"} />
                  </Typography>

                  <Typography
                    className="JS-CheckoutPage-Price"
                    align={"right"}
                    sx={{ fontWeight: 800, whiteSpace: "nowrap" }}
                  >
                    {toCurrency(totalAmount - piggyGiftcardAmount, { name: "CheckoutPage-Price" })}
                  </Typography>
                </Box>
              </Box>

              {itemsCount > 0 ? (
                <Button
                  data-cy="orderpage-checkout-payOrOrder-button"
                  className="JS-CheckoutPage-PayOrOrderButton"
                  sx={{ marginY: 4, marginBottom: 8, width: 1 }}
                  variant={"contained"}
                  size={"large"}
                  type={"submit"}
                  disabled={!isOpen}
                >
                  {payDirect
                    ? (customTexts["pay button"] ?? <FormattedMessageJamezz id={"Pay"} />)
                    : (customTexts["order button"] ?? <FormattedMessageJamezz id={"Order"} />)}
                </Button>
              ) : null}
              <Box sx={{ height: 10, opacity: 0 }}>_</Box>
            </Box>
          </BackgroundMediaUrlFromSalesarea>
        </form>
      </FormProvider>
    </div>
  );
}
function useOneTimeUpsell() {
  const isWideScreen = useIsWidescreen();

  const { isActive: isActiveNoFood, openUpsell: openUpsellNoFood } = useUpsellNoFoodOrNoDrinksInShoppingCart(
    UpsellType.upsellNoFood
  );
  const { isActive: isActiveNoDrinks, openUpsell: openUpsellNoDrinks } = useUpsellNoFoodOrNoDrinksInShoppingCart(
    UpsellType.upsellNoDrinks
  );

  useEffect(() => {
    // TODO performance: Refactor 'isActiveNoFood' to getIsActiveNoFood(store.getState) to prevent rerenders
    if (isActiveNoFood && isWideScreen) {
      openUpsellNoFood(ArticleSuggestionPage);
    }
  }, []);

  useEffect(() => {
    // TODO performance: Refactor 'isActiveNoDrinks' to getIsActiveNoDrinks(store.getState) to prevent rerenders
    if (isActiveNoDrinks && isWideScreen) {
      openUpsellNoDrinks(ArticleSuggestionPage);
    }
  }, []);
}
export default CheckoutPage;
