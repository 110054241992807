import CustomField from "../../../../types/shared/CustomField";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import useCustomField from "./useCustomField";
import { selectAppLanguage } from "../../../../kiosk/components/LanguageSelector/useLanguage.ts";
import { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { useCustomfieldTranslatableText } from "../../../../global/utils/languages.ts";

interface Props {
  customField: CustomField;
}

function CustomFieldSelectContent(props: Props) {
  const { value, setCustomField } = useCustomField(props.customField);
  const customField = props.customField;
  const selectedLanguage = useAppSelector(selectAppLanguage);

  const customFieldText = useCustomfieldTranslatableText();
  const label = customFieldText(props.customField, "translationOverwrite") ?? customField.label;
  return (
    <FormControl sx={{ width: 1, marginY: 1 }}>
      <FormHelperText>{customField.description}</FormHelperText>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        variant={"outlined"}
        fullWidth
        inputProps={{ required: customField.required }}
        value={value}
        onChange={(e) => {
          setCustomField(e.target.value);
        }}
      >
        {customField.options?.map((option: any) => (
          <MenuItem value={option.id} key={option.id}>
            {option.translationOverwrite?.[selectedLanguage] ?? option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CustomFieldSelectContent;
