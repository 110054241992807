import SlideUpTransition from "../../../components/SlideUpTransition.tsx";
import { useCallback } from "react";
import { useDialog } from "../../../utils/dialog/DialogProvider.tsx";
import QrArticleSuggestionPage from "../../../../qr/pages/ArticleSuggestionPage/ArticleSuggestionPage.tsx";
import KioskArticleSuggestionPage from "../../../../kiosk/pages/ArticleSuggestionPage/ArticleSuggestionPage.tsx";
import Article from "../../../utils/models/menu/Article.ts";
import { getArticleById } from "../../../utils/models/menu/Menu.ts";
import useMenuFilters from "../../../utils/menu/filters/useMenuFilters.ts";
import useIsWidescreen from "../../../utils/useIsWidescreen.ts";
import store from "../../../utils/redux/store.tsx";
import { selectArticlesMap } from "../../../utils/redux/selectors/selectArticlesMap.ts";
import isKiosk from "../../../utils/helpers/isKiosk.tsx";
import { selectAppLanguage } from "../../../../kiosk/components/LanguageSelector/useLanguage.ts";
import ShowArticleDialogAction from "../../../utils/automation/actions/ShowArticleDialogAction.ts";

export default function useOpenUpsellPage() {
  const { openDialog } = useDialog();
  const isWidescreen = useIsWidescreen();
  const articleFilter = useMenuFilters();

  return useCallback(
    (action: ShowArticleDialogAction, source: string) => {
      const lang = selectAppLanguage(store.getState());
      if (store.getState().global.salesarea.custom_data.upsell_enabled === false) {
        return;
      }
      const ArticleSuggestionPage = isKiosk() ? KioskArticleSuggestionPage : QrArticleSuggestionPage;

      let articles: Article[] = [];
      const articlesMap = selectArticlesMap(store.getState());
      action.articleIds.forEach((articleId) => {
        const article = getArticleById(articlesMap, articleId);
        if (article) {
          articles.push(article);
        }
      });
      articles = articles.filter(articleFilter);
      const validArticlesCount = articles.filter((article) => {
        return !(article.isOutOfStock || article.isNotAvailable);
      }).length;

      if (validArticlesCount > 0) {
        openDialog({
          children: (
            <ArticleSuggestionPage
              articles={articles}
              upsellType={action.upsellId ? "Upsells" : "Freeoption"}
              upsellId={action.upsellId}
              upsellSource={source ?? null}
              title={action.titleTranslations?.[lang] ?? action.title}
              text={action.descriptionTranslations?.[lang] ?? action.description}
            />
          ),
          fullScreen: isKiosk() ? "almostFullScreen" : !isWidescreen,
          TransitionComponent: SlideUpTransition,
        });
      }
    },
    [openDialog, articleFilter, isWidescreen]
  );
}
