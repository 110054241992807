import React, { ReactNode, useCallback } from "react";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import useOrderLimits from "../../../global/utils/useOrderLimits.ts";
import useKioskSendCheckout2 from "../../utils/useKioskSendCheckout2.tsx";
import { useAskIfUserWantsReceipt } from "../../components/askForReceiptDialog.tsx";
import { useAgeCheckByEmployee } from "../../components/ConfirmAgeByEmployeeDialog.tsx";
import { useBetweenOrderPage } from "../../../global/components/BetweenOrderDialog.tsx";
import useShoppingCartConfirmationCheck from "../../../global/utils/ageCheck/useShoppingCartConfirmationCheck.tsx";
import { OrderPageAction } from "./OrderPageAction.tsx";
import { AgeCheckResult } from "../../../global/utils/ageCheck/ageCheckUtils";

import { useScanBoardingPass } from "../../utils/useScanBoardingPass";
import { useAccountSubTotalValue } from "../../../global/utils/redux/accountSlice.tsx";
import useTippingAsync from "../CheckoutPage/TippingAtCheckout.tsx";
import { selectArticlesSum } from "../../../global/utils/redux/shoppingCartSlice.tsx";
import useAskForPrepaid from "../../components/piggy/Prepaid/useAskForPrepaid.tsx";

export function SendOrder({ children }: { children: ReactNode }) {
  const { isValid } = useOrderLimits();
  const noItemsInCart = useAppSelector(selectArticlesSum) === 0;

  const subTotalAmount = useAccountSubTotalValue();
  const { tipComponent, askForTip } = useTippingAsync(subTotalAmount);
  const sendOrder = useSendOrder(askForTip);

  return (
    <>
      {tipComponent}
      <OrderPageAction
        data-cy="btn-send-order"
        className="JS-SendOrderButton-Root"
        disabled={!isValid || noItemsInCart}
        onClick={sendOrder}
      >
        {children}
      </OrderPageAction>
    </>
  );
}

export function useSendOrder(askForTip: () => Promise<unknown>) {
  const { sendOrder } = useKioskSendCheckout2();
  const askForReceipt = useAskIfUserWantsReceipt();
  const confirmAgeByEmployee = useAgeCheckByEmployee();
  const handleBetweenOrder = useBetweenOrderPage();
  const checkShoppingCart = useShoppingCartConfirmationCheck();
  const scanBoardingPass = useScanBoardingPass();
  const askForPiggyPrepaid = useAskForPrepaid();

  return useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      await scanBoardingPass();
      const ageConfirmed = await confirmAgeByEmployee();
      if (ageConfirmed !== AgeCheckResult.Accepted) {
        return;
      }
      await handleBetweenOrder();
      await askForReceipt();
      await askForTip();
      await askForPiggyPrepaid();

      const shoppingCartConfirmation = await checkShoppingCart();
      if (shoppingCartConfirmation === true || shoppingCartConfirmation.response === "APPROVED") {
        sendOrder();
      }
    },
    [
      askForPiggyPrepaid,
      askForReceipt,
      askForTip,
      checkShoppingCart,
      confirmAgeByEmployee,
      handleBetweenOrder,
      scanBoardingPass,
      sendOrder,
    ]
  );
}
