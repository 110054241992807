import { Chip, TooltipProps } from "@mui/material";
import Box from "@mui/material/Box";
import Image from "../../global/components/Image";
import MenuTag, { getMenuTagTranslatedName } from "../../global/utils/models/menu/MenuTag";
import { allergensDictionary } from "../../global/utils/api/parseApis/useParseApiAllergen";
import JTooltip from "../../kiosk/components/JamezzMUI/JTooltip";
import { useAppSelector } from "../../global/utils/redux/store";
import "../../assets/pulseAllergenAnimation.css";
import { selectAppLanguage } from "../../kiosk/components/LanguageSelector/useLanguage.ts";

interface Props {
  menuTag: MenuTag;
  width?: number;
  margin?: number | string;
  clickable?: boolean;
  placement?: TooltipProps["placement"];
}

export default function MenuTagView(props: Props) {
  const menuTag = props.menuTag;
  const selectedLanguage = useAppSelector(selectAppLanguage);

  const size = props.width ?? 30;
  const margin = props.margin ?? 0.1;
  const clickable = props.clickable ?? true;

  const menuTagPreferences = useAppSelector((state) => state.menuState.menuTagPreferences);
  const isFiltered: boolean = menuTagPreferences[menuTag.id]?.checked ?? false;

  const Comp = menuTag.image ? allergensDictionary[menuTag.image]?.image : undefined;

  return menuTag.mediaUrl || menuTag.image ? (
    <JTooltip
      title={getMenuTagTranslatedName(menuTag, selectedLanguage)}
      className={"JS-ArticleDialog-Tag-Tooltip"}
      placement={props.placement}
      onClick={(e) => {
        if (clickable) {
          e.stopPropagation();
        }
      }}
    >
      <Box
        className="JS-MenuTag-Allergens"
        sx={{
          margin,
          "& .JS-MenuTag-Allergen": {
            animation: isFiltered ? "0.9s pulseAllergen 500ms 2 alternate" : undefined,
            color: isFiltered ? "red !important" : undefined,
          },
        }}
      >
        {Comp ? (
          <Comp
            className={"JS-MenuTag-Allergen JS-MenuTag-Allergen-" + menuTag.id}
            propsStyle={{ width: size, objectFit: "contain" }}
          />
        ) : menuTag.mediaUrl ? (
          <Image
            style={{ width: size, objectFit: "contain" }}
            srcSet={menuTag.mediaUrl.conversions?.responsive?.srcset}
            className={"JS-MenuTag-Allergen JS-MenuTag-Allergen-" + menuTag.id}
          />
        ) : null}
      </Box>
    </JTooltip>
  ) : (
    <Chip
      key={menuTag.id}
      size={"small"}
      sx={{ margin, backgroundColor: "error.main", color: "error.contrastText" }}
      className={"JS-ArticleDialog-Tag"}
      label={getMenuTagTranslatedName(menuTag, selectedLanguage)}
    />
  );
}
