import { PiggyVoucher } from "../../../../types/shared/piggy.ts";
import store, { useAppSelector } from "../../../../global/utils/redux/store.tsx";

import useCurrency, { CurrencyLocation } from "../../../../global/utils/useCurrency.tsx";
import Box from "@mui/material/Box";
import { ButtonBase, Fade, Stack, Typography } from "@mui/material";
import PaperRip from "./PaperRip.tsx";
import Checkmark from "./Checkmark.tsx";
import { Lock } from "@mui/icons-material";
import { format } from "date-fns";

import Barcode from "../../../../assets/images/Barcode.svg?react";
import PercentageDiscount from "../../../../assets/images/PercentageDiscount.svg?react";
import JamezzLogo from "../../../../assets/images/JamezzLogo.svg";
import Image from "../../../../global/components/Image.tsx";
import usePiggyVoucher from "../../../../global/utils/piggy/usePiggyVoucher.ts";
import useVouchers from "../../../../global/utils/useVouchers.ts";
import { selectVouchersToBeRedeemedMap } from "../../../../global/utils/vouchersV2/selectors/selectVouchersToBeRedeemedMap.ts";
import { voucherV2Removed } from "../../../../global/utils/vouchersV2/vouchersV2Slice.tsx";
import useConfirmationDialog from "../../useConfirmationDialog/useConfirmationDialog.tsx";

export default function PiggyVoucherItem({ item }: { item: PiggyVoucher }) {
  const vouchersV2 = useAppSelector(selectVouchersToBeRedeemedMap);
  const voucherIsInShoppingCart = vouchersV2[item.code] != null;
  const isRedeemed = item.status === "REDEEMED";
  const isLocked = item.status === "LOCKED";
  const isExpired = item.status === "EXPIRED";
  const { validDate } = usePiggyVoucher(item);
  const confirmationDialog = useConfirmationDialog();

  const toCurrency = useCurrency({ location: CurrencyLocation.PiggyVoucher });
  const logoImage = useAppSelector(
    (state) => state.global.salesarea?.media_urls?.logoImage?.[0]?.conversions?.optimized?.url
  );
  const { checkVoucherCode } = useVouchers({ withLoading: true });
  return (
    <Box
      sx={{
        width: 400,
        height: 200,
        backgroundColor: "primary.main",
        borderRadius: 2,
        overflow: "hidden",
        boxShadow: "0 1px 10px 0px rgba(0,0,0,0.6)",
      }}
    >
      <ButtonBase
        sx={{ height: 1, width: 1 }}
        disabled={isRedeemed || isLocked || isExpired}
        onClick={() => {
          if (voucherIsInShoppingCart) {
            confirmationDialog({
              body: "Are you sure you do not want to use this voucher?",
              actions: [
                {
                  id: "Yes",
                  action: () => {
                    store.dispatch(voucherV2Removed(vouchersV2[item.code]));
                  },
                },
                { id: "Cancel" },
              ],
            });
          } else {
            checkVoucherCode(item.code);
          }

          // if (voucherIsInShoppingCart) {
          // store.dispatch(piggyVoucherRemoved(item));
          // } else {
          //   if (validDate == null || isBefore(validDate, getNow())) {
          //     // store.dispatch(piggyVoucherAdded(item));
          //   } else {
          //     toast.info(
          //       "This voucher is valid from: " +
          //         validDate.toLocaleString("en-US", {
          //           timeZone: store.getState().global.salesarea.timezone ?? "Europe/Amsterdam",
          //         }),
          //       {
          //         toastId: "voucher-valid-from-date",
          //       }
          //     );
          //   }
          // }
        }}
      >
        <Box sx={{ display: "flex", width: 1, height: 1, position: "relative" }}>
          {isRedeemed ? <PaperRip isUsed={true} /> : null}
          {isExpired ? <PaperRip isExpired={true} /> : null}
          <Fade in={voucherIsInShoppingCart} unmountOnExit>
            <Box>
              <Checkmark scale={6} />
            </Box>
          </Fade>
          <Fade in={isLocked} unmountOnExit>
            <Box>
              <Box sx={{ position: "absolute", width: 1, height: 1, background: "#00000050" }}></Box>
              <Box sx={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%,-50%)" }}>
                <Lock />
              </Box>
            </Box>
          </Fade>

          <Stack sx={{ textAlign: "right", flex: "1 1 0%", mx: 2, width: "calc(100% - 32px)" }}>
            <Box sx={{ textAlign: "left", display: "flex", alignItems: "center", gap: 1, width: 1, maxHeight: 0.33 }}>
              <Image
                src={logoImage ?? JamezzLogo}
                style={{ flex: "1 1 0%", width: 0, maxHeight: "100%", objectFit: "contain", objectPosition: "left" }}
              />
              <PercentageDiscount style={{ width: 30, color: "white" }} />
              <Typography sx={{ color: "white", fontSize: 18 }}>Voucher</Typography>
            </Box>
            <Typography sx={{ fontWeight: 800, fontSize: 28, color: "white", flex: 1 }}>
              {item.promotion.name}
            </Typography>
            <Box sx={{ textAlign: "left", display: "flex", alignItems: "center", gap: 1 }}>
              {validDate ? (
                <Typography sx={{ color: "white", fontSize: 18 }}>Valid {format(validDate, "dd-MMM-yyyy")}</Typography>
              ) : null}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ fontWeight: 800, fontSize: 22, color: "white" }}>
                *****{item.code.substring(5)}
              </Typography>
              {/*<Typography sx={{ fontWeight: 800, fontSize: 22, color: "white" }}>*/}
              {/*  {toCurrency(item.attributes.max_value / 100)}*/}
              {/*</Typography>*/}
            </Box>
          </Stack>
          <Box sx={{ background: "white", padding: 1, height: 1 }}>
            <Barcode style={{ width: 58 }} />
          </Box>
        </Box>
      </ButtonBase>
    </Box>
  );
}
