import { ReactNode } from "react";
import { useDialog } from "../../global/utils/dialog/DialogProvider.tsx";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { OrderPageAction } from "../pages/OrderPage/OrderPageAction.tsx";
import FormattedMessageJamezz from "../../global/components/FormattedMessageJamezz.tsx";

export function AreYouSureStartOverDialog(props: {
  message: ReactNode;
  onOk: () => void;
  CancelText?: ReactNode;
  OkText?: ReactNode;
}) {
  const { closeDialog } = useDialog();
  return (
    <Box sx={{ padding: 8 }}>
      <Typography sx={{ textAlign: "center" }}>{props.message}</Typography>

      <Box sx={{ width: "100%", mt: 4 }}>
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 4 }}>
          <OrderPageAction
            data-cy={"are-you-sure-dialog-ok-btn"}
            onClick={() => {
              props.onOk();
              closeDialog();
            }}
          >
            {props.CancelText ?? <FormattedMessageJamezz id="Cancel" />}
          </OrderPageAction>
          <OrderPageAction
            onClick={async () => {
              closeDialog();
            }}
          >
            {props.OkText ?? <FormattedMessageJamezz id={"Ok"} />}
          </OrderPageAction>
        </Box>
      </Box>
    </Box>
  );
}
