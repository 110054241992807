import { useDialog } from "../dialog/DialogProvider";
import { useCallback } from "react";
import store from "../redux/store";
import checkOutstandingBalance from "../api/scanAndPay/checkOutstandingBalance";
import { setAfterPayData } from "../redux/shoppingCartSlice";
import PayAfterOrderDialog from "../../../qr/components/PayAfterOrderDialog";
import SlideUpTransition from "../../components/SlideUpTransition";
import useIsWidescreen from "../useIsWidescreen";
import { BlockUserInput } from "@jamezz/react-components";

export default function useAfterPay() {
  const { openDialog } = useDialog();
  const isWidescreen = useIsWidescreen();
  const { blockingStarted, blockingStopped } = BlockUserInput.useBlockUserInput();
  return useCallback(() => {
    blockingStarted();
    checkOutstandingBalance()
      .then((response) => {
        store.dispatch(setAfterPayData(response));

        if (response) {
          openDialog({
            children: <PayAfterOrderDialog />,
            fullScreen: !isWidescreen,
            TransitionComponent: SlideUpTransition,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => blockingStopped());
  }, [openDialog, isWidescreen, blockingStarted, blockingStopped]);
}
