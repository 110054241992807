import { Box, Typography } from "@mui/material";
// @ts-ignore
import Header from "../../../assets/images/fake/header.png";
import { useAppSelector } from "../../../global/utils/redux/store";
import TableIsNotOpenMessage from "../../../global/components/CheckTableOpen/TableIsNotOpenMessage";
import ArrangementsOverview from "../../components/Arrangements/ArrangementsOverview";
import WaitingTimeView from "./Components/WaitingTimeView.tsx";
import BackgroundMediaUrlFromSalesarea from "../../../kiosk/hooks/useBackgroundMediaUrl.tsx";

export default function MenuHeader() {
  const tableName = useAppSelector((state) => state.global.sessionState?.tableName);
  const useArrangements = useAppSelector((state) => state.global.salesarea.use_arrangements);
  const pincode = useAppSelector((state) => state.arrangements.ticket_pincode.pincode);
  return (
    <>
      <BackgroundMediaUrlFromSalesarea
        keyName={"extra_settings__qrv5_menu_header"}
        className={"JS-MenuHeader-Root"}
        sx={{
          marginTop: -2,
          zIndex: 1,
          width: 1,
          height: 200,
          display: "flex",
          alignItems: "flex-end",
          padding: 2,
          position: "relative",
        }}
        sxWrapper={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "unset",
        }}
        fallbackBackground={`url("${Header}")`}
      >
        <Box
          sx={{
            display: "grid",
            width: 1,
            height: 1,
            gridTemplate: `
            ". b" 1px
            "c d"
            / 2fr 1fr
            `,
            paddingTop: 1,
          }}
        >
          {useArrangements ? <ArrangementsOverview sx={{ gridArea: "c", alignSelf: "end" }} /> : null}
          <Box sx={{ gridArea: "b", justifySelf: "end" }}>
            <WaitingTimeView />
          </Box>
          <Box sx={{ gridArea: "d", justifySelf: "end", alignSelf: "end" }}>
            <Typography
              sx={{
                textAlign: "right",
                fontStyle: "bold",
                fontWeight: 800,
                fontSize: "10pt",
              }}
              className={"JS-MenuHeader-TableText"}
            >
              {tableName}
            </Typography>
            {pincode ? (
              <Typography
                sx={{
                  textAlign: "right",
                  fontStyle: "bold",
                  fontWeight: 800,
                  fontSize: "10pt",
                }}
                className={"JS-MenuHeader-TableText"}
              >
                (Pin) {pincode}
              </Typography>
            ) : null}
          </Box>
        </Box>
      </BackgroundMediaUrlFromSalesarea>

      <TableIsNotOpenMessage />
    </>
  );
}
