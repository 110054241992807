import { Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import OrderOptionGroup, {
  countOrderArticles,
  isOrderOptionGroupValid,
} from "../../../global/utils/models/order/OrderOptionGroup";
import Box from "@mui/material/Box";
import OptionGroupContent from "./OptionGroups/OptionGroupContent";
import { orderArticlesPushedByUser } from "../../../global/utils/redux/shoppingCartSlice";
import OrderArticle from "../../../global/utils/models/order/OrderArticle";
import store, { useAppDispatch } from "../../../global/utils/redux/store";

import { ButtonBase, Chip, Collapse, Divider, Fade, Typography } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import useAddOrderArticleToShoppingCart, {
  checkStockAndMaxCount,
} from "../../../global/utils/order/useAddOrderArticleToShoppingCart";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import NextButton from "../../components/NextButton";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { optionGroupType, Type } from "../../../global/utils/orderSelector/useOptionGroup";
import { OptionTranslationsContext } from "../../../global/utils/translations/useOptionTranslations";
import _ from "lodash";
import { Swiper as SwiperType } from "swiper/types";
import useSalesareaType, { SalesareaType } from "../../../global/utils/hooks/useSalesareaType";
import { useMaxAlcoholicItemsCheck } from "../../../global/components/MaxAlcoholicItems/MaxAlcoholicItems";
import { AgeCheckResult } from "../../../global/utils/ageCheck/ageCheckUtils";
import { OrderArticleContext } from "../../../global/utils/contexts/OrderArticleContext.ts";
import { getPropertyOfOptionGroup } from "../../../global/utils/models/menu/OptionGroup.ts";

export function shouldAutoGotoNextOptionPage(orderOptionGroup: OrderOptionGroup, orderArticle: OrderArticle) {
  const radio = optionGroupType(orderOptionGroup, orderArticle.article) === Type.Radio;
  const optional =
    optionGroupType(orderOptionGroup, orderArticle.article) === Type.Optional &&
    getPropertyOfOptionGroup(orderOptionGroup.optionGroup, orderArticle.article, "minCount") === 0 &&
    getPropertyOfOptionGroup(orderOptionGroup.optionGroup, orderArticle.article, "maxCount") === 1;

  const maxCountReached =
    getPropertyOfOptionGroup(orderOptionGroup.optionGroup, orderArticle.article, "maxCount") > 0 &&
    getPropertyOfOptionGroup(orderOptionGroup.optionGroup, orderArticle.article, "maxCount") ===
      countOrderArticles(orderOptionGroup);

  return (radio || optional || maxCountReached) && countOrderArticles(orderOptionGroup) > 0;
}

interface BaseProps {
  orderArticle: OrderArticle;
  modifyingOrderArticle?: OrderArticle;
  compactLayout?: boolean;
  optionOrderGroupsPageIndex: number;
  setOptionOprderGroupsPageIndex: Dispatch<SetStateAction<number>>;
}

interface Props extends BaseProps {
  compactLayout?: false;
  fixedCount?: number;
  onOrderArticleOk?: (orderArticle: OrderArticle) => void;
}

interface PropsCompactLayout extends BaseProps {
  compactLayout: true;
  price: ReactNode;
  fixedCount?: number;
  onOrderArticleOk?: (orderArticle: OrderArticle) => void;
}

export default function OptionOrderGroups(props: Props | PropsCompactLayout) {
  const dispatch = useAppDispatch();
  const context = useContext(OrderArticleContext);
  const setOrderArticle = context?.setOrderArticle;

  const { closeDialog } = useDialog();
  const [swiperRef, setSwiperRef] = useState<SwiperType | null>(null);
  const [selectedOptionOrdergroup, setSelectedOptionOrdergroup] = useState(0);
  const addOrderArticleToShoppingCart = useAddOrderArticleToShoppingCart();
  const salesareaType = useSalesareaType();
  const checkMaxAlcoholicItems = useMaxAlcoholicItemsCheck();
  const { setOptionOprderGroupsPageIndex } = props;

  useEffect(() => {
    const handler = () => {
      if (swiperRef) {
        setOptionOprderGroupsPageIndex(swiperRef.activeIndex);
      }
    };

    if (swiperRef) {
      swiperRef.on("activeIndexChange", handler);
    }

    return () => {
      if (swiperRef) {
        swiperRef.off("activeIndexChange", handler);
      }
    };
  }, [setOptionOprderGroupsPageIndex, swiperRef]);

  const filteredOrderOptionGroups = useMemo(() => {
    return props.orderArticle.orderOptionGroups.filter((optionOrderGroup) => !optionOrderGroup.optionGroup.skip);
  }, [props.orderArticle.orderOptionGroups]);
  const isLastOptionGroup = Math.max(0, filteredOrderOptionGroups.length - 1) === selectedOptionOrdergroup;
  const [showMakeYourChoiceChip, setShowMakeYourChoiceChip] = useState(false);
  const orderArticle = props.orderArticle;
  const isValidCurrentOptionGroup = useMemo(() => {
    if (!filteredOrderOptionGroups[selectedOptionOrdergroup]) {
      return true;
    }

    return isOrderOptionGroupValid(filteredOrderOptionGroups[selectedOptionOrdergroup], orderArticle.article);
  }, [filteredOrderOptionGroups, selectedOptionOrdergroup, orderArticle]);

  const canOrder = useMemo(() => {
    return orderArticle.article.canOrder && isValidCurrentOptionGroup;
  }, [orderArticle, isValidCurrentOptionGroup]);

  useEffect(() => {
    setShowMakeYourChoiceChip(false);
  }, [selectedOptionOrdergroup]);

  const orderOptionGroupViews = useMemo(() => {
    return filteredOrderOptionGroups.map((orderOptionGroup, index) => {
      return (
        <OptionTranslationsContext.Provider
          value={orderOptionGroup.optionGroup.translations ?? props.orderArticle.article.translations}
        >
          <OptionGroupContent
            orderArticle={props.orderArticle}
            orderOptionGroup={orderOptionGroup}
            key={orderOptionGroup.id}
            index={index}
            onChange={(orderOptionGroup) => {
              const copy = _.cloneDeep(props.orderArticle);
              const index = _.findIndex(copy.orderOptionGroups, (subject) => subject.id == orderOptionGroup.id);
              copy.orderOptionGroups[index] = orderOptionGroup;
              setOrderArticle?.(copy);
              //  addToCartAnalytics(copy);
              if (shouldAutoGotoNextOptionPage(orderOptionGroup, props.orderArticle) && !isLastOptionGroup) {
                swiperRef?.slideNext();
                setSelectedOptionOrdergroup((v) => v + 1);
              }
            }}
          />
        </OptionTranslationsContext.Provider>
      );
    });
  }, [filteredOrderOptionGroups, props.orderArticle, setOrderArticle, isLastOptionGroup, swiperRef]);

  return (
    <Box sx={{ width: 1, display: "flex", flexDirection: "column", height: 1 }}>
      <Box sx={{ width: 1, flex: "1 1 0%", height: 0, textAlign: "center", display: "flex", flexDirection: "column" }}>
        <Collapse in={showMakeYourChoiceChip}>
          <Chip
            sx={{
              backgroundColor: "error.light",
              padding: 3,
              margin: 1,
              fontWeight: 800,
              color: "error.contrastText",
            }}
            label={<FormattedMessageJamezz id={"Please, make a choice!"} />}
          />
        </Collapse>
        <Swiper
          onSwiper={setSwiperRef}
          tabIndex={selectedOptionOrdergroup}
          allowTouchMove={false}
          style={{ height: "0", flex: "1 1 0%", overflow: "hidden", width: "100%" }}
          className="JS-OrderArticleSelectorPage-OptionGroupContainer"
        >
          {orderOptionGroupViews
            .filter((view) => view)
            .map((view, index) => {
              return (
                <SwiperSlide key={selectedOptionOrdergroup + "-" + index}>
                  {Math.abs(selectedOptionOrdergroup - index) <= 1 ? view : null}
                </SwiperSlide>
              );
            })}
        </Swiper>
      </Box>

      {salesareaType === SalesareaType.CATALOG ? (
        <Box
          className={"JS-OptionOrderGroups-AddWrapper"}
          sx={{
            display: "flex",
            width: 1,
            justifyContent: "center",
            alignItems: "center",
            marginY: 3,
          }}
        >
          <ButtonBase
            className={"JS-OptionOrderGroups-BackButton"}
            data-cy="page-order-content-btn-back-btn"
            sx={{
              borderRadius: 16,
              textTransform: "uppercase",
              backgroundColor: "primary.light",
              opacity: canOrder ? undefined : 0.6,
              fontSize: 42,
              width: 614,
              padding: 3,
              color: "white",
              fontWeight: 800,
              boxShadow: "0 1px 10px 0px rgba(0,0,0,0.6)",
            }}
            onClick={() => {
              closeDialog();
            }}
          >
            <FormattedMessageJamezz id={"Back"} />
          </ButtonBase>
        </Box>
      ) : (
        <Collapse in={isLastOptionGroup}>
          <Divider />
          {store.getState().global.salesarea?.custom_data?.kioskv5?.show_warning_options_belong_to_every_unit ? (
            <Collapse in={Boolean(orderArticle.count > 1 && filteredOrderOptionGroups.length > 0)}>
              <Typography
                sx={{ background: "red", borderRadius: 10, padding: 4, margin: 4, fontSize: "0.8em" }}
                className={"JS-OrderArticleSelectorPage-Warning"}
              >
                <FormattedMessageJamezz
                  id={
                    "Are you sure you want the selected options by {articleName}? If not, add 1 to your order, open {articleName} again and make other choices."
                  }
                  values={{ articleName: orderArticle.article.name }}
                />
              </Typography>
            </Collapse>
          ) : null}
          {props.fixedCount == null ? (
            <Box
              sx={{
                display: "flex",
                width: 1,
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
                marginBottom: 3,
                paddingX: 4,
              }}
              className={"JS-OptionOrderGroups-ButtonsWrapper"}
            >
              <ButtonBase
                className={"JS-OptionOrderGroups-MinusButton"}
                sx={{
                  width: 150,
                  padding: 3,
                  backgroundColor: "primary.light",
                  borderRadius: 16,
                  fontSize: 42,
                  color: "white",
                  textTransform: "uppercase",
                  fontWeight: 800,
                  opacity: !(orderArticle.count > (props.modifyingOrderArticle ? 0 : 1)) ? 0.6 : 1,
                  boxShadow: "0 1px 10px 0px rgba(0,0,0,0.6)",
                }}
                disabled={!(orderArticle.count > (props.modifyingOrderArticle ? 0 : 1))}
                onClick={() => {
                  setOrderArticle?.({ ...orderArticle, count: orderArticle.count - 1 });
                }}
              >
                <Remove />
              </ButtonBase>
              <Typography
                className={"JS-OptionOrderGroups-Count"}
                sx={{
                  textAlign: "center",
                  marginX: 3,
                  width: 75,
                }}
              >
                {orderArticle.count}
              </Typography>
              <ButtonBase
                className={"JS-OptionOrderGroups-PlusButton"}
                sx={{
                  width: 150,
                  padding: 3,
                  backgroundColor: "primary.light",
                  borderRadius: 16,
                  fontSize: 42,
                  color: "white",
                  textTransform: "uppercase",
                  fontWeight: 800,
                  boxShadow: "0 1px 10px 0px rgba(0,0,0,0.6)",
                }}
                onClick={async () => {
                  const orderArticles = store.getState().shoppingCart.items;
                  if (!checkStockAndMaxCount({ ...orderArticle, count: orderArticle.count + 1 }, orderArticles)) {
                    return;
                  }

                  const result = await checkMaxAlcoholicItems(
                    { ...orderArticle, count: orderArticle.count + 1 },
                    props.modifyingOrderArticle
                  );

                  if (result == AgeCheckResult.Accepted) {
                    setOrderArticle?.({ ...orderArticle, count: orderArticle.count + 1 });
                  }
                }}
              >
                <Add />
              </ButtonBase>
            </Box>
          ) : null}
          <Box
            className={"JS-OptionOrderGroups-AddWrapper"}
            sx={{
              display: "flex",
              width: 1,
              justifyContent: "center",
              alignItems: "center",
              marginY: 3,
            }}
          ></Box>
        </Collapse>
      )}

      {filteredOrderOptionGroups.length > 2 ? (
        <Box sx={{ zIndex: 1, textAlign: "center", marginTop: 1 }}>
          {filteredOrderOptionGroups.map((orderOptionGroup, idx) => {
            return (
              <Box
                key={orderOptionGroup.id}
                component="span"
                className={`swiper-pagination-bullet${
                  props.optionOrderGroupsPageIndex === idx
                    ? " swiper-pagination-bullet-active JS-OptionOrderGroups-Pagination-Bullet--Current"
                    : ""
                } JS-OptionOrderGroups-Pagination-Bullet`}
                sx={(theme) => ({
                  margin: "0 2px",
                  backgroundColor:
                    props.optionOrderGroupsPageIndex === idx ? theme.palette.primary.main : "transparent",
                  width: "30px",
                  height: "30px",
                  lineHeight: "70px",
                  border: `6px solid ${theme.palette.primary.main}`,
                  transition: "all 200ms ease-in",
                })}
              />
            );
          })}
        </Box>
      ) : null}

      <Box
        className={"JS-OptionOrderGroups-NavigationWrapper"}
        sx={{
          width: 1,
          display: "flex",
          justifyContent: "space-between",
          marginTop: 1,
          paddingX: 4,
          alignItems: "center",
          pt: 1,
        }}
      >
        <Fade in={true}>
          <ButtonBase
            className={"JS-OptionOrderGroups-PreviousButton"}
            sx={{
              minWidth: 375,
              padding: 3,
              borderRadius: 16,
              fontSize: 42,
              color: "primary.contrastText",
              backgroundColor: "primary.light",
              textTransform: "uppercase",
              fontWeight: 800,
              boxShadow: "0 1px 10px 0px rgba(0,0,0,0.6)",
            }}
            onClick={() => {
              if (!swiperRef?.animating) {
                if (selectedOptionOrdergroup > 0) {
                  swiperRef?.slidePrev();
                  setSelectedOptionOrdergroup(selectedOptionOrdergroup - 1);
                } else {
                  closeDialog();
                }
              }
            }}
          >
            {selectedOptionOrdergroup > 0 ? (
              <FormattedMessageJamezz id={"Previous"} />
            ) : (
              <FormattedMessageJamezz id={"Close"} />
            )}
          </ButtonBase>
        </Fade>
        {props.compactLayout ? (
          <Typography variant="body2" sx={{ opacity: 0.7, alignSelf: "center", fontStyle: "italic" }}>
            {props.price}
          </Typography>
        ) : null}

        <Box>
          <NextButton
            sx={{
              opacity: isValidCurrentOptionGroup ? 1 : 0.6,
            }}
            isLastOptionGroup={isLastOptionGroup}
            onClick={async () => {
              if (isLastOptionGroup) {
                if (canOrder) {
                  const result = await checkMaxAlcoholicItems(orderArticle, props.modifyingOrderArticle);

                  if (result == AgeCheckResult.Accepted) {
                    if (props.onOrderArticleOk) {
                      props.onOrderArticleOk(_.cloneDeep(orderArticle));
                    } else if (props.modifyingOrderArticle == null) {
                      try {
                        await addOrderArticleToShoppingCart(orderArticle);
                        closeDialog();
                        if (orderArticle.isUpselled) {
                          closeDialog();
                        }
                      } catch (e) {
                        console.log(e);
                      }
                    } else {
                      closeDialog();
                      dispatch(
                        orderArticlesPushedByUser([
                          {
                            ...props.modifyingOrderArticle,
                            count: -props.modifyingOrderArticle.count,
                          },
                          orderArticle,
                        ])
                      );
                    }
                  }
                } else {
                  setShowMakeYourChoiceChip(true);
                }
              } else {
                if (!swiperRef?.animating) {
                  if (isValidCurrentOptionGroup) {
                    swiperRef?.slideNext();
                    setSelectedOptionOrdergroup(selectedOptionOrdergroup + 1);
                  } else {
                    setShowMakeYourChoiceChip(true);
                  }
                }
              }
            }}
            translationId={!isLastOptionGroup ? "Next" : props.modifyingOrderArticle == null ? "Add" : "Change"}
          />
        </Box>
      </Box>
    </Box>
  );
}
