import { StepIconProps } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import Box from "@mui/material/Box";

export function CustomFieldsStepIcon({ className, completed, active, icon }: StepIconProps) {
  if (completed) {
    return (
      <CheckCircle
        className="JS-CustomFields-Step-Completed"
        sx={(theme) => ({
          width: 50,
          height: 50,
          fill: theme.palette.primary.main,
        })}
      />
    );
  }
  return (
    <Box
      component="div"
      className={active ? "JS-CustomFields-Step-Active" : "JS-CustomFields-Step"}
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 50,
        height: 50,
        borderRadius: "50%",
        color: theme.palette.primary.contrastText,
        backgroundColor: active ? theme.palette.primary.main : theme.palette.text.disabled,
      })}
    >
      {icon}
    </Box>
  );
}
