import store, { RootState, useAppSelector } from "../../../global/utils/redux/store.tsx";
import TicketPincodeDialog from "./TicketPincodeDialog.tsx";
import {
  useLazyFetchTableStateQuery,
  useRefreshTableStateMutation,
} from "../../../global/utils/redux/api/arrangementsApi.ts";
import { useCallback, useEffect } from "react";
import { BlockUserInput } from "@jamezz/react-components";
import { CircularProgress, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import { userProvidedValidPincode } from "../../../global/utils/redux/arrangements/arrangementsSlice.tsx";

export default function TicketPincode() {
  const status = useAppSelector((state: RootState) => state.arrangements.ticket_pincode.status);
  const pincode = useAppSelector((state: RootState) => state.arrangements.ticket_pincode.pincode);
  const [fetch] = useLazyFetchTableStateQuery();
  const [refreshTableState] = useRefreshTableStateMutation();

  const { blockingStarted, blockingStopped } = BlockUserInput.useBlockUserInput();

  const onPincodeEntered = useCallback(
    (pincode: string) => {
      return new Promise<boolean>((resolve) => {
        blockingStarted();
        fetch(pincode)
          .then((result) => {
            const pincodeIsCorrect = result?.data?.status == "ok";
            resolve(pincodeIsCorrect);
            if (pincodeIsCorrect) {
              refreshTableState();
              store.dispatch(userProvidedValidPincode(pincode));
            }
          })
          .finally(() => blockingStopped());
      });
    },
    [blockingStarted, blockingStopped, fetch, refreshTableState]
  );

  useEffect(() => {
    let timeout: ReturnType<typeof setInterval> | null = null;
    if (status === "NO_PINCODE_SET_YET") {
      timeout = setInterval(() => {
        refreshTableState();
      }, 10000);
    }
    return () => {
      if (timeout) {
        clearInterval(timeout);
      }
    };
  }, [status, refreshTableState]);

  if (status === "NO_PINCODE_SET_YET") {
    return (
      <Dialog open={true}>
        <DialogTitle>Waiting for pincode being generated</DialogTitle>
        <DialogContent>
          <Stack direction={"row"} justifyContent={"center"} sx={{ width: 1 }}>
            <CircularProgress />
          </Stack>
        </DialogContent>
      </Dialog>
    );
  } else if (status && !pincode) {
    return <TicketPincodeDialog numberOfDigits={4} onPincodeEntered={onPincodeEntered} />;
  } else {
    return null;
  }
}
