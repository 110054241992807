import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, Card, TextField, Typography } from "@mui/material";
import useVouchers from "../../global/utils/useVouchers";
import { useDialog } from "../../global/utils/dialog/DialogProvider";
import FormattedMessageJamezz from "../../global/components/FormattedMessageJamezz";
import { onFocusKeyboard, scannerDisabled, scannerEnabled } from "../../global/utils/redux/globalSlice";
import { useAppDispatch, useAppSelector } from "../../global/utils/redux/store";
import useInputListener from "../../global/utils/useInputListener";
import { QrCodeScanner } from "@mui/icons-material";
import { voucherCodeFromScannedCode } from "../../global/utils/vouchers/Voucher";
import { toast } from "react-toastify";
import { OrderPageAction } from "../pages/OrderPage/OrderPageAction.tsx";

export default function VoucherInput({ children }: { children: ReactNode }) {
  const { openDialog } = useDialog();

  return (
    <OrderPageAction
      data-cy="add-voucher-btn"
      className="JS-Vouchers-VoucherButton"
      style={{ marginBottom: "32px" }}
      onClick={() => {
        openDialog({ children: <VoucherDialog />, style: { marginBottom: "256px" } });
      }}
    >
      {children}
    </OrderPageAction>
  );
}

enum VoucherScanState {
  INITIAL,
  LOADING,
  SUCCEEDED,
  FAILED,
}

function useVoucherScanner() {
  const dispatch = useAppDispatch();
  const [voucherScanState, setVoucherScanState] = useState<VoucherScanState>(VoucherScanState.INITIAL);
  const { checkVoucherCode } = useVouchers();

  useEffect(() => {
    dispatch(scannerDisabled());
    return () => {
      dispatch(scannerEnabled());
    };
  }, [dispatch]);

  const reinitialize = useCallback(() => {
    setVoucherScanState(VoucherScanState.INITIAL);
  }, []);

  useInputListener((code) => {
    if (voucherScanState !== VoucherScanState.LOADING) {
      setVoucherScanState(VoucherScanState.LOADING);
      // QR-codes generated with Jamezz are printed with "QRD|{VoucherCode}"
      // this prefix exists to disambiguate codes scanned during a global scan
      code = voucherCodeFromScannedCode(code);
      checkVoucherCode(code)
        .then((response) => {
          setVoucherScanState(VoucherScanState.SUCCEEDED);
        })
        .catch((err) => {
          if ("result" in err) {
            toast.error(<FormattedMessageJamezz id={err.result.message} />);
          }
          setVoucherScanState(VoucherScanState.FAILED);
        });
    }
  });

  return {
    voucherScanState,
    reinitialize,
  };
}

function TypeVoucher() {
  const [voucherText, setVoucherText] = useState("");
  const { closeDialog } = useDialog();
  const { checkVoucherCode } = useVouchers();
  const dispatch = useAppDispatch();
  const [voucherCheckState, setVoucherCheckState] = useState<VoucherScanState>(VoucherScanState.INITIAL);
  const inputRef = useRef<null | HTMLInputElement>(null);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplate: `
      "title title title"
      "input input input"
      "check check back"
    `,
        gap: 4,
      }}
    >
      <Typography
        variant="h4"
        style={{ marginLeft: "10px", marginRight: "10px", display: "inline", textAlign: "center", gridArea: "title" }}
      >
        <FormattedMessageJamezz id="Vouchers.kiosk-typing.title" />
      </Typography>

      <TextField
        ref={inputRef}
        placeholder="Voucher code"
        style={{ gridArea: "input" }}
        autoFocus
        value={voucherText}
        onChange={(e) => {
          setVoucherText(e.target.value);
        }}
        onFocus={() => {
          dispatch(onFocusKeyboard({ layout: "default", value: voucherText, position: "floating" }));
        }}
        helperText={
          voucherCheckState === VoucherScanState.FAILED ? (
            <Typography color="red">
              <FormattedMessageJamezz id="Vouchers.messages.failed" />
            </Typography>
          ) : null
        }
      />
      <Button variant="contained" onClick={closeDialog} style={{ gridArea: "back" }}>
        <FormattedMessageJamezz id="Close" />
      </Button>
      <Button
        variant="contained"
        style={{ gridArea: "check" }}
        onClick={() => {
          checkVoucherCode(voucherText)
            .then((response) => {
              toast.success(<FormattedMessageJamezz id={"Voucher.messages.added-successfully"} />);
              closeDialog();
            })
            .catch((err) => {
              if ("result" in err) {
                toast.error(<FormattedMessageJamezz id={err.result.message} />);
              } else {
                // fallback error message if server fails to process the request
                toast.error(<FormattedMessageJamezz id="Vouchers.errors.invalid-response" />);
              }
              setVoucherCheckState(VoucherScanState.FAILED);
              //refocus after failure so the keyboard re-opens
              if (inputRef.current) inputRef.current.focus();
            });
        }}
      >
        <FormattedMessageJamezz id="Vouchers.check-voucher" />
      </Button>
    </Box>
  );
}

export function VoucherDialog() {
  const { closeDialog } = useDialog();
  const [typeManually, setTypeManually] = useState(false);
  const scanner_hw_available =
    useAppSelector((state) => state.global.salesarea.custom_data?.scanner_hw_available) ?? true;
  const { voucherScanState } = useVoucherScanner();

  return (
    <Card
      data-cy="scan-voucher-dialog"
      sx={{
        padding: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      {typeManually || !scanner_hw_available ? (
        <TypeVoucher />
      ) : (
        <>
          <Typography
            variant="h4"
            style={{ marginLeft: "10px", marginRight: "10px", display: "inline", textAlign: "center" }}
            data-cy={
              voucherScanState === VoucherScanState.INITIAL
                ? "voucher-scan-state-initial"
                : voucherScanState === VoucherScanState.LOADING
                  ? "voucher-scan-state-loading"
                  : voucherScanState === VoucherScanState.SUCCEEDED
                    ? "voucher-scan-state-succeeded"
                    : voucherScanState === VoucherScanState.FAILED
                      ? "voucher-scan-state-failed"
                      : "voucher-scan-state-impossible"
            }
          >
            {voucherScanState === VoucherScanState.INITIAL ? (
              <FormattedMessageJamezz id="Vouchers.messages.scan-code" />
            ) : voucherScanState === VoucherScanState.LOADING ? (
              <FormattedMessageJamezz id="Vouchers.messages.checking" />
            ) : voucherScanState === VoucherScanState.SUCCEEDED ? (
              <FormattedMessageJamezz id="Vouchers.messages.success" />
            ) : voucherScanState === VoucherScanState.FAILED ? (
              <FormattedMessageJamezz id="Vouchers.messages.failed" />
            ) : null}
          </Typography>
          <QrCodeScanner sx={{ fontSize: "256px" }} />

          <Button
            onClick={() => {
              setTypeManually(true);
            }}
          >
            <FormattedMessageJamezz id="Vouchers.kiosk-typing.type-your-code" />
          </Button>
          <Button variant="contained" sx={{ width: "40%" }} onClick={closeDialog}>
            <FormattedMessageJamezz id="Vouchers.messages.go-back" />
          </Button>
        </>
      )}
    </Card>
  );
}
