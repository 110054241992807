import Article, { getArticleName } from "../../../global/utils/models/menu/Article";
import { Button, ButtonBase, Paper, Typography } from "@mui/material";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import SlideUpTransition from "../../../global/components/SlideUpTransition";
import Image from "../../../global/components/Image";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import useCurrency, { CurrencyLocation } from "../../../global/utils/useCurrency";
import { ComponentOverlay } from "../../../global/components/ComponentOverlay";
import store, { useAppSelector } from "../../../global/utils/redux/store";
import { selectAppLanguage } from "../../components/LanguageSelector/useLanguage.ts";
import { postAnalyticsEvent, postEcomAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics";
import {
  findInvalidOrderOptionGroup,
  initOrderArticle,
  OrderArticleAnalytics,
} from "../../../global/utils/models/order/OrderArticle.ts";
import Box from "@mui/material/Box";
import { selectArticlesMap } from "../../../global/utils/redux/selectors/selectArticlesMap.ts";
import useIsOrderingAllowed from "../../../global/utils/hooks/useIsOrderingAllowed.ts";
import useAddOrderArticleToShoppingCart from "../../../global/utils/order/useAddOrderArticleToShoppingCart.tsx";
import OrderArticleSelectorPage from "../OrderArticleSelectorPage/OrderArticleSelectorPage.tsx";

interface Props extends Pick<OrderArticleAnalytics, "upsellType" | "upsellSource" | "upsellId"> {
  article: Article;
  upsell_text: string;
  upsell_title: string;
  useAsDialog: boolean;
}

export default function ArticleSuggestionItem(props: Props) {
  const { openDialog, closeDialog } = useDialog();
  const lang = useAppSelector(selectAppLanguage);
  const toCurrency = useCurrency({ location: CurrencyLocation.Menu });
  const canOrder = useIsOrderingAllowed(undefined, props.article);
  const addOrderArticleToShoppingCart = useAddOrderArticleToShoppingCart({ allowUpsell: false });

  const submitEComPromotion = () => {
    postAnalyticsEvent({
      category: "ArticleSuggestionItem",
      action: "OrderArticleSelectorPage",
      label: props.article.name,
    });
    postEcomAnalyticsEvent("select_promotion", {
      creative_name: props.upsell_title ?? "n/a",
      creative_slot: props.upsell_title ?? "n/a",
      promotion_id: "UPSELL_" + props.article.id,
      promotion_name: props.upsell_text ?? "n/a",
      items: [
        {
          item_id: props.article.id,
          item_name: props.article.name,
          price: props.article.price,
          quantity: 1,
        },
      ],
    });
  };

  return (
    <ComponentOverlay
      disabled={!props.article.isNotAvailable}
      overlayContent={
        props.article.isOutOfStock ? (
          <Typography variant={"h6"} sx={{ fontWeight: 1000 }} className={"JS-ArticleSuggestionItem-OutOfStock-Text"}>
            <FormattedMessageJamezz id={"OUT OF STOCK"} />
          </Typography>
        ) : (
          <Typography variant={"h6"} sx={{ fontWeight: 1000 }} className={"JS-ArticleSuggestionItem-NotAvailable-Text"}>
            <FormattedMessageJamezz id={"NOT AVAILABLE"} />
          </Typography>
        )
      }
    >
      <ButtonBase
        className={"JS-ArticleSuggestionPage-Root"}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flex: 1,
          margin: 2,
          maxWidth: "calc(50% - 32px)",
        }}
        onClick={() => {
          openDialog({
            children: (
              <OrderArticleSelectorPage
                article={props.article}
                isUpselled={true}
                upsellType={props.upsellType ?? "Product"}
                upsellSource={props.upsellSource}
                upsellId={props.upsellId}
              />
            ),
            fullScreen: "almostFullScreen",
            TransitionComponent: SlideUpTransition,
          });

          submitEComPromotion();
        }}
      >
        <Paper
          sx={{
            width: 1,
            height: 1,
            borderRadius: 6,
            boxShadow: "0 1px 10px 0px rgba(0,0,0,0.2)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 2,
            px: 4,
            py: 2,
          }}
          className={"JS-ArticleSuggestionItem-Paper"}
        >
          <Box
            sx={{
              pt: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {props.article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset ? (
              <Image
                srcSet={props.article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset}
                style={{ width: 220, height: 220, objectFit: "contain" }}
              />
            ) : null}
            <Typography sx={{ marginY: 1, fontWeight: 800 }}>{getArticleName(props.article, lang)}</Typography>
            {props.article.price > 0 || props.article.vaprice > 0 ? (
              <Typography sx={{ fontWeight: 800, marginTop: 1 }} className={"JS-ArticleSuggestionItem-Price"}>
                {toCurrency(props.article.vaprice > 0 ? props.article.vaprice : props.article.price, {
                  name: "ArticleContent-price",
                })}
              </Typography>
            ) : null}
          </Box>
          <Button
            sx={{
              width: 1,
              whiteSpace: "nowrap",
              boxShadow: "0 1px 10px 0px rgba(0,0,0,0.4)",
              fontSize: 36,
            }}
            className={"JS-ArticleSuggestionItem-ViewButton"}
            onClick={(e) => {
              e.stopPropagation();
              const orderArticle = initOrderArticle(selectArticlesMap(store.getState()), props.article, 1);
              if (
                !findInvalidOrderOptionGroup(orderArticle) &&
                canOrder == "ALLOWED" &&
                orderArticle.orderOptionGroups.length === 0
              ) {
                addOrderArticleToShoppingCart(orderArticle).catch((err) => console.log(err));
                closeDialog();
              } else {
                openDialog({
                  children: (
                    <OrderArticleSelectorPage
                      article={props.article}
                      isUpselled={true}
                      upsellType={props.upsellType ?? "Product"}
                      upsellSource={props.upsellSource}
                      upsellId={props.upsellId}
                    />
                  ),
                  fullScreen: "almostFullScreen",
                  TransitionComponent: SlideUpTransition,
                });

                submitEComPromotion();
              }
            }}
          >
            <FormattedMessageJamezz id={"Add"} />
          </Button>
        </Paper>
      </ButtonBase>
    </ComponentOverlay>
  );
}
