import Box from "@mui/material/Box";
import { useRef, useState } from "react";
import { Button, Collapse, IconButton, TextField, Typography } from "@mui/material";
import { useLazyFetchGiftcardQuery } from "../../../../global/utils/redux/api/piggyApi";
import store, { useAppDispatch, useAppSelector } from "../../../../global/utils/redux/store";
import { onFocusKeyboard } from "../../../../global/utils/redux/globalSlice";
import { toast } from "react-toastify";
import { piggyGiftcardAdded, piggyGiftcardRemoved } from "../../../../global/utils/redux/piggySlice";
import useCurrency, { CurrencyLocation } from "../../../../global/utils/useCurrency";
import { useIntl } from "react-intl";
import { Delete, Info } from "@mui/icons-material";
import { TransitionGroup } from "react-transition-group";
import { useAccountTotalValue } from "../../../../global/utils/redux/accountSlice";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz";
import isKiosk from "../../../../global/utils/helpers/isKiosk";
import { useFocusCustomField } from "../../../pages/CheckoutPage/CustomFields/useFocusCustomField";
import JTooltip from "../../../../kiosk/components/JamezzMUI/JTooltip";
import { useCustomTexts } from "../../../../global/utils/useCustomTexts";
import { GiftcardResponse } from "../../../../types/shared/piggy";
import { selectPiggyGiftcardAmount } from "../../../../global/utils/redux/selectors/selectPiggyGiftcardAmount.tsx";
import { BlockUserInput } from "@jamezz/react-components";

export default function PiggyGiftcardCheckout() {
  const [giftcardHash, setGiftcardHash] = useState("");
  const toCurrency = useCurrency({ location: CurrencyLocation.Checkout });
  const [request] = useLazyFetchGiftcardQuery();
  const piggyGiftcards = useAppSelector((state) => state.piggy.giftcards);
  const piggyGiftcardAmount = useAppSelector(selectPiggyGiftcardAmount);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const totalAmount = useAccountTotalValue();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const enabled = useAppSelector((state) => state.global.salesarea.piggy.giftcards.enabled);
  const customFieldName = intl.formatMessage({ id: "Piggy Giftcards" });
  useFocusCustomField(inputRef, customFieldName);
  const ct = useCustomTexts(["loyalty gift card - title"]);
  const { blockingStarted, blockingStopped } = BlockUserInput.useBlockUserInput();
  if (!enabled) {
    return null;
  }
  return (
    <>
      <Box sx={{ mb: 2, border: "0px solid #66666666", borderRadius: 3, width: 1 }}>
        <Box sx={{ display: "inline-flex", justifyContent: "space-between", width: 1 }}>
          <Typography>{ct["loyalty gift card - title"] ?? "Piggy Giftcards"}</Typography>
          <JTooltip
            title={intl.formatMessage({
              id: "The used amount will be subtracted from your giftcard. If the giftcard still has a balance, it can be used at a later moment in time.",
            })}
          >
            <Info />
          </JTooltip>
        </Box>
        <Box sx={{ mt: 1 }}>
          <TransitionGroup style={{ width: "100%" }}>
            {piggyGiftcards.map((giftcard) => {
              return (
                <Collapse key={giftcard.uuid}>
                  <Box
                    key={giftcard.uuid}
                    sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                  >
                    <Typography>{giftcard.hash}</Typography>
                    <Typography>{toCurrency(giftcard.amount_in_cents / 100)}</Typography>
                    <JTooltip
                      title={intl.formatMessage({ id: "PiggyGiftCard.remove" })}
                      arrow
                      onClose={() => {}}
                      onOpen={() => {}}
                      disableInteractive
                    >
                      <IconButton
                        onClick={() => {
                          store.dispatch(piggyGiftcardRemoved(giftcard));
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </JTooltip>
                  </Box>
                </Collapse>
              );
            })}
          </TransitionGroup>
        </Box>
        {piggyGiftcardAmount < totalAmount ? (
          <>
            <Box sx={{ px: 2 }}>
              <TextField
                focused={isKiosk() ? true : undefined}
                sx={{ mb: 2, width: 1 }}
                name={"piggy_giftcard"}
                value={giftcardHash}
                placeholder={intl.formatMessage({ id: "Enter giftcard code" })}
                inputProps={{
                  inputMode: isKiosk() ? "none" : undefined,
                }}
                onChange={(e) => {
                  setGiftcardHash(e.target.value);
                }}
                inputRef={inputRef}
                // inputRef={(ref) => {
                //   // The intention is to only open the keyboard on the first render.
                //   // ref !== null and inputRef1.current is not yet set
                //   if (ref !== inputRef.current && ref && isKiosk()) {
                //     dispatch(onFocusKeyboard({ value: giftcardHash, layout: "default" }));
                //     inputRef.current = ref;
                //   }
                // }}
                onFocus={() => {
                  if (isKiosk()) {
                    dispatch(
                      onFocusKeyboard({
                        value: giftcardHash,
                        layout: "default",
                      })
                    );
                  }
                }}
              />
            </Box>

            <Collapse in={!!giftcardHash}>
              <Box sx={{ width: 1, textAlign: "center", marginBottom: 2 }}>
                <Button
                  variant={giftcardHash ? "contained" : "text"}
                  onClick={() => {
                    if (
                      piggyGiftcards.some((giftcard) => {
                        return giftcard.hash === giftcardHash;
                      })
                    ) {
                      toast.info(intl.formatMessage({ id: "Giftcard already added" }));
                    } else {
                      blockingStarted();
                      request(giftcardHash)
                        .then((response) => {
                          console.log(response);
                          if (response.error) {
                            toast.error(intl.formatMessage({ id: "Giftcard not found" }));
                          } else if (response.data) {
                            setGiftcardHash("");
                            store.dispatch(piggyGiftcardAdded((response.data as GiftcardResponse).data.giftcard));
                          }
                        })
                        .finally(() => {
                          blockingStopped();
                        });
                    }
                  }}
                >
                  <FormattedMessageJamezz id={"Add giftcard"} />
                </Button>
              </Box>
            </Collapse>
          </>
        ) : null}
      </Box>
    </>
  );
}
