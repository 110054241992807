import store from "../../utils/redux/store.tsx";

export function initializeTimApi(
  terminalIpAddress: string,
  terminalId: string,
  ecrPayment: any,
  writeLog: any,
  callbackToServer: any
) {
  window.onTimApiLog = () => {
    // Overwrite standard logging
  };
  function sendCallback(data: any, retryCount = 0) {
    callbackToServer({ payment: JSON.stringify(store.getState().global.order), paymentData: data })
      .then(() => {
        if (window.sentBalance) {
          ecrPayment.terminal.balanceAsync();
        }
      })
      .catch(() => {
        if (retryCount < 10) {
          setTimeout(
            () => {
              sendCallback(data, retryCount + 1);
            },
            (retryCount + 1) * 2000
          );
        }
      });
  }

  // @ts-ignore
  class HandlePaymentState extends timapi.DefaultTerminalListener {
    balanceCompleted(event: any, data: any) {
      super.balanceCompleted(event, data);
      ecrPayment.terminal.logoutAsync();
      // if (jamezzDebugMode) console.warn("balanceCompleted", event, data);
    }

    disconnected(terminal: any, exception: any) {
      super.disconnected(terminal, exception);
      console.log("DISCONNECTED");
      // window.initSpsDone = false;
      // window.initStarting = false;
      // writeLog("disconnected", exception, "warning", 1);
      // if (jamezzDebugMode) console.warn("disconnected", terminal, exception);
      // if (window.jsPaymentCallback !== undefined && window.jsPaymentCallback !== null) {
      //     window.jsPaymentCallback(0, "FAILED");
      // }
    }

    connectCompleted(event: any) {
      super.connectCompleted(event);

      setTimeout(() => {
        // @ts-ignore
        if (ecrPayment.terminal.getTerminalStatus() !== timapi.constants.loginCompleted) {
          ecrPayment.terminal.loginAsync();
        }
      }, 1000);
      // writeLog("connectCompleted", event, "ok", 1);
      // if (jamezzDebugMode) console.warn("connectCompleted", event);
    }

    deactivateCompleted(event: any, data: any) {
      super.deactivateCompleted(event, data);
      ecrPayment.terminal.balanceAsync();
      // if (jamezzDebugMode) console.warn("deactivateCompleted", event, data);
    }

    activateCompleted(event: any, data: any) {
      super.activateCompleted(event, data);
      // window.initSpsDone = true;
      // writeLog("activateCompleted", event, "ok", 1);
      // if (jamezzDebugMode) console.warn("activateCompleted", event, data);
    }

    logoutCompleted(event: any) {
      super.logoutCompleted(event);
      ecrPayment.terminal.dispose();
      // if (jamezzDebugMode) console.warn("logoutCompleted", event);
    }

    loginCompleted(event: any) {
      super.loginCompleted(event);
      // @ts-ignore
      if (ecrPayment.terminal.getTerminalStatus() !== timapi.constants.activateCompleted) {
        ecrPayment.terminal.activateAsync();
      }
      // writeLog("loginCompleted", event, "ok", 1);
      // if (jamezzDebugMode) console.warn("loginCompleted", event);
    }

    terminalStatusChanged(terminal: any) {
      super.terminalStatusChanged(terminal);
      // if (jamezzDebugMode) console.warn("terminalStatusChanged", terminal);
    }

    errorNotification(terminal: any, response: any) {
      super.errorNotification(terminal, response);
      // if (!errorLogged) {
      //     writeLog("initTimApi", response, "error", 0);
      //     errorLogged = true;
      // }
      // if (jamezzDebugMode) console.warn("errorNotification", terminal, response);
      // if (window.jsPaymentCallback !== undefined && window.jsPaymentCallback !== null) {
      //     window.jsPaymentCallback(0, "FAILED");
      // }
    }

    keyPressed(terminal: any, reason: any) {
      super.keyPressed(terminal, reason);
    }

    requestCompleted(event: any, data: any) {
      super.requestCompleted(event, data);
    }

    transactionCompleted(event: any, data: any) {
      super.transactionCompleted(event, data);
      // window.transActionActive = false;
      console.log("TRANSACTION COMPLETED");
      if (event.exception) {
        // if (window.jsPaymentCallback !== undefined && window.jsPaymentCallback !== null) {
        writeLog({
          type: "payment",
          data: event.exception.errorMessage ?? "Unknown error",
          result: "failed",
          status: 0,
        });
        sendCallback("paymentFailed");
        // window.jsPaymentCallback(0, event.exception.errorMessage ?? "Unknown error");
        // }
      } else {
        // if (data != undefined && data.transactionInformation != undefined) {
        writeLog({ type: "payment", data: data.transactionInformation, result: "ok", status: 1 });
        sendCallback(data);
        // this.canMakeCallback(data);
        // }
      }
    }
  }

  try {
    // window.initStarting = true;
    // @ts-ignore
    const settings: any = new timapi.TerminalSettings();
    settings.connectionIPString = terminalIpAddress;
    settings.connectionIPPort = 80;
    // @ts-ignore
    settings.connectionMode = timapi.constants.ConnectionMode.broadcast;
    // @ts-ignore
    settings.protocolType = timapi.constants.ProtocolType.sixml;
    settings.terminalId = String(terminalId);
    settings.autoCommit = true;
    settings.allowClosedCardInsert = true;
    settings.fetchBrands = false;
    settings.dcc = false;
    settings.tipAllowed = false;
    settings.integratorId = "3fcd9ce0-0165-44f9-b898-1d2507696169";
    // @ts-ignore
    settings.guides.add(timapi.constants.Guides.unattended);

    // @ts-ignore
    ecrPayment.terminal = new timapi.Terminal(settings);
    // @ts-ignore
    ecrPayment.terminal.setPosId("1");
    // @ts-ignore
    ecrPayment.terminal.setUserId(1);
    // @ts-ignore
    ecrPayment.terminal.addListener(new HandlePaymentState());
    // @ts-ignore
    ecrPayment.terminal.connectAsync();
    console.log("initTimApi", "starting", "tim setup finished", 0);
    // writeLog("initTimApi", "starting", "tim setup finished", 0);

    return ecrPayment;
  } catch (err) {
    console.log(err);
    // writeLog("initTimApi", err.message, "tim setup error", 0);
  }
}
