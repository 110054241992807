import { useState } from "react";
import { useAppSelector } from "../../../global/utils/redux/store";
import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";

import usePriceKeyChecker from "../../../kiosk/utils/usePriceKeyChecker";
import { toast } from "react-toastify";
import CloseDialogButton from "../Dialog/CloseDialogButton.tsx";
import { postAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics";
import { Controller, FormProvider, useForm } from "react-hook-form";
import isQr from "../../../global/utils/helpers/isQr";
import { BlockUserInput } from "@jamezz/react-components";

export default function AccountDiscount() {
  const useAccountServer = useAppSelector((state) => state.global.salesarea.useAccountServer);
  const useAccountServer_input = useAppSelector((state) => state.global.salesarea.useAccountServer_input);
  const discountCardNr = useAppSelector((state) => state.shoppingCart.discountCardNr);
  const [openDialog, setOpenDialog] = useState(false);

  if (useAccountServer && useAccountServer_input && !discountCardNr) {
    return (
      <Box sx={{ marginTop: 2, width: 1 }}>
        <Button
          size={isQr() ? "small" : "large"}
          variant={"contained"}
          sx={{ width: 1 }}
          onClick={() => {
            postAnalyticsEvent({
              category: "AccountDiscount",
              action: "Open discount card dialog",
            });
            setOpenDialog(true);
          }}
        >
          <FormattedMessageJamezz id={"DISCOUNT CARD"} />
        </Button>

        <AccountDiscountForm open={openDialog} setOpen={setOpenDialog} />
      </Box>
    );
  } else {
    return null;
  }
}

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function AccountDiscountForm(props: Props) {
  const priceKeyCheck = usePriceKeyChecker();
  const { blockingStarted, blockingStopped } = BlockUserInput.useBlockUserInput();
  const useFormMethods = useForm({
    defaultValues: {
      identifier: "",
      name: "",
    },
  });
  const { getValues, trigger, control } = useFormMethods;

  return (
    <Dialog
      className="JS-AccountDiscount-Dialog-Root"
      open={props.open}
      keepMounted={false}
      onClose={() => {
        props.setOpen(false);
      }}
    >
      <Box sx={{ w: 1 }}>
        <DialogTitle sx={{ marginRight: 5, textAlign: "center" }} className="JS-AccountDiscount-Dialog-Title">
          <FormattedMessageJamezz id={"Login"} />
        </DialogTitle>
      </Box>
      <CloseDialogButton
        onClose={() => {
          postAnalyticsEvent({
            category: "AccountDiscountForm",
            action: "CloseDialogButton",
          });
          props.setOpen(false);
        }}
      />
      <DialogContent>
        <FormProvider {...useFormMethods}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              trigger().then((isValid) => {
                if (isValid) {
                  blockingStarted();
                  const { identifier, name } = getValues();
                  priceKeyCheck(identifier, name)
                    .then(() => {
                      postAnalyticsEvent({
                        category: "AccountDiscountForm",
                        action: "Login",
                      });
                      toast.success(<FormattedMessageJamezz id="Discounts.messages.discount-applied" />);
                      props.setOpen(false);
                    })
                    .catch(() => {
                      toast.error(<FormattedMessageJamezz id="Discounts.messages.incorrect-code" />);
                    })
                    .finally(() => {
                      blockingStopped();
                    });
                }
              });
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="identifier"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      inputProps={{
                        className: "JS-AccountDiscount-Dialog-Input",
                      }}
                      InputLabelProps={{
                        className: "JS-AccountDiscount-Dialog-InputLabel",
                      }}
                      data-cy="account-discount-field-id"
                      autoFocus
                      {...field}
                      error={Boolean(error)}
                      label="ID"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      inputProps={{
                        className: "JS-AccountDiscount-Dialog-Input",
                      }}
                      InputLabelProps={{
                        className: "JS-AccountDiscount-Dialog-InputLabel",
                      }}
                      {...field}
                      data-cy="account-discount-field-lastname"
                      error={Boolean(error)}
                      label={<FormattedMessageJamezz id="Lastname" />}
                    />
                  )}
                />
              </Grid>
              <Grid item sx={{ display: "grid", gridTemplateColumns: "1fr 2fr", gap: 4, width: "100%" }}>
                <Button
                  className="JS-AccountDiscount-Dialog-Cancel"
                  variant="contained"
                  onClick={() => {
                    postAnalyticsEvent({
                      category: "AccountDiscountForm",
                      action: "Cancel",
                    });
                    props.setOpen(false);
                  }}
                >
                  <FormattedMessageJamezz id="Cancel" />
                </Button>
                <Button variant="contained" type="submit" className="JS-AccountDiscount-Dialog-Login" color="success">
                  <FormattedMessageJamezz id="Login" />
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
