import OrderArticle from "../models/order/OrderArticle";
import store, { useAppDispatch } from "../redux/store";
import { orderArticlesPushedByUser } from "../redux/shoppingCartSlice";
import { useCallback } from "react";
import Rule from "../automation/Rule";
import useUpsellMenuTrigger from "../../../kiosk/utils/useUpsellMenuTrigger";
import { toast } from "react-toastify";
import FormattedMessageJamezz from "../../components/FormattedMessageJamezz";
import useOpenUpsellPage from "../../domains/upsells/hooks/useOpenUpsellPage.tsx";
import ShowArticleDialogAction from "../automation/actions/ShowArticleDialogAction";
import {
  ArrangementCheckResult,
  useArrangementChecks,
} from "../../components/ArrangementLimitations/ArrangementLimitations.tsx";
import { AgeCheckResult } from "../ageCheck/ageCheckUtils.ts";
import { useAgeCheck } from "../../components/AgeCheckDialog.tsx";
import { getArticleById } from "../models/menu/Menu.ts";
import { selectArticlesMap } from "../redux/selectors/selectArticlesMap.ts";
import _ from "lodash";
import useRulesForOrderArticle from "../../domains/upsells/hooks/useRulesForOrderArticle.ts";

export function checkStockAndMaxCount(orderArticle: OrderArticle, orderArticles: OrderArticle[]) {
  if (
    (orderArticle.article.maxCount && orderArticle.article.maxCount > 0) ||
    (orderArticle.article.stock > 0 && store.getState().global.salesarea.useProductStock)
  ) {
    const articleFromStore =
      getArticleById(selectArticlesMap(store.getState()), orderArticle.article.id) ?? orderArticle.article;
    const maxCount = articleFromStore.maxCount ?? articleFromStore.stock;
    let currentCount = 0;
    orderArticles.forEach((item) => {
      if (item.article.id === orderArticle.article.id) {
        currentCount += item.count;
      }
    });

    if (currentCount + orderArticle.count > maxCount) {
      if (articleFromStore.maxCount) {
        toast.error(
          <FormattedMessageJamezz
            id={"You can order maximal {maxCount} of this item."}
            values={{ maxCount: maxCount }}
          />
        );
      } else {
        toast.error(<FormattedMessageJamezz id={"Only {x} left in stock"} values={{ x: articleFromStore.stock }} />, {
          toastId: "x_left_in_stock_" + articleFromStore.id,
        });
      }
      return false;
    }
  }
  return true;
}

export default function useAddOrderArticleToShoppingCart(
  { allowUpsell }: { allowUpsell: boolean } = { allowUpsell: true }
) {
  const dispatch = useAppDispatch();
  const checkArrangements = useArrangementChecks();
  const checkAge = useAgeCheck();

  const { triggerUpsellMenu } = useUpsellMenuTrigger();
  const getRulesForOrderArticle = useRulesForOrderArticle();
  const openUpsellPage = useOpenUpsellPage();

  return useCallback(
    (orderArticle: OrderArticle) => {
      return new Promise<void>((resolve, reject) => {
        (async () => {
          const orderArticles = store.getState().shoppingCart.items;
          if (!checkStockAndMaxCount(orderArticle, orderArticles)) {
            reject("stock_denied");
            return;
          }

          const arrangementRulesCheck = await checkArrangements(orderArticle);
          if (arrangementRulesCheck === ArrangementCheckResult.Accepted) {
            const ageCheckResult = await checkAge(orderArticle);
            if (ageCheckResult === AgeCheckResult.Denied) {
              reject("age_denied");
              return;
            }
          } else {
            reject("arrangement_check_denied");
            return;
          }

          dispatch(orderArticlesPushedByUser([orderArticle]));
          resolve();

          _.defer(() => {
            if (allowUpsell) {
              if (orderArticle.article.upsellMenu) {
                triggerUpsellMenu(orderArticle);
              }
              if (!orderArticle.isUpselled) {
                getRulesForOrderArticle(orderArticle)
                  ?.filter((rule: Rule) => rule.conditions.every((condition) => condition.check()))
                  .forEach((rule: Rule) => {
                    if (rule.action?.discriminator === "ShowArticleDialogAction") {
                      const action = rule.action as ShowArticleDialogAction;
                      openUpsellPage(action, orderArticle.article.id);
                    }
                  });
              }
            }
          });
        })();
      });
    },
    [checkArrangements, dispatch, checkAge, allowUpsell, triggerUpsellMenu, getRulesForOrderArticle, openUpsellPage]
  );
}
