import OpeningHours from "../JamezzHours";
import { jamezzApplicationType } from "../../../../qr/App";
import { ArticlegroupResponse, CustomizableTextResponse, MediaUrl } from "../../redux/api/menuDataApi";

export default interface Articlegroup {
  id: string;
  apiId1?: string;
  name: string;
  alternativeName1?: string;
  showMaxNumberOfItems: number | null;
  orderTimes?: OpeningHours;
  description?: string;
  images: MediaUrl[];
  imageBannerMediaUrl?: MediaUrl;
  translations?: any;
  sortKey: number;
  numberOfColumns: number;
  minWidthPerArticle: number;
  parentId: number | null;
  disableOrdering: boolean;
  isVisibleInJamezz: boolean;
  showInCategoryMenu: boolean;
  settings: any;
  customizable_texts: CustomizableTextResponse[];
  category?: string;
  updatedAt: string;
  blocked: boolean;
}

export function getColumnCount(apiArticlegroup: any) {
  let numberOfColumns = (apiArticlegroup.columnCount !== 0 ? apiArticlegroup.columnCount : 1) ?? 1;

  if (jamezzApplicationType.type === "KIOSK") {
    if (apiArticlegroup.settings) {
      const settings = JSON.parse(apiArticlegroup.settings);
      if (settings.columnCount?.kiosk > 0) {
        numberOfColumns = settings.columnCount?.kiosk;
      }
    }
  } else if (jamezzApplicationType.type === "QR") {
    if (apiArticlegroup.settings) {
      const settings = JSON.parse(apiArticlegroup.settings);
      if (settings.columnCount?.qr > 0) {
        numberOfColumns = settings.columnCount?.qr;
      }
    }
  }
  return Number(numberOfColumns);
}
export function getMinWidthPerArticle(apiArticlegroup: ArticlegroupResponse) {
  let minWidth: number = 300;

  if (jamezzApplicationType.type === "QR") {
    if (apiArticlegroup.settings) {
      const settings = JSON.parse(apiArticlegroup.settings);
      if (settings.columnMinWidth?.qr == null) {
        // Kind of backward compatible
        const columnCount = getColumnCount(apiArticlegroup);
        if (columnCount === 1) {
          minWidth = 300;
        } else if (columnCount === 2) {
          minWidth = 180;
        }
      } else {
        if (settings.columnMinWidth?.qr > 0) {
          minWidth = Number(settings.columnMinWidth?.qr);
        }
      }
    }
  }
  return Math.max(150, Number(minWidth));
}
export function getRowCount(apiArticlegroup: any) {
  let numberOfColumns = 100;

  if (jamezzApplicationType.type === "KIOSK") {
    if (apiArticlegroup.settings) {
      const settings = JSON.parse(apiArticlegroup.settings);
      if (settings.rowCount?.kiosk > 0) {
        numberOfColumns = settings.rowCount?.kiosk;
      }
    }
  } else if (jamezzApplicationType.type === "QR") {
    if (apiArticlegroup.settings) {
      const settings = JSON.parse(apiArticlegroup.settings);
      if (settings.rowCount?.qr > 0) {
        numberOfColumns = settings.rowCount?.qr;
      }
    }
  }
  return Number(numberOfColumns);
}
export function getArticlegroupName(articlegroup: Articlegroup, language: string) {
  if (articlegroup.translations?.[language]?.naam) {
    return articlegroup.translations?.[language]?.naam;
  }

  return articlegroup.alternativeName1 ?? articlegroup.name;
}

export function getCategoryName(articlegroup: Articlegroup, language: string) {
  if (articlegroup.translations?.[language]?.categorie) {
    return articlegroup.translations?.[language]?.categorie;
  }

  return articlegroup.name;
}

export function getArticlegroupDescription(articlegroup: Articlegroup, language: string) {
  if (articlegroup.translations?.[language]?.omschrijving) {
    return articlegroup.translations?.[language]?.omschrijving;
  }

  if (articlegroup.translations?.[language]?.bigdescr) {
    return articlegroup.translations?.[language]?.bigdescr;
  }

  return articlegroup.description;
}
