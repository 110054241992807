import { Collapse, Dialog, DialogContent, DialogTitle, Stack, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import Circle from "./Circle.tsx";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";
import { useCustomTexts } from "../../../global/utils/useCustomTexts.tsx";

export default function TicketPincodeDialog({
  numberOfDigits = 4,
  onPincodeEntered,
}: {
  numberOfDigits?: number;
  onPincodeEntered?: (pincode: string) => Promise<boolean>;
}) {
  const theme = useTheme();
  const [pincode, setPincode] = useState("");
  const [pincodeIsWrong, setPincodeIsWrong] = useState("");

  const inputRefs = useRef<(HTMLInputElement | null)[]>(_.times(numberOfDigits, _.constant(null)));

  const onChange = useCallback(
    (key: string) => {
      if (key == "Backspace") {
        if (pincode.length > 0) {
          setPincode(pincode.substring(0, pincode.length - 1));
          setPincodeIsWrong("");
        }
      } else {
        if (key.length == 1) {
          setPincode((pincode + key).substring(0, numberOfDigits));
          setPincodeIsWrong("");
        }
      }
    },
    [numberOfDigits, pincode]
  );

  useEffect(() => {
    inputRefs.current[pincode.length]?.focus();
  }, [pincode]);

  useEffect(() => {
    if (pincode.length === numberOfDigits) {
      onPincodeEntered?.(pincode).then((result) => {
        if (!result) {
          setPincode("");
          setPincodeIsWrong(new Date().toISOString());
        }
      });
    }
  }, [pincode, onPincodeEntered, numberOfDigits]);

  const ct = useCustomTexts(["use_random_pincodes_enter_pincode_explanation"]);
  return (
    <Dialog
      open={true}
      onKeyDown={(e) => {
        onChange(e.key);
      }}
    >
      <DialogTitle>
        <FormattedMessageJamezz id={"Please enter pincode..."} />
      </DialogTitle>
      <DialogContent sx={{ pb: 4 }}>
        <Typography sx={{ mb: 2 }}>{ct["use_random_pincodes_enter_pincode_explanation"]}</Typography>
        <Collapse in={Boolean(pincodeIsWrong)}>
          <Typography sx={{ mb: 2, color: theme.palette.error.main }}>
            <FormattedMessageJamezz id={"Pincode is invalid. Try again..."} />
          </Typography>
        </Collapse>
        <Stack direction={"row"} gap={1} justifyContent={"center"}>
          {_.times(numberOfDigits).map((index) => (
            <Circle
              key={index}
              index={index}
              isFilled={pincode.charAt(index) != ""}
              inputRefs={inputRefs}
              onFocus={() => {
                inputRefs.current[pincode.length]?.focus();
              }}
            />
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
