import React from "react";
import { CSSProperties } from "@mui/material/styles/createMixins";
import { BoxProps } from "@mui/material";

export default function MilkIcon({
  innerColor = "#fefefe",
  outerColor = "#804330",
  className,
  propsStyle,
}: {
  innerColor?: CSSProperties["color"];
  outerColor?: CSSProperties["color"];
  className?: string;
  propsStyle?: BoxProps["style"];
}) {
  const style = {
    fill: innerColor,
  };

  return (
    <svg viewBox={`0 0 216 216`} className={className} style={{ color: outerColor, ...propsStyle }}>
      <path
        d="M215.84 107.75c0 59.59-48.16 108.11-108.09 108.11C48.17 215.86 0 167.34 0 107.75 0 47.81 48.17 0 107.75 0c59.93 0 108.09 47.81 108.09 107.75z"
        fill={"currentColor"}
      />
      <path
        d="M87.76 73.86c.72 3.92.72 7.49-.71 11.06-6.43 8.92-11.06 21.05-12.84 34.61-1.78-3.57-4.28-8.57-4.64-10.35-5.35-16.41 0-30.34 15.33-36.75.37-.35 1.07-.35 1.78 0 .73.35 1.08.71 1.08 1.43zm12.49-9.64c4.29 0 1.79 16.41 1.42 18.55-1.77 6.07-4.99 9.99-7.84 15.34-3.21 7.14-6.07 14.63-8.55 21.05 0 .36-.72.71-1.44.71s-1.43-.71-1.43-1.06v-.71c.36-5 2.5-12.84 5.71-20.34 2.85-6.78 6.42-10.71 7.5-16.05.71-3.93-1.79-12.85-.73-16.07.38-1.42 3.22-1.42 5.36-1.42zm-3.2 111.68c5.7.71 11.41.71 17.13.71 6.05 0 11.77 0 17.48-.71 3.57-.36 6.41-1.79 8.91-4.64 8.92-11.78 14.63-19.63 14.63-38.89 0-21.41-6.78-40.32-17.13-51.74-4.64-5.71-2.49-18.91 2.15-23.19 1.43-1.42 10.34-3.57 11.05-8.56 0-1.07.37-3.21-1.43-4.99-6.77-7.13-19.63-1.43-27.11 1.07-4.99 1.42-9.99 4.28-14.62 6.07-3.22 1.07-6.06 2.85-9.28 3.21-3.93.35-7.14-.35-9.98-1.08-.36 0-.72-.35-.72-1.06 0-.36 0-.72.36-1.07 1.07-.36 1.78-1.07 2.49-1.07 4.65-2.14 13.57-2.85 18.21-2.85 4.64-.36 6.77-.72 6.05-1.08-.71-.35-2.13-.35-3.92-.35-8.91-.71-26.75.71-29.6 6.07-.36.71-.72 1.78-.72 2.14 0 1.42.36 3.57 1.42 5.7.36 1.43 1.08 2.85 1.78 4.29-16.41 4.99-23.18 15.69-23.54 32.11-.36 4.28.36 6.78 1.42 11.06 1.07 4.99 2.14 7.5 4.28 11.78.73 1.78 5 10.7 7.14 12.85v.71c0 19.26 5.7 27.11 14.63 38.89 2.14 2.83 5.34 4.26 8.92 4.62z"
        fill={innerColor}
      />
    </svg>
  );
}
