import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store.tsx";
import { selectTableState } from "../api/selectTableState.ts";
import { arrangementsApi } from "../api/arrangementsApi.ts";
import { menuDataApi } from "../api/menuDataApi.ts";
import { selectActiveArrangement } from "./selectActiveArrangement.tsx";
import { ArrangementsLoadingState } from "../../../../types/qr/arrangements.ts";

export const selectArrangementLoadingState = createSelector(
  [
    (state: RootState) => state.global.salesarea.use_arrangements,
    selectTableState,
    (state) => arrangementsApi.endpoints.fetchArrangementsSettings.select()(state),
    (state) => menuDataApi.endpoints.fetchMenuData.select()(state),
    selectActiveArrangement,
  ],
  (arrangements_enabled, tableState, arrangementSettings, menuData, activeArrangement) => {
    if (!arrangements_enabled) return ArrangementsLoadingState.ARRANGEMENT_MODULE_INACTIVE;
    if (tableState.isError || arrangementSettings.isError || menuData.isError) {
      return ArrangementsLoadingState.ARRANGEMENT_FATAL_ERROR;
    }
    if (tableState.data == null || arrangementSettings.data == null || menuData.data == null)
      return ArrangementsLoadingState.LOADING;
    if (activeArrangement) return ArrangementsLoadingState.LOADED;
    return ArrangementsLoadingState.ERR_NO_ACTIVE_ARRANGEMENT;
  }
);
