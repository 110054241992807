import CeleryIcon from "../../../components/Allergens/CeleryIcon.tsx";
import EggIcon from "../../../components/Allergens/EggIcon.tsx";
import CrustaceanIcon from "../../../components/Allergens/CrustaceanIcon.tsx";
import GlutenIcon from "../../../components/Allergens/GlutenIcon.tsx";
import FishIcon from "../../../components/Allergens/FishIcon.tsx";
import MilkIcon from "../../../components/Allergens/MilkIcon.tsx";
import LupinIcon from "../../../components/Allergens/LupinIcon.tsx";
import PeanutIcon from "../../../components/Allergens/PeanutIcon.tsx";
import SoyaIcon from "../../../components/Allergens/SoyaIcon.tsx";
import NutsIcon from "../../../components/Allergens/NutsIcon.tsx";
import SesameIcon from "../../../components/Allergens/SesameIcon.tsx";
import SulphiteIcon from "../../../components/Allergens/SulphiteIcon.tsx";
import MolluscIcon from "../../../components/Allergens/MolluscIcon.tsx";
import MustardIcon from "../../../components/Allergens/MustardIcon.tsx";

export const allergensDictionary = {
  ei: {
    label: "Ei",
    image: EggIcon,
  },
  schaal: {
    label: "Schaaldieren",
    image: CrustaceanIcon,
  },
  gluten: {
    label: "Gluten",
    image: GlutenIcon,
  },
  vis: {
    label: "Vis",
    image: FishIcon,
  },
  pinda: {
    label: "Pinda",
    image: PeanutIcon,
  },
  soja: {
    label: "Soja",
    image: SoyaIcon,
  },
  melk: {
    label: "Melk",
    image: MilkIcon,
  },
  noten: {
    label: "Noten",
    image: NutsIcon,
  },
  sesamzaad: {
    label: "Sesamzaad",
    image: SesameIcon,
  },
  zwaveldioxide: {
    label: "Zwaveldioxide",
    image: SulphiteIcon,
  },
  lupine: {
    label: "Lupine",
    image: LupinIcon,
  },
  weekdieren: {
    label: "Weekdieren",
    image: MolluscIcon,
  },
  selderij: {
    label: "Selderij",
    image: CeleryIcon,
  },
  mosterd: {
    label: "Mosterd",
    image: MustardIcon,
  },
};
