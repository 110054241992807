import { useTipping } from "../../../global/utils/useTipping.ts";
import { TipDrawer } from "../../../qr/components/TipDrawer.tsx";
import { useRef, useState } from "react";
import { postAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics.ts";

export default function useTippingAsync(amount: number) {
  const { isAvailable } = useTipping();
  const [tipDrawerOpened, setTipDrawerOpened] = useState(false);

  const resolveMethod = useRef<any>();

  return {
    askForTip: () =>
      new Promise<boolean>((resolve) => {
        resolveMethod.current = resolve;
        if (!isAvailable) {
          resolve(true);
        } else {
          postAnalyticsEvent({
            category: "CheckoutPage",
            action: "setTipDrawerOpened",
          });
          setTipDrawerOpened(true);
        }
      }),
    tipComponent: (
      <TipDrawer
        paymentAmount={amount}
        onTipIsAdded={(tipAmount) => {
          setTipDrawerOpened(false);
          console.log(tipAmount);
          resolveMethod.current();
        }}
        open={tipDrawerOpened}
        onClose={() => {
          setTipDrawerOpened(false);
        }}
      />
    ),
  };
}
