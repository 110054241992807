import { usePiggyUser } from "../../../../utils/piggy/piggy.tsx";
import { useAppSelector } from "../../../../../global/utils/redux/store.tsx";
import { Paper, Stack, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Image from "../../../../../global/components/Image.tsx";
import JamezzLogo from "../../../../../assets/images/JamezzLogo.svg";
import FormattedMessageJamezz from "../../../../../global/components/FormattedMessageJamezz.tsx";
import Birthday from "./Birthday.tsx";
import Points from "./Points.tsx";
import useLoyaltyType from "./useLoyaltyType.tsx";
import { keyframes } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import PrepaidBalance from "./PrepaidBalance.tsx";

export function LoyaltyCard({ isCompactView }: { isCompactView?: boolean } = { isCompactView: false }) {
  const showLoyalty = useAppSelector(
    (state) => state.global.salesarea.piggy.loyalty.enabled && state.global.salesarea.piggy.loyalty.show_loyalty_in_app
  );
  const prepaidEnabled = useAppSelector((state) => state.global.salesarea.piggy.prepaid.enabled);

  const { piggyUser } = usePiggyUser();
  const logoImage = useAppSelector(
    (state) => state.global.salesarea?.media_urls?.logoImage?.[0]?.conversions?.optimized?.url
  );

  const loyaltyTier = useLoyaltyType();

  const {
    palette: { primary },
  } = useTheme();
  const backgroundColor = primary.main;
  const ref = useRef<HTMLDivElement | null>();
  const [width, setWidth] = useState(600);

  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        setWidth(ref.current.getBoundingClientRect().width);
      }
    }, 1000);
  }, []);
  const frames = keyframes`
  0% {
      background-position: 0;
  }
  100% {
      background-position: ${width}px;
  }`;

  return (
    <Paper
      data-cy={"piggy-loyalty-card"}
      id={"PiggyLoyaltyContent-Card"}
      elevation={10}
      ref={(newRef) => {
        ref.current = newRef;
      }}
      sx={{
        width: 1,
        aspectRatio: 1.8,
        p: 2,
        color: "white",
        background: loyaltyTier?.color
          ? "linear-gradient(100deg, " +
            backgroundColor +
            " 20%, " +
            loyaltyTier.color +
            " 40%, " +
            backgroundColor +
            " 60%)"
          : backgroundColor,
        backgroundPosition: 0,
        animation: `${frames} 2s infinite linear`,
        animationFillMode: "forwards",
        borderRadius: 4,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "auto auto auto",
          gridTemplateAreas: `
      'welcome a' 
      'contentA contentB'
      'b points' 
    `,
          height: 1,
        }}
      >
        {piggyUser?.data?.contact?.firstname ? (
          <Stack
            sx={{
              gridColumnStart: 1,
              gridColumnEnd: 3,
              gridRowStart: 1,
              gridRowEnd: isCompactView ? 3 : 2,
              overflow: "hidden",
            }}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            gap={3}
          >
            <Image src={logoImage ?? JamezzLogo} style={{ width: "240px", height: "100%", objectFit: "contain" }} />
          </Stack>
        ) : null}
        <Box sx={{ flex: "1 1 0%", gridArea: "2 / 1 / 3 / 3", alignSelf: "center" }}>
          {isCompactView ? null : (
            <>
              {piggyUser?.data?.contact?.is_birthday ? (
                <Birthday firstname={piggyUser?.data?.contact?.firstname ?? ""} key={piggyUser.timestamp} />
              ) : (
                <Typography sx={{ textAlign: "center" }}>
                  <FormattedMessageJamezz
                    id={"Welcome {firstname}!"}
                    values={{ firstname: piggyUser?.data?.contact?.firstname }}
                  />
                </Typography>
              )}
            </>
          )}
        </Box>
        <Stack sx={{ gridArea: "points", justifySelf: "flex-end", alignSelf: "flex-end" }} gap={1}>
          {prepaidEnabled ? <PrepaidBalance isCompactView={isCompactView} /> : null}
          {showLoyalty ? <Points isCompactView={isCompactView} /> : null}
        </Stack>
        <Box
          sx={{
            gridArea: "b",
            justifySelf: "flex-start",
            alignSelf: "flex-end",
            fontSize: isCompactView ? "1rem" : undefined,
          }}
        >
          {loyaltyTier?.name}
        </Box>
      </Box>
    </Paper>
  );
}
