import { useCallback, useEffect } from "react";
import { useDialog } from "../../../../global/utils/dialog/DialogProvider";
import store, { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { piggyApi } from "../../../../global/utils/redux/api/piggyApi.ts";
import { piggyAuthApi } from "../../../../global/utils/redux/api/piggyAuthApi.ts";
import { Button, DialogContent, Stack, Typography } from "@mui/material";
import { piggyUsePrepaid } from "../../../../global/utils/redux/piggySlice.tsx";
import { selectAccountTotalValue, useAccountTotalValue } from "../../../../global/utils/redux/accountSlice.tsx";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz.tsx";

export default function useAskForPrepaid() {
  const { openDialog } = useDialog();

  return useCallback(() => {
    return new Promise<void>((resolve) => {
      if (!store.getState().global.salesarea?.payDirect) {
        resolve();
        return;
      }
      const contactUuid = piggyAuthApi.endpoints.getLoggedinUser.select()(store.getState() as any).data?.data
        ?.piggy_contact_uuid;
      if (contactUuid) {
        const prepaidBalance = piggyApi.endpoints.fetchPrepaidBalance.select(contactUuid)(store.getState() as any).data
          ?.data?.prepaid_balance;

        const totalAmount = selectAccountTotalValue(store.getState());

        if (totalAmount > 0 && prepaidBalance && prepaidBalance.balance_in_cents > 0) {
          openDialog({
            children: (
              <PrepaidDialog
                contactUuid={contactUuid ?? ""}
                prepaidBalance={prepaidBalance}
                onClose={() => {
                  resolve();
                }}
              />
            ),
            disabledBackdropClick: true,
            sx: {
              ".MuiDialog-paper": {
                borderRadius: 6,
              },
            },
          });
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });
  }, [openDialog]);
}

function PrepaidDialog({
  contactUuid,
  onClose,
  prepaidBalance,
}: {
  contactUuid: string;
  prepaidBalance: { balance: string; balance_in_cents: number };
  onClose: () => void;
}) {
  const { closeDialog } = useDialog();
  const totalAmount = useAccountTotalValue();
  useEffect(() => {
    return () => {
      onClose();
    };
  }, [onClose]);
  return (
    <>
      <DialogContent>
        <Stack direction={"column"} sx={{ mt: 2 }}>
          <Typography>
            <FormattedMessageJamezz id={"Hi, do you want to use your prepaid balance?"} />
          </Typography>
          <Typography sx={{ fontWeight: 800 }}>
            <FormattedMessageJamezz id={"My balance: {balance}"} values={{ balance: prepaidBalance.balance }} />
          </Typography>
        </Stack>
      </DialogContent>

      <Stack direction={"row"} gap={10} sx={{ px: 5, py: 5, width: 1, justifyContent: "center" }}>
        <Button
          onClick={() => {
            closeDialog();
          }}
        >
          <FormattedMessageJamezz id={"No"} />
        </Button>
        <Button
          onClick={() => {
            store.dispatch(
              piggyUsePrepaid({
                contact_uuid: contactUuid,
                using_amount_in_cents: Math.min(prepaidBalance.balance_in_cents, Math.round(totalAmount * 100)),
              })
            );
            closeDialog();
          }}
        >
          <FormattedMessageJamezz id={"Yes"} />
        </Button>
      </Stack>
    </>
  );
}
