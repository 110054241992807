import { useAppSelector } from "../../../global/utils/redux/store";
import { Box, BoxProps, Card, Chip, Skeleton, SxProps, Typography } from "@mui/material";
import { AccessTime, HourglassBottom, HourglassTop, Refresh } from "@mui/icons-material";
import { addSeconds, format } from "date-fns";
import { memo, ReactNode, useEffect, useMemo } from "react";
import { useOrderTimer } from "../../utils/arrangements/useOrderTimer.ts";
import { ArrangementsLoadingState, PreDiningArrangement } from "../../../types/qr/arrangements.ts";
import useAyceTicketTimer from "../../../global/components/ArrangementLimitations/useAyceTicketTimer.tsx";
import { RegularArrangementOverview } from "./RegularArrangementOverview.tsx";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";
import ArrangementReloadButton from "./ArrangementReloadButton.tsx";
import { selectActiveArrangement } from "../../../global/utils/redux/arrangements/selectActiveArrangement.tsx";
import { useRefreshTableStateMutation } from "../../../global/utils/redux/api/arrangementsApi.ts";
import { selectArrangementLoadingState } from "../../../global/utils/redux/arrangements/selectArrangementLoadingState.tsx";

/**
 * Converts the given number of seconds into a formatted string in the format "mm:ss".
 *
 * @param {number} seconds - The number of seconds to convert.
 * @return {string} The formatted time string in the format "mm:ss".
 */
function timeFormatMinuteSeconds(seconds: number): string {
  const helperDate = addSeconds(new Date(0), seconds);
  return format(helperDate, "mm:ss");
}

const ayceInfoItemGridStyle = {
  display: "grid",
  gridTemplateColumns: "min-content max-content",
  gridTemplateAreas: `
    "icon value"
    "icon helper"
    `,
};
const ayceInfoItemIconStyle = {
  gridArea: "icon",
  alignSelf: "center",
  marginRight: "4px",
};
const ayceInfoItemValueStyle = {
  gridArea: "value",
};
const ayceInfoItemHelperStyle = {
  gridArea: "helper",
};

function _AyceInformationItem({
  icon,
  value,
  helper,
  isLoading = false,
  ...props
}: {
  icon: ReactNode;
  value: ReactNode;
  helper: ReactNode;
  isLoading?: boolean;
} & BoxProps) {
  return (
    <Box sx={ayceInfoItemGridStyle} {...props}>
      <Typography style={ayceInfoItemIconStyle}>{icon}</Typography>
      <Typography style={ayceInfoItemValueStyle} fontWeight="bold">
        {isLoading ? <Skeleton variant="text" width="10" /> : value}
      </Typography>
      <Typography style={ayceInfoItemHelperStyle} variant="body2">
        {helper}
      </Typography>
    </Box>
  );
}

export const AyceInformationItem = memo(_AyceInformationItem);

export function ArrangementChip({
  name,
  covers,
  supplementary = false,
}: {
  name: string;
  covers: number;
  supplementary?: boolean;
}) {
  // TODO translations: arrangement name is not translated, customizable text?
  return (
    <Chip
      className={"JS-ArrangementChip-Root"}
      data-cy={`arrangement-info-${supplementary ? "supplementary" : "main"}`}
      size="small"
      color={supplementary ? "info" : "default"}
      label={
        <>
          <Box
            className={"JS-ArrangementChip-Box"}
            component="span"
            sx={(theme) => ({
              borderRadius: "50%",
              backgroundColor: theme.palette.grey.A400,
              paddingX: 1,
              paddingY: 0.5,
              marginRight: 0.5,
              fontSize: "1.15em",
            })}
          >
            {covers}x
          </Box>
          {name}
        </>
      }
    />
  );
}

/**
 * Isolated into its own component for rendering performance (useOrderTimer causes many re-renders)
 */
export function RoundTimer({ timeBetweenRounds }: { timeBetweenRounds: number | null }) {
  const timeUntilNextRound = useOrderTimer();

  const iconHourglassBottom = useMemo(() => {
    return <HourglassBottom style={{ alignSelf: "center" }} />;
  }, []);

  const iconHourglassTop = useMemo(() => {
    return <HourglassTop style={{ alignSelf: "center" }} />;
  }, []);

  return timeBetweenRounds ? (
    timeUntilNextRound === 0 ? (
      <AyceInformationItem
        data-cy="ayce-overview-ordertimer"
        icon={iconHourglassBottom}
        value={<FormattedMessageJamezz id="AYCE.overview.helper.ready" />}
        helper={<FormattedMessageJamezz id="Next round" />}
      />
    ) : (
      <AyceInformationItem
        data-cy="ayce-overview-ordertimer"
        icon={iconHourglassTop}
        value={timeFormatMinuteSeconds(timeUntilNextRound)}
        helper={<FormattedMessageJamezz id="Next round" />}
      />
    )
  ) : null;
}

function PreDiningArrangementOverview({ arrangement }: { arrangement: PreDiningArrangement }) {
  return (
    <>
      <Typography sx={{ mb: 1 }} fontWeight="bold">
        <FormattedMessageJamezz id="AYCE.overview.pre-dining" />
      </Typography>
      <Typography>
        <FormattedMessageJamezz id="AYCE.overview.no-arrangement-selected.default-body" />
      </Typography>
      <ArrangementReloadButton sx={{ mt: 1 }}>
        <Refresh sx={{ translate: "0 1px" }} />
      </ArrangementReloadButton>
    </>
  );
}

export default function ArrangementsOverview({ sx }: { sx: SxProps }) {
  const arrangement = useAppSelector(selectActiveArrangement);
  const pincodeStatus = useAppSelector((state) => state.arrangements.ticket_pincode.status);

  const arrangementLoadingState = useAppSelector(selectArrangementLoadingState);

  const [refreshTableState] = useRefreshTableStateMutation();

  useEffect(() => {
    refreshTableState();
  }, []);
  if (pincodeStatus != null) {
    return null;
  }
  return (
    <Card
      className={"JS-ArrangementsOverview-Root"}
      data-cy={
        "arrangements-overview-card-" +
        (arrangementLoadingState === ArrangementsLoadingState.LOADING ? "loading" : "loaded")
      }
      sx={{
        color: "black",
        padding: "8px",
        backgroundColor: "#ffffffd0",
        /**
         * animation trick: animate width and height after loading is finished using maxWidth and maxHeight trick
         * @see https://css-tricks.com/using-css-transitions-auto-dimensions/#aa-technique-1-max-height
         */
        transition: "all 250ms ease-in",
        ...(arrangementLoadingState === ArrangementsLoadingState.LOADING
          ? {
              maxWidth: "70vw",
              maxHeight: "200px",
            }
          : {
              maxWidth: "100vw",
              maxHeight: "100vh",
            }),
        ...sx,
      }}
    >
      {arrangementLoadingState === ArrangementsLoadingState.ERR_NO_ACTIVE_ARRANGEMENT ? (
        <>
          <Typography sx={{ mb: 1 }} fontWeight="bold">
            <FormattedMessageJamezz id="AYCE.overview.pre-dining" />
          </Typography>
          <Typography>
            <FormattedMessageJamezz id="AYCE.overview.err-no-arrangement.body" />
          </Typography>
        </>
      ) : arrangementLoadingState === ArrangementsLoadingState.ARRANGEMENT_FATAL_ERROR ? (
        <>
          <Typography sx={{ mb: 1 }} fontWeight="bold">
            <FormattedMessageJamezz id="Error" />
          </Typography>
          <Typography>
            <FormattedMessageJamezz id="AYCE.error.cannot-load-arrangement" />
          </Typography>
        </>
      ) : (
        <>
          {arrangementLoadingState === ArrangementsLoadingState.LOADING ? (
            <Skeleton variant="text" width="30" />
          ) : arrangement && arrangement.variant === "regular" ? (
            <RegularArrangementOverview arrangement={arrangement} />
          ) : arrangement && arrangement.variant === "pre-dining" ? (
            <PreDiningArrangementOverview arrangement={arrangement} />
          ) : null}
        </>
      )}
    </Card>
  );
}

export function AyceTicketTimer({ arrangementLoadingState }: { arrangementLoadingState: ArrangementsLoadingState }) {
  const timeLeftInSeconds = useAyceTicketTimer();

  return (
    <AyceInformationItem
      icon={<AccessTime style={{ alignSelf: "center" }} />}
      /*TODO Translations*/
      value={
        timeLeftInSeconds > 600
          ? `${String(Math.floor(timeLeftInSeconds / 60)).padStart(2, "0")} min`
          : `${String(Math.floor(timeLeftInSeconds / 60)).padStart(2, "0")}:${String(timeLeftInSeconds % 60).padStart(
              2,
              "0"
            )}`
      }
      helper={<FormattedMessageJamezz id="Time" />}
      isLoading={arrangementLoadingState === ArrangementsLoadingState.LOADING}
      data-cy="ayce-overview-max-time"
    />
  );
}
