import Grid from "@mui/material/Grid";
import { useState } from "react";
import { Box, Button, DialogContent, TextField, Typography } from "@mui/material";
import useVouchers from "../../global/utils/useVouchers";
import { useDialog } from "../../global/utils/dialog/DialogProvider";
import FormattedMessageJamezz from "../../global/components/FormattedMessageJamezz";
import CloseDialogButtonQr from "./Dialog/CloseDialogButton.tsx";
import { toast } from "react-toastify";
import { BlockUserInput } from "@jamezz/react-components";

export default function VoucherInput() {
  const { openDialog } = useDialog();

  return (
    <Box sx={{ marginTop: 2, width: 1 }}>
      <Button
        data-cy="voucher-btn"
        className="JS-Vouchers-VoucherButton"
        variant="contained"
        size="small"
        sx={{ width: 1 }}
        onClick={() => {
          openDialog({ children: <VoucherDialog /> });
        }}
      >
        <FormattedMessageJamezz id={"Add voucher"} />
      </Button>
    </Box>
  );
}

export function VoucherDialog() {
  const [voucherText, setVoucherText] = useState("");
  const { closeDialog } = useDialog();
  const { checkVoucherCode } = useVouchers();
  const isLoading = BlockUserInput.useIsUserInputBlocked();

  return (
    <>
      <CloseDialogButtonQr />
      <DialogContent sx={{ mt: 4 }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!isLoading) {
              checkVoucherCode(voucherText)
                .then(() => {
                  toast.success(<FormattedMessageJamezz id="Voucher.messages.added-successfully" />);
                  closeDialog();
                  setVoucherText("");
                })
                .catch((err) => {
                  console.log(err);
                  toast.error(<FormattedMessageJamezz id={err.result.message} />);
                  console.log("ERROR");
                });
            }
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography variant={"subtitle1"} style={{ marginLeft: "10px", marginRight: "10px" }}>
                <FormattedMessageJamezz id="Please, fill in your voucher code below" />
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <TextField
                data-cy="voucher-input"
                sx={{ width: 1 }}
                autoFocus
                required
                label={<FormattedMessageJamezz id={"Voucher Code"} />}
                value={voucherText}
                onChange={(e) => {
                  setVoucherText(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Button variant="contained" size="small" type={"submit"}>
                <FormattedMessageJamezz id={"Check voucher"} />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </>
  );
}
